import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
const GoogleFontsComp = ({ value, label, cbValue }) => {
  const [select2Data, setSelect2Data] = useState([
    { value: "ABeeZee", label: "ABeeZee" },
    { value: "Abel", label: "Abel" },
    { value: "Abhaya Libre", label: "Abhaya Libre" },
    { value: "Abril Fatface", label: "Abril Fatface" },
    // { value: 'Akronim', label: 'Akronim' },
    // { value: 'Aladin', label: 'Aladin' },
    // { value: 'Alata', label: 'Alata' },
    // { value: 'Alatsi', label: 'Alatsi' },
    // { value: 'Aldrich', label: 'Aldrich' },
    // { value: 'Alef', label: 'Alef' },
    // { value: 'Alegreya', label: 'Alegreya' },
    // { value: 'Alegreya Sans', label: 'Alegreya Sans' },
    // { value: 'Alegreya Sans SC', label: 'Alegreya Sans SC' },
    // { value: 'Alegreya SC', label: 'Alegreya SC' },
    // { value: 'Aleo', label: 'Aleo' },
    // { value: 'Alex Brush', label: 'Alex Brush' },
    // { value: 'Alfa Slab One', label: 'Alfa Slab One' },
    // { value: 'Alice', label: 'Alice' },
    // { value: 'Alike', label: 'Alike' },
    // { value: 'Alike Angular', label: 'Alike Angular' },
    { value: "Aclonica", label: "Aclonica" },
    { value: "Acme", label: "Acme" },
    { value: "Actor", label: "Actor" },
    { value: "Adamina", label: "Adamina" },
    { value: "Advent Pro", label: "Advent Pro" },
    { value: "Aguafina Script", label: "Aguafina Script" },
    { value: "Akaya Kanadaka", label: "Akaya Kanadaka" },
    { value: "Akaya Telivigala", label: "Akaya Telivigala" },
    { value: "Akronim", label: "Akronim" },
    { value: "Aladin", label: "Aladin" },
    { value: "Alata", label: "Alata" },
    { value: "Alatsi", label: "Alatsi" },
    { value: "Alef", label: "Alef" },
    // { value: 'Alegreya', label: 'Alegreya' },
    // { value: 'Alegreya Sans"', label: 'Alegreya Sans' },
    // { value: 'Alegreya Sans SC"', label: 'Alegreya Sans SC' },
    // { value: "Alegreya SC", label: "Alegreya SC" },
    { value: "Aleo", label: "Aleo" },
    { value: "Alex Brush", label: "Alex Brush" },
    { value: "Alfa Slab One", label: "Alfa Slab One" },
    { value: "Alice", label: "Alice" },
    { value: "Alike", label: "Alike" },
    { value: "Alike Angular", label: "Alike Angular" },
    { value: "Allan", label: "Allan" },
    { value: "Allerta", label: "Allerta" },
    { value: "Allerta Stencil", label: "Allerta Stencil" },
    { value: "Allison", label: "Allison" },
    { value: "Allura", label: "Allura" },
    { value: "Almarai", label: "Almarai" },
    { value: "Almendra", label: "Almendra" },
    { value: "Almendra Display", label: "Almendra Display" },
    { value: "Almendra SC", label: "Almendra SC" },
    { value: "Alumni Sans", label: "Alumni Sans" },
    { value: "Amarante", label: "Amarante" },
    { value: "Amaranth", label: "Amaranth" },
    { value: "Amatic SC", label: "Amatic SC" },
    { value: "Amethysta", label: "Amethysta" },
    { value: "Amiko", label: "Amiko" },
    { value: "Amiri", label: "Amiri" },
    { value: "Amita", label: "Amita" },
    { value: "Anaheim", label: "Anaheim" },
    { value: "Andada Pro", label: "Andada Pro" },
    { value: "Andika", label: "Andika" },
    { value: "Andika New Basic", label: "Andika New Basic" },
    { value: "Angkor", label: "Angkor" },
    { value: "Annie Use Your Telescope", label: "Annie Use Your Telescope" },
    { value: "Anonymous Pro", label: "Anonymous Pro" },
    { value: "Antic", label: "Antic" },
    { value: "Antic Didone", label: "Antic Didone" },
    { value: "Antic Slab", label: "Antic Slab" },
    { value: "Anton", label: "Anton" },
    { value: "Antonio", label: "Antonio" },
    { value: "Arapey", label: "Arapey" },
    { value: "Arbutus", label: "Arbutus" },
    { value: "Arbutus Slab", label: "Arbutus Slab" },
    { value: "Architects Daughter", label: "Architects Daughter" },
    { value: "Archivo", label: "Archivo" },
    { value: "Archivo Black", label: "Archivo Black" },
    { value: "Archivo Narrow", label: "Archivo Narrow" },
    { value: "Are You Serious", label: "Are You Serious" },
    { value: "Aref Ruqaa", label: "Aref Ruqaa" },
    { value: "Arima Madurai", label: "Arima Madurai" },
    { value: "Arimo", label: "Arimo" },
    { value: "Arizonia", label: "Arizonia" },
    { value: "Armata", label: "Armata" },
    { value: "Arsenal", label: "Arsenal" },
    { value: "Artifika", label: "Artifika" },
    { value: "Arvo", label: "Arvo" },
    { value: "Arya", label: "Arya" },
    { value: "Asap", label: "Asap" },
    { value: "Asap Condensed", label: "Asap Condensed" },
    { value: "Asar", label: "Asar" },
    { value: "Asset", label: "Asset" },
    { value: "Assistant", label: "Assistant" },
    { value: "Astloch", label: "Astloch" },
    { value: "Asul", label: "Asul" },
    { value: "Athiti", label: "Athiti" },
    { value: "Atkinson Hyperlegible", label: "Atkinson Hyperlegible" },
    { value: "Atma", label: "Atma" },
    { value: "Atomic Age", label: "Atomic Age" },
    { value: "Aubrey", label: "Aubrey" },
    { value: "Audiowide", label: "Audiowide" },
    { value: "Autour One", label: "Autour One" },
    { value: "Average", label: "Average" },
    { value: "Average Sans", label: "Average Sans" },
    { value: "Averia Gruesa Libre", label: "Averia Gruesa Libre" },
    { value: "Averia Libre", label: "Averia Libre" },
    { value: "Averia Sans Libre", label: "Averia Sans Libre" },
    { value: "Averia Serif Libre", label: "Averia Serif Libre" },
    { value: "Azeret Mono", label: "Azeret Mono" },
    { value: "B612", label: "B612" },
    { value: "B612 Mono", label: "B612 Mono" },
    { value: "Bad Script", label: "Bad Script" },
    { value: "Bahiana", label: "Bahiana" },
    { value: "Bahianita", label: "Bahianita" },
    { value: "Bai Jamjuree", label: "Bai Jamjuree" },
    { value: "Bakbak One", label: "Bakbak One" },
    { value: "Ballet", label: "Ballet" },
    { value: "Baloo 2", label: "Baloo 2" },
    { value: "Baloo Bhai 2", label: "Baloo Bhai 2" },
    { value: "Baloo Bhaijaan 2", label: "Baloo Bhaijaan 2" },
    { value: "Baloo Bhaina 2", label: "Baloo Bhaina 2" },
    { value: "Baloo Chettan 2", label: "Baloo Chettan 2" },
    { value: "Baloo Da 2", label: "Baloo Da 2" },
    { value: "Baloo Paaji 2", label: "Baloo Paaji 2" },
    { value: "Baloo Tamma 2", label: "Baloo Tamma 2" },
    { value: "Baloo Tammudu 2", label: "Baloo Tammudu 2" },
    { value: "Baloo Thambi 2", label: "Baloo Thambi 2" },
    { value: "Balsamiq Sans", label: "Balsamiq Sans" },
    { value: "Balthazar", label: "Balthazar" },
    { value: "Bangers", label: "Bangers" },
    { value: "Barlow", label: "Barlow" },
    { value: "Barlow Condensed", label: "Barlow Condensed" },
    { value: "Barlow Semi Condensed", label: "Barlow Semi Condensed" },
    { value: "Barriecito", label: "Barriecito" },
    { value: "Barrio", label: "Barrio" },
    { value: "Basic", label: "Basic" },
    { value: "Baskervville", label: "Baskervville" },
    { value: "Battambang", label: "Battambang" },
    { value: "Baumans", label: "Baumans" },
    { value: "Bayon", label: "Bayon" },
    { value: "Be Vietnam Pro", label: "Be Vietnam Pro" },
    { value: "Bebas Neue", label: "Bebas Neue" },
    { value: "Belgrano", label: "Belgrano" },
    { value: "Bellefair", label: "Bellefair" },
    { value: "Belleza", label: "Belleza" },
    { value: "Bellota", label: "Bellota" },
    { value: "Bellota Text", label: "Bellota Text" },
    { value: "BenchNine", label: "BenchNine" },
    { value: "Benne", label: "Benne" },
    { value: "Bentham", label: "Bentham" },
    { value: "Berkshire Swash", label: "Berkshire Swash" },
    { value: "Besley", label: "Besley" },
    { value: "Beth Ellen", label: "Beth Ellen" },
    { value: "Bevan", label: "Bevan" },
    { value: "Big Shoulders Display", label: "Big Shoulders Display" },
    {
      value: "Big Shoulders Inline Display",
      label: "Big Shoulders Inline Display",
    },
    { value: "Big Shoulders Inline Text", label: "Big Shoulders Inline Text" },
    {
      value: "Big Shoulders Stencil Display",
      label: "Big Shoulders Stencil Display",
    },
    {
      value: "Big Shoulders Stencil Text",
      label: "Big Shoulders Stencil Text",
    },
    { value: "Big Shoulders Text", label: "Big Shoulders Text" },
    { value: "Bigelow Rules", label: "Bigelow Rules" },
    { value: "Bigshot One", label: "Bigshot One" },
    { value: "Bilbo", label: "Bilbo" },
    { value: "Bilbo Swash Caps", label: "Bilbo Swash Caps" },
    { value: "BioRhyme", label: "BioRhyme" },
    { value: "BioRhyme Expanded", label: "BioRhyme Expanded" },
    { value: "Birthstone", label: "Birthstone" },
    { value: "Birthstone Bounce", label: "Birthstone Bounce" },
    { value: "Biryani", label: "Biryani" },
    { value: "Bitter", label: "Bitter" },
    { value: "Black And White Picture", label: "Black And White Picture" },
    { value: "Black Han Sans", label: "Black Han Sans" },
    { value: "Black Ops One", label: "Black Ops One" },
    { value: "Blinker", label: "Blinker" },
    { value: "Bodoni Moda", label: "Bodoni Moda" },
    { value: "Bokor", label: "Bokor" },
    { value: "Bona Nova", label: "Bona Nova" },
    { value: "Bonbon", label: "Bonbon" },
    { value: "Bonheur Royale", label: "Bonheur Royale" },
    { value: "Boogaloo", label: "Boogaloo" },
    { value: "Bowlby One", label: "Bowlby One" },
    { value: "Bowlby One SC", label: "Bowlby One SC" },
    { value: "Brawler", label: "Brawler" },
    { value: "Bree Serif", label: "Bree Serif" },
    { value: "Brygada 1918", label: "Brygada 1918" },
    { value: "Bubblegum Sans", label: "Bubblegum Sans" },
    { value: "Bubbler One", label: "Bubbler One" },
    { value: "Buda", label: "Buda" },
    { value: "Buenard", label: "Buenard" },
    { value: "Bungee", label: "Bungee" },
    { value: "Bungee Hairline", label: "Bungee Hairline" },
    { value: "Bungee Inline", label: "Bungee Inline" },
    { value: "Bungee Outline", label: "Bungee Outline" },
    { value: "Bungee Shade", label: "Bungee Shade" },
    { value: "Butcherman", label: "Butcherman" },
    { value: "Butterfly Kids", label: "Butterfly Kids" },
    { value: "Cabin", label: "Cabin" },
    { value: "Cabin Condensed", label: "Cabin Condensed" },
    { value: "Cabin Sketch", label: "Cabin Sketch" },
    { value: "Caesar Dressing", label: "Caesar Dressing" },
    { value: "Cagliostro", label: "Cagliostro" },
    { value: "Cairo", label: "Cairo" },
    { value: "Caladea", label: "Caladea" },
    { value: "Calistoga", label: "Calistoga" },
    { value: "Calligraffitti", label: "Calligraffitti" },
    { value: "Cambay", label: "Cambay" },
    { value: "Cambo", label: "Cambo" },
    { value: "Candal", label: "Candal" },
    { value: "Cantarell", label: "Cantarell" },
    { value: "Cantata One", label: "Cantata One" },
    { value: "Cantora One", label: "Cantora One" },
    { value: "Capriola", label: "Capriola" },
    { value: "Caramel", label: "Caramel" },
    { value: "Carattere", label: "Carattere" },
    { value: "Cardo", label: "Cardo" },
    { value: "Carme", label: "Carme" },
    { value: "Carrois Gothic", label: "Carrois Gothic" },
    { value: "Carrois Gothic SC", label: "Carrois Gothic SC" },
    { value: "Carter One", label: "Carter One" },
    { value: "Castoro", label: "Castoro" },
    { value: "Catamaran", label: "Catamaran" },
    { value: "Caudex", label: "Caudex" },
    { value: "Caveat", label: "Caveat" },
    { value: "Caveat Brush", label: "Caveat Brush" },
    { value: "Cedarville Cursive", label: "Cedarville Cursive" },
    { value: "Ceviche One", label: "Ceviche One" },
    { value: "Chakra Petch", label: "Chakra Petch" },
    { value: "Changa", label: "Changa" },
    { value: "Changa One", label: "Changa One" },
    { value: "Chango", label: "Chango" },
    { value: "Charm", label: "Charm" },
    { value: "Charmonman", label: "Charmonman" },
    { value: "Chathura", label: "Chathura" },
    { value: "Chau Philomene One", label: "Chau Philomene One" },
    { value: "Chela One", label: "Chela One" },
    { value: "Chelsea Market", label: "Chelsea Market" },
    { value: "Chenla", label: "Chenla" },
    { value: "Cherish", label: "Cherish" },
    { value: "Cherry Cream Soda", label: "Cherry Cream Soda" },
    { value: "Cherry Swash", label: "Cherry Swash" },
    { value: "Chewy", label: "Chewy" },
    { value: "Chicle", label: "Chicle" },
    { value: "Chilanka", label: "Chilanka" },
    { value: "Chivo", label: "Chivo" },
    { value: "Chonburi", label: "Chonburi" },
    { value: "Cinzel", label: "Cinzel" },
    { value: "Cinzel Decorative", label: "Cinzel Decorative" },
    { value: "Clicker Script", label: "Clicker Script" },
    { value: "Coda", label: "Coda" },
    { value: "Coda Caption", label: "Coda Caption" },
    { value: "Codystar", label: "Codystar" },
    { value: "Coiny", label: "Coiny" },
    { value: "Combo", label: "Combo" },
    { value: "Comfortaa", label: "Comfortaa" },
    { value: "Comforter", label: "Comforter" },
    { value: "Comforter Brush", label: "Comforter Brush" },
    { value: "Comic Neue", label: "Comic Neue" },
    { value: "Coming Soon", label: "Coming Soon" },
    { value: "Commissioner", label: "Commissioner" },
    { value: "Concert One", label: "Concert One" },
    { value: "Condiment", label: "Condiment" },
    { value: "Content", label: "Content" },
    { value: "Contrail One", label: "Contrail One" },
    { value: "Convergence", label: "Convergence" },
    { value: "Cookie", label: "Cookie" },
    { value: "Copse", label: "Copse" },
    { value: "Corben", label: "Corben" },
    { value: "Corinthia", label: "Corinthia" },
    { value: "Cormorant", label: "Cormorant" },
    { value: "Cormorant Garamond", label: "Cormorant Garamond" },
    { value: "Cormorant Infant", label: "Cormorant Infant" },
    { value: "Cormorant SC", label: "Cormorant SC" },
    { value: "Cormorant Unicase", label: "Cormorant Unicase" },
    { value: "Cormorant Upright", label: "Cormorant Upright" },
    { value: "Courgette", label: "Courgette" },
    { value: "Courier Prime", label: "Courier Prime" },
    { value: "Cousine", label: "Cousine" },
    { value: "Coustard", label: "Coustard" },
    { value: "Covered By Your Grace", label: "Covered By Your Grace" },
    { value: "Crafty Girls", label: "Crafty Girls" },
    { value: "Creepster", label: "Creepster" },
    { value: "Crete Round", label: "Crete Round" },
    { value: "Crimson Pro", label: "Crimson Pro" },
    { value: "Croissant One", label: "Croissant One" },
    { value: "Crushed", label: "Crushed" },
    { value: "Cuprum", label: "Cuprum" },
    { value: "Cute Font", label: "Cute Font" },
    { value: "Cutive", label: "Cutive" },
    { value: "Cutive Mono", label: "Cutive Mono" },
    { value: "Damion", label: "Damion" },
    { value: "Dancing Script", label: "Dancing Script" },
    { value: "Dangrek", label: "Dangrek" },
    { value: "Darker Grotesque", label: "Darker Grotesque" },
    { value: "David Libre", label: "David Libre" },
    { value: "Dawning of a New Day", label: "Dawning of a New Day" },
    { value: "Days One", label: "Days One" },
    { value: "Dekko", label: "Dekko" },
    { value: "Dela Gothic One", label: "Dela Gothic One" },
    { value: "Delius", label: "Delius" },
    { value: "Delius Swash Caps", label: "Delius Swash Caps" },
    { value: "Delius Unicase", label: "Delius Unicase" },
    { value: "Della Respira", label: "Della Respira" },
    { value: "Denk One", label: "Denk One" },
    { value: "Devonshire", label: "Devonshire" },
    { value: "Dhurjati", label: "Dhurjati" },
    { value: "Didact Gothic", label: "Didact Gothic" },
    { value: "Diplomata", label: "Diplomata" },
    { value: "Diplomata SC", label: "Diplomata SC" },
    { value: "DM Mono", label: "DM Mono" },
    { value: "DM Sans", label: "DM Sans" },
    { value: "DM Serif Display", label: "DM Serif Display" },
    { value: "DM Serif Text", label: "DM Serif Text" },
    { value: "Do Hyeon", label: "Do Hyeon" },
    { value: "Dokdo", label: "Dokdo" },
    { value: "Domine", label: "Domine" },
    { value: "Donegal One", label: "Donegal One" },
    { value: "Dongle", label: "Dongle" },
    { value: "Doppio One", label: "Doppio One" },
    { value: "Dorsa", label: "Dorsa" },
    { value: "Dosis", label: "Dosis" },
    { value: "DotGothic16", label: "DotGothic16" },
    { value: "Dr Sugiyama", label: "Dr Sugiyama" },
    { value: "Duru Sans", label: "Duru Sans" },
    { value: "Dynalight", label: "Dynalight" },
    { value: "Eagle Lake", label: "Eagle Lake" },
    { value: "East Sea Dokdo", label: "East Sea Dokdo" },
    { value: "Eater", label: "Eater" },
    { value: "EB Garamond", label: "EB Garamond" },
    { value: "Economica", label: "Economica" },
    { value: "Eczar", label: "Eczar" },
    { value: "El Messiri", label: "El Messiri" },
    { value: "Electrolize", label: "Electrolize" },
    { value: "Elsie", label: "Elsie" },
    { value: "Elsie Swash Caps", label: "Elsie Swash Caps" },
    { value: "Emblema One", label: "Emblema One" },
    { value: "Emilys Candy", label: "Emilys Candy" },
    { value: "Encode Sans", label: "Encode Sans" },
    { value: "Encode Sans Condensed", label: "Encode Sans Condensed" },
    { value: "Encode Sans Expanded", label: "Encode Sans Expanded" },
    { value: "Encode Sans SC", label: "Encode Sans SC" },
    {
      value: "Encode Sans Semi Condensed",
      label: "Encode Sans Semi Condensed",
    },
    { value: "Encode Sans Semi Expanded", label: "Encode Sans Semi Expanded" },
    { value: "Engagement", label: "Engagement" },
    { value: "Englebert", label: "Englebert" },
    { value: "Enriqueta", label: "Enriqueta" },
    { value: "Ephesis", label: "Ephesis" },
    { value: "Epilogue", label: "Epilogue" },
    { value: "Erica One", label: "Erica One" },
    { value: "Esteban", label: "Esteban" },
    { value: "Estonia", label: "Estonia" },
    { value: "Euphoria Script", label: "Euphoria Script" },
    { value: "Ewert", label: "Ewert" },
    { value: "Exo", label: "Exo" },
    { value: "Exo 2", label: "Exo 2" },
    { value: "Expletus Sans", label: "Expletus Sans" },
    { value: "Explora", label: "Explora" },
    { value: "Fahkwang", label: "Fahkwang" },
    { value: "Fanwood Text", label: "Fanwood Text" },
    { value: "Farro", label: "Farro" },
    { value: "Farsan", label: "Farsan" },
    { value: "Fascinate", label: "Fascinate" },
    { value: "Fascinate Inline", label: "Fascinate Inline" },
    { value: "Faster One", label: "Faster One" },
    { value: "Fasthand", label: "Fasthand" },
    { value: "Fauna One", label: "Fauna One" },
    { value: "Faustina", label: "Faustina" },
    { value: "Federant", label: "Federant" },
    { value: "Federo", label: "Federo" },
    { value: "Felipa", label: "Felipa" },
    { value: "Fenix", label: "Fenix" },
    { value: "Festive", label: "Festive" },
    { value: "Finger Paint", label: "Finger Paint" },
    { value: "Fira Code", label: "Fira Code" },
    { value: "Fira Mono", label: "Fira Mono" },
    { value: "Fira Sans", label: "Fira Sans" },
    { value: "Fira Sans Condensed", label: "Fira Sans Condensed" },
    { value: "Fira Sans Extra Condensed", label: "Fira Sans Extra Condensed" },
    { value: "Fjalla One", label: "Fjalla One" },
    { value: "Fjord One", label: "Fjord One" },
    { value: "Flamenco", label: "Flamenco" },
    { value: "Flavors", label: "Flavors" },
    { value: "Fleur De Leah", label: "Fleur De Leah" },
    { value: "Flow Block", label: "Flow Block" },
    { value: "Flow Circular", label: "Flow Circular" },
    { value: "Flow Rounded", label: "Flow Rounded" },
    { value: "Fondamento", label: "Fondamento" },
    { value: "Fontdiner Swanky", label: "Fontdiner Swanky" },
    { value: "Forum", label: "Forum" },
    { value: "Francois One", label: "Francois One" },
    { value: "Frank Ruhl Libre", label: "Frank Ruhl Libre" },
    { value: "Fraunces", label: "Fraunces" },
    { value: "Freckle Face", label: "Freckle Face" },
    { value: "Fredericka the Great", label: "Fredericka the Great" },
    { value: "Fredoka One", label: "Fredoka One" },
    { value: "Freehand", label: "Freehand" },
    { value: "Fresca", label: "Fresca" },
    { value: "Frijole", label: "Frijole" },
    { value: "Fruktur", label: "Fruktur" },
    { value: "Fugaz One", label: "Fugaz One" },
    { value: "Fuggles", label: "Fuggles" },
    { value: "Fuzzy Bubbles", label: "Fuzzy Bubbles" },
    { value: "Gabriela", label: "Gabriela" },
    { value: "Gaegu", label: "Gaegu" },
    { value: "Gafata", label: "Gafata" },
    { value: "Galada", label: "Galada" },
    { value: "Galdeano", label: "Galdeano" },
    { value: "Galindo", label: "Galindo" },
    { value: "Gamja Flower", label: "Gamja Flower" },
    { value: "Gayathri", label: "Gayathri" },
    { value: "Gelasio", label: "Gelasio" },
    { value: "Gemunu Libre", label: "Gemunu Libre" },
    { value: "Genos", label: "Genos" },
    { value: "Gentium Basic", label: "Gentium Basic" },
    { value: "Gentium Book Basic", label: "Gentium Book Basic" },
    { value: "Geo", label: "Geo" },
    { value: "Georama", label: "Georama" },
    { value: "Geostar", label: "Geostar" },
    { value: "Geostar Fill", label: "Geostar Fill" },
    { value: "Germania One", label: "Germania One" },
    { value: "GFS Didot", label: "GFS Didot" },
    { value: "GFS Neohellenic", label: "GFS Neohellenic" },
    { value: "Gideon Roman", label: "Gideon Roman" },
    { value: "Gidugu", label: "Gidugu" },
    { value: "Gilda Display", label: "Gilda Display" },
    { value: "Girassol", label: "Girassol" },
    { value: "Give You Glory", label: "Give You Glory" },
    { value: "Glass Antiqua", label: "Glass Antiqua" },
    { value: "Glegoo", label: "Glegoo" },
    { value: "Gloria Hallelujah", label: "Gloria Hallelujah" },
    { value: "Glory", label: "Glory" },
    { value: "Gluten", label: "Gluten" },
    { value: "Goblin One", label: "Goblin One" },
    { value: "Gochi Hand", label: "Gochi Hand" },
    { value: "Goldman", label: "Goldman" },
    { value: "Gorditas", label: "Gorditas" },
    { value: "Gothic A1", label: "Gothic A1" },
    { value: "Gotu", label: "Gotu" },
    { value: "Goudy Bookletter 1911", label: "Goudy Bookletter 1911" },
    { value: "Gowun Batang", label: "Gowun Batang" },
    { value: "Gowun Dodum", label: "Gowun Dodum" },
    { value: "Graduate", label: "Graduate" },
    { value: "Grand Hotel", label: "Grand Hotel" },
    { value: "Grandstander", label: "Grandstander" },
    { value: "Gravitas One", label: "Gravitas One" },
    { value: "Great Vibes", label: "Great Vibes" },
    { value: "Grechen Fuemen", label: "Grechen Fuemen" },
    { value: "Grenze", label: "Grenze" },
    { value: "Grenze Gotisch", label: "Grenze Gotisch" },
    { value: "Grey Qo", label: "Grey Qo" },
    { value: "Griffy", label: "Griffy" },
    { value: "Gruppo", label: "Gruppo" },
    { value: "Gudea", label: "Gudea" },
    { value: "Gugi", label: "Gugi" },
    { value: "Gupter", label: "Gupter" },
    { value: "Gurajada", label: "Gurajada" },
    { value: "Gwendolyn", label: "Gwendolyn" },
    { value: "Habibi", label: "Habibi" },
    { value: "Hachi Maru Pop", label: "Hachi Maru Pop" },
    { value: "Hahmlet", label: "Hahmlet" },
    { value: "Halant", label: "Halant" },
    { value: "Hammersmith One", label: "Hammersmith One" },
    { value: "Hanalei", label: "Hanalei" },
    { value: "Hanalei Fill", label: "Hanalei Fill" },
    { value: "Handlee", label: "Handlee" },
    { value: "Hanuman", label: "Hanuman" },
    { value: "Happy Monkey", label: "Happy Monkey" },
    { value: "Harmattan", label: "Harmattan" },
    { value: "Headland One", label: "Headland One" },
    { value: "Heebo", label: "Heebo" },
    { value: "Henny Penny", label: "Henny Penny" },
    { value: "Hepta Slab", label: "Hepta Slab" },
    { value: "Herr Von Muellerhoff", label: "Herr Von Muellerhoff" },
    { value: "Hi Melody", label: "Hi Melody" },
    { value: "Hina Mincho", label: "Hina Mincho" },
    { value: "Hind", label: "Hind" },
    { value: "Hind Guntur", label: "Hind Guntur" },
    { value: "Hind Madurai", label: "Hind Madurai" },
    { value: "Hind Siliguri", label: "Hind Siliguri" },
    { value: "Hind Vadodara", label: "Hind Vadodara" },
    { value: "Holtwood One SC", label: "Holtwood One SC" },
    { value: "Homemade Apple", label: "Homemade Apple" },
    { value: "Homenaje", label: "Homenaje" },
    { value: "Hurricane", label: "Hurricane" },
    { value: "Ibarra Real Nova", label: "Ibarra Real Nova" },
    { value: "IBM Plex Mono", label: "IBM Plex Mono" },
    { value: "IBM Plex Sans", label: "IBM Plex Sans" },
    { value: "IBM Plex Sans Arabic", label: "IBM Plex Sans Arabic" },
    { value: "IBM Plex Sans Condensed", label: "IBM Plex Sans Condensed" },
    { value: "IBM Plex Sans Devanagari", label: "IBM Plex Sans Devanagari" },
    { value: "IBM Plex Sans Hebrew", label: "IBM Plex Sans Hebrew" },
    { value: "IBM Plex Sans KR", label: "IBM Plex Sans KR" },
    { value: "IBM Plex Sans Thai", label: "IBM Plex Sans Thai" },
    { value: "IBM Plex Sans Thai Looped", label: "IBM Plex Sans Thai Looped" },
    { value: "IBM Plex Serif", label: "IBM Plex Serif" },
    { value: "Iceberg", label: "Iceberg" },
    { value: "Iceland", label: "Iceland" },
    { value: "IM Fell Double Pica", label: "IM Fell Double Pica" },
    { value: "IM Fell Double Pica SC", label: "IM Fell Double Pica SC" },
    { value: "IM Fell DW Pica", label: "IM Fell DW Pica" },
    { value: "IM Fell DW Pica SC", label: "IM Fell DW Pica SC" },
    { value: "IM Fell English", label: "IM Fell English" },
    { value: "IM Fell English SC", label: "IM Fell English SC" },
    { value: "IM Fell French Canon", label: "IM Fell French Canon" },
    { value: "IM Fell French Canon SC", label: "IM Fell French Canon SC" },
    { value: "IM Fell Great Primer", label: "IM Fell Great Primer" },
    { value: "IM Fell Great Primer SC", label: "IM Fell Great Primer SC" },
    { value: "Imbue", label: "Imbue" },
    { value: "Imperial Script", label: "Imperial Script" },
    { value: "Imprima", label: "Imprima" },
    { value: "Inconsolata", label: "Inconsolata" },
    { value: "Inder", label: "Inder" },
    { value: "Indie Flower", label: "Indie Flower" },
    { value: "Inika", label: "Inika" },
    { value: "Inknut Antiqua", label: "Inknut Antiqua" },
    { value: "Inria Sans", label: "Inria Sans" },
    { value: "Inria Serif", label: "Inria Serif" },
    { value: "Inspiration", label: "Inspiration" },
    { value: "Inter", label: "Inter" },
    { value: "Irish Grover", label: "Irish Grover" },
    { value: "Island Moments", label: "Island Moments" },
    { value: "Istok Web", label: "Istok Web" },
    { value: "Italiana", label: "Italiana" },
    { value: "Italianno", label: "Italianno" },
    { value: "Itim", label: "Itim" },
    { value: "Jacques Francois", label: "Jacques Francois" },
    { value: "Jacques Francois Shadow", label: "Jacques Francois Shadow" },
    { value: "Jaldi", label: "Jaldi" },
    { value: "JetBrains Mono", label: "JetBrains Mono" },
    { value: "Jim Nightshade", label: "Jim Nightshade" },
    { value: "Jockey One", label: "Jockey One" },
    { value: "Jolly Lodger", label: "Jolly Lodger" },
    { value: "Jomhuria", label: "Jomhuria" },
    { value: "Jomolhari", label: "Jomolhari" },
    { value: "Josefin Sans", label: "Josefin Sans" },
    { value: "Josefin Slab", label: "Josefin Slab" },
    { value: "Jost", label: "Jost" },
    { value: "Joti One", label: "Joti One" },
    { value: "Jua", label: "Jua" },
    { value: "Judson", label: "Judson" },
    { value: "Julee", label: "Julee" },
    { value: "Julius Sans One", label: "Julius Sans One" },
    { value: "Junge", label: "Junge" },
    { value: "Jura", label: "Jura" },
    { value: "Just Another Hand", label: "Just Another Hand" },
    { value: "Just Me Again Down Here", label: "Just Me Again Down Here" },
    { value: "K2D", label: "K2D" },
    { value: "Kadwa", label: "Kadwa" },
    { value: "Kaisei Decol", label: "Kaisei Decol" },
    { value: "Kaisei HarunoUmi", label: "Kaisei HarunoUmi" },
    { value: "Kaisei Opti", label: "Kaisei Opti" },
    { value: "Kaisei Tokumin", label: "Kaisei Tokumin" },
    { value: "Kalam", label: "Kalam" },
    { value: "Kameron", label: "Kameron" },
    { value: "Kanit", label: "Kanit" },
    { value: "Kantumruy", label: "Kantumruy" },
    { value: "Karantina", label: "Karantina" },
    { value: "Karla", label: "Karla" },
    { value: "Karma", label: "Karma" },
    { value: "Katibeh", label: "Katibeh" },
    { value: "Kaushan Script", label: "Kaushan Script" },
    { value: "Kavivanar", label: "Kavivanar" },
    { value: "Kavoon", label: "Kavoon" },
    { value: "Kdam Thmor", label: "Kdam Thmor" },
    { value: "Keania One", label: "Keania One" },
    { value: "Kelly Slab", label: "Kelly Slab" },
    { value: "Kenia", label: "Kenia" },
    { value: "Khand", label: "Khand" },
    { value: "Khmer", label: "Khmer" },
    { value: "Khula", label: "Khula" },
    { value: "Kings", label: "Kings" },
    { value: "Kirang Haerang", label: "Kirang Haerang" },
    { value: "Kite One", label: "Kite One" },
    { value: "Kiwi Maru", label: "Kiwi Maru" },
    { value: "Klee One", label: "Klee One" },
    { value: "Knewave", label: "Knewave" },
    { value: "Kodchasan", label: "Kodchasan" },
    { value: "Koh Santepheap", label: "Koh Santepheap" },
    { value: "KoHo", label: "KoHo" },
    { value: "Kolker Brush", label: "Kolker Brush" },
    { value: "Kosugi", label: "Kosugi" },
    { value: "Kosugi Maru", label: "Kosugi Maru" },
    { value: "Kotta One", label: "Kotta One" },
    { value: "Koulen", label: "Koulen" },
    { value: "Kranky", label: "Kranky" },
    { value: "Kreon", label: "Kreon" },
    { value: "Kristi", label: "Kristi" },
    { value: "Krona One", label: "Krona One" },
    { value: "Krub", label: "Krub" },
    { value: "Kufam", label: "Kufam" },
    { value: "Kulim Park", label: "Kulim Park" },
    { value: "Kumar One", label: "Kumar One" },
    { value: "Kumar One Outline", label: "Kumar One Outline" },
    { value: "Kumbh Sans", label: "Kumbh Sans" },
    { value: "Kurale", label: "Kurale" },
    { value: "La Belle Aurore", label: "La Belle Aurore" },
    { value: "Lacquer", label: "Lacquer" },
    { value: "Laila", label: "Laila" },
    { value: "Lakki Reddy", label: "Lakki Reddy" },
    { value: "Lalezar", label: "Lalezar" },
    { value: "Lancelot", label: "Lancelot" },
    { value: "Langar", label: "Langar" },
    { value: "Lateef", label: "Lateef" },
    { value: "Lato", label: "Lato" },
    { value: "League Script", label: "League Script" },
    { value: "Leckerli One", label: "Leckerli One" },
    { value: "Ledger", label: "Ledger" },
    { value: "Lekton", label: "Lekton" },
    { value: "Lemon", label: "Lemon" },
    { value: "Lemonada", label: "Lemonada" },
    { value: "Lexend", label: "Lexend" },
    { value: "Lexend Deca", label: "Lexend Deca" },
    { value: "Lexend Exa", label: "Lexend Exa" },
    { value: "Lexend Giga", label: "Lexend Giga" },
    { value: "Lexend Mega", label: "Lexend Mega" },
    { value: "Lexend Peta", label: "Lexend Peta" },
    { value: "Lexend Tera", label: "Lexend Tera" },
    { value: "Lexend Zetta", label: "Lexend Zetta" },
    { value: "Libre Barcode 128", label: "Libre Barcode 128" },
    { value: "Libre Barcode 128 Text", label: "Libre Barcode 128 Text" },
    { value: "Libre Barcode 39", label: "Libre Barcode 39" },
    { value: "Libre Barcode 39 Extended", label: "Libre Barcode 39 Extended" },
    {
      value: "Libre Barcode 39 Extended Text",
      label: "Libre Barcode 39 Extended Text",
    },
    { value: "Libre Barcode 39 Text", label: "Libre Barcode 39 Text" },
    { value: "Libre Barcode EAN13 Text", label: "Libre Barcode EAN13 Text" },
    { value: "Libre Baskerville", label: "Libre Baskerville" },
    { value: "Libre Caslon Display", label: "Libre Caslon Display" },
    { value: "Libre Caslon Text", label: "Libre Caslon Text" },
    { value: "Libre Franklin", label: "Libre Franklin" },
    { value: "Licorice", label: "Licorice" },
    { value: "Life Savers", label: "Life Savers" },
    { value: "Lilita One", label: "Lilita One" },
    { value: "Lily Script One", label: "Lily Script One" },
    { value: "Limelight", label: "Limelight" },
    { value: "Linden Hill", label: "Linden Hill" },
    { value: "Literata", label: "Literata" },
    { value: "Liu Jian Mao Cao", label: "Liu Jian Mao Cao" },
    { value: "Livvic", label: "Livvic" },
    { value: "Lobster", label: "Lobster" },
    { value: "Lobster Two", label: "Lobster Two" },
    { value: "Londrina Outline", label: "Londrina Outline" },
    { value: "Londrina Shadow", label: "Londrina Shadow" },
    { value: "Londrina Sketch", label: "Londrina Sketch" },
    { value: "Londrina Solid", label: "Londrina Solid" },
    { value: "Long Cang", label: "Long Cang" },
    { value: "Lora", label: "Lora" },
    { value: "Love Light", label: "Love Light" },
    { value: "Love Ya Like A Sister", label: "Love Ya Like A Sister" },
    { value: "Loved by the King", label: "Loved by the King" },
    { value: "Lovers Quarrel", label: "Lovers Quarrel" },
    { value: "Luckiest Guy", label: "Luckiest Guy" },
    { value: "Lusitana", label: "Lusitana" },
    { value: "Lustria", label: "Lustria" },
    { value: "Luxurious Roman", label: "Luxurious Roman" },
    { value: "Luxurious Script", label: "Luxurious Script" },
    { value: "M PLUS 1", label: "M PLUS 1" },
    { value: "M PLUS 1 Code", label: "M PLUS 1 Code" },
    { value: "M PLUS 1p", label: "M PLUS 1p" },
    { value: "M PLUS 2", label: "M PLUS 2" },
    { value: "M PLUS Code Latin", label: "M PLUS Code Latin" },
    { value: "M PLUS Rounded 1c", label: "M PLUS Rounded 1c" },
    { value: "Ma Shan Zheng", label: "Ma Shan Zheng" },
    { value: "Macondo", label: "Macondo" },
    { value: "Macondo Swash Caps", label: "Macondo Swash Caps" },
    { value: "Mada", label: "Mada" },
    { value: "Magra", label: "Magra" },
    { value: "Maiden Orange", label: "Maiden Orange" },
    { value: "Maitree", label: "Maitree" },
    { value: "Major Mono Display", label: "Major Mono Display" },
    { value: "Mako", label: "Mako" },
    { value: "Mali", label: "Mali" },
    { value: "Mallanna", label: "Mallanna" },
    { value: "Mandali", label: "Mandali" },
    { value: "Manjari", label: "Manjari" },
    { value: "Manrope", label: "Manrope" },
    { value: "Mansalva", label: "Mansalva" },
    { value: "Manuale", label: "Manuale" },
    { value: "Marcellus", label: "Marcellus" },
    { value: "Marcellus SC", label: "Marcellus SC" },
    { value: "Marck Script", label: "Marck Script" },
    { value: "Margarine", label: "Margarine" },
    { value: "Markazi Text", label: "Markazi Text" },
    { value: "Marko One", label: "Marko One" },
    { value: "Marmelad", label: "Marmelad" },
    { value: "Martel", label: "Martel" },
    { value: "Martel Sans", label: "Martel Sans" },
    { value: "Marvel", label: "Marvel" },
    { value: "Mate", label: "Mate" },
    { value: "Mate SC", label: "Mate SC" },
    { value: "Maven Pro", label: "Maven Pro" },
    { value: "McLaren", label: "McLaren" },
    { value: "Mea Culpa", label: "Mea Culpa" },
    { value: "Meddon", label: "Meddon" },
    { value: "MedievalSharp", label: "MedievalSharp" },
    { value: "Medula One", label: "Medula One" },
    { value: "Meera Inimai", label: "Meera Inimai" },
    { value: "Megrim", label: "Megrim" },
    { value: "Meie Script", label: "Meie Script" },
    { value: "Meow Script", label: "Meow Script" },
    { value: "Merienda", label: "Merienda" },
    { value: "Merienda One", label: "Merienda One" },
    { value: "Merriweather", label: "Merriweather" },
    { value: "Merriweather Sans", label: "Merriweather Sans" },
    { value: "Metal", label: "Metal" },
    { value: "Metal Mania", label: "Metal Mania" },
    { value: "Metamorphous", label: "Metamorphous" },
    { value: "Metrophobic", label: "Metrophobic" },
    { value: "Michroma", label: "Michroma" },
    { value: "Milonga", label: "Milonga" },
    { value: "Miltonian", label: "Miltonian" },
    { value: "Miltonian Tattoo", label: "Miltonian Tattoo" },
    { value: "Mina", label: "Mina" },
    { value: "Miniver", label: "Miniver" },
    { value: "Miriam Libre", label: "Miriam Libre" },
    { value: "Mirza", label: "Mirza" },
    { value: "Miss Fajardose", label: "Miss Fajardose" },
    { value: "Mitr", label: "Mitr" },
    { value: "Mochiy Pop One", label: "Mochiy Pop One" },
    { value: "Mochiy Pop P One", label: "Mochiy Pop P One" },
    { value: "Modak", label: "Modak" },
    { value: "Modern Antiqua", label: "Modern Antiqua" },
    { value: "Mogra", label: "Mogra" },
    { value: "Mohave", label: "Mohave" },
    { value: "Molengo", label: "Molengo" },
    { value: "Molle", label: "Molle" },
    { value: "Monda", label: "Monda" },
    { value: "Monofett", label: "Monofett" },
    { value: "Monoton", label: "Monoton" },
    { value: "Monsieur La Doulaise", label: "Monsieur La Doulaise" },
    { value: "Montaga", label: "Montaga" },
    { value: "Montagu Slab", label: "Montagu Slab" },
    { value: "MonteCarlo", label: "MonteCarlo" },
    { value: "Montez", label: "Montez" },
    { value: "Montserrat", label: "Montserrat" },
    { value: "Montserrat Alternates", label: "Montserrat Alternates" },
    { value: "Montserrat Subrayada", label: "Montserrat Subrayada" },
    { value: "Moo Lah Lah", label: "Moo Lah Lah" },
    { value: "Moon Dance", label: "Moon Dance" },
    { value: "Moul", label: "Moul" },
    { value: "Moulpali", label: "Moulpali" },
    { value: "Mountains of Christmas", label: "Mountains of Christmas" },
    { value: "Mouse Memoirs", label: "Mouse Memoirs" },
    { value: "Mr Bedfort", label: "Mr Bedfort" },
    { value: "Mr Dafoe", label: "Mr Dafoe" },
    { value: "Mr De Haviland", label: "Mr De Haviland" },
    { value: "Mrs Saint Delafield", label: "Mrs Saint Delafield" },
    { value: "Mrs Sheppards", label: "Mrs Sheppards" },
    { value: "Mukta", label: "Mukta" },
    { value: "Mukta Mahee", label: "Mukta Mahee" },
    { value: "Mukta Malar", label: "Mukta Malar" },
    { value: "Mukta Vaani", label: "Mukta Vaani" },
    { value: "Mulish", label: "Mulish" },
    { value: "Murecho", label: "Murecho" },
    { value: "MuseoModerno", label: "MuseoModerno" },
    { value: "Mystery Quest", label: "Mystery Quest" },
    { value: "Nanum Brush Script", label: "Nanum Brush Script" },
    { value: "Nanum Gothic", label: "Nanum Gothic" },
    { value: "Nanum Gothic Coding", label: "Nanum Gothic Coding" },
    { value: "Nanum Myeongjo", label: "Nanum Myeongjo" },
    { value: "Nanum Pen Script", label: "Nanum Pen Script" },
    { value: "Nerko One", label: "Nerko One" },
    { value: "Neucha", label: "Neucha" },
    { value: "Neuton", label: "Neuton" },
    { value: "New Rocker", label: "New Rocker" },
    { value: "New Tegomin", label: "New Tegomin" },
    { value: "News Cycle", label: "News Cycle" },
    { value: "Newsreader", label: "Newsreader" },
    { value: "Niconne", label: "Niconne" },
    { value: "Niramit", label: "Niramit" },
    { value: "Nixie One", label: "Nixie One" },
    { value: "Nobile", label: "Nobile" },
    { value: "Nokora", label: "Nokora" },
    { value: "Norican", label: "Norican" },
    { value: "Nosifer", label: "Nosifer" },
    { value: "Notable", label: "Notable" },
    { value: "Nothing You Could Do", label: "Nothing You Could Do" },
    { value: "Noticia Text", label: "Noticia Text" },
    { value: "Noto Kufi Arabic", label: "Noto Kufi Arabic" },
    { value: "Noto Music", label: "Noto Music" },
    { value: "Noto Naskh Arabic", label: "Noto Naskh Arabic" },
    { value: "Noto Nastaliq Urdu", label: "Noto Nastaliq Urdu" },
    { value: "Noto Rashi Hebrew", label: "Noto Rashi Hebrew" },
    { value: "Noto Sans", label: "Noto Sans" },
    { value: "Noto Sans Adlam", label: "Noto Sans Adlam" },
    { value: "Noto Sans Adlam Unjoined", label: "Noto Sans Adlam Unjoined" },
    {
      value: "Noto Sans Anatolian Hieroglyphs",
      label: "Noto Sans Anatolian Hieroglyphs",
    },
    { value: "Noto Sans Arabic", label: "Noto Sans Arabic" },
    { value: "Noto Sans Armenian", label: "Noto Sans Armenian" },
    { value: "Noto Sans Avestan", label: "Noto Sans Avestan" },
    { value: "Noto Sans Balinese", label: "Noto Sans Balinese" },
    { value: "Noto Sans Bamum", label: "Noto Sans Bamum" },
    { value: "Noto Sans Bassa Vah", label: "Noto Sans Bassa Vah" },
    { value: "Noto Sans Batak", label: "Noto Sans Batak" },
    { value: "Noto Sans Bengali", label: "Noto Sans Bengali" },
    { value: "Noto Sans Bhaiksuki", label: "Noto Sans Bhaiksuki" },
    { value: "Noto Sans Brahmi", label: "Noto Sans Brahmi" },
    { value: "Noto Sans Buginese", label: "Noto Sans Buginese" },
    { value: "Noto Sans Buhid", label: "Noto Sans Buhid" },
    {
      value: "Noto Sans Canadian Aboriginal",
      label: "Noto Sans Canadian Aboriginal",
    },
    { value: "Noto Sans Carian", label: "Noto Sans Carian" },
    {
      value: "Noto Sans Caucasian Albanian",
      label: "Noto Sans Caucasian Albanian",
    },
    { value: "Noto Sans Chakma", label: "Noto Sans Chakma" },
    { value: "Noto Sans Cham", label: "Noto Sans Cham" },
    { value: "Noto Sans Cherokee", label: "Noto Sans Cherokee" },
    { value: "Noto Sans Coptic", label: "Noto Sans Coptic" },
    { value: "Noto Sans Cuneiform", label: "Noto Sans Cuneiform" },
    { value: "Noto Sans Cypriot", label: "Noto Sans Cypriot" },
    { value: "Noto Sans Deseret", label: "Noto Sans Deseret" },
    { value: "Noto Sans Devanagari", label: "Noto Sans Devanagari" },
    { value: "Noto Sans Display", label: "Noto Sans Display" },
    { value: "Noto Sans Duployan", label: "Noto Sans Duployan" },
    {
      value: "Noto Sans Egyptian Hieroglyphs",
      label: "Noto Sans Egyptian Hieroglyphs",
    },
    { value: "Noto Sans Elbasan", label: "Noto Sans Elbasan" },
    { value: "Noto Sans Elymaic", label: "Noto Sans Elymaic" },
    { value: "Noto Sans Georgian", label: "Noto Sans Georgian" },
    { value: "Noto Sans Glagolitic", label: "Noto Sans Glagolitic" },
    { value: "Noto Sans Gothic", label: "Noto Sans Gothic" },
    { value: "Noto Sans Grantha", label: "Noto Sans Grantha" },
    { value: "Noto Sans Gujarati", label: "Noto Sans Gujarati" },
    { value: "Noto Sans Gunjala Gondi", label: "Noto Sans Gunjala Gondi" },
    { value: "Noto Sans Gurmukhi", label: "Noto Sans Gurmukhi" },
    { value: "Noto Sans Hanifi Rohingya", label: "Noto Sans Hanifi Rohingya" },
    { value: "Noto Sans Hanunoo", label: "Noto Sans Hanunoo" },
    { value: "Noto Sans Hatran", label: "Noto Sans Hatran" },
    { value: "Noto Sans Hebrew", label: "Noto Sans Hebrew" },
    { value: "Noto Sans Hong Kong", label: "Noto Sans Hong Kong" },
    {
      value: "Noto Sans Imperial Aramaic",
      label: "Noto Sans Imperial Aramaic",
    },
    {
      value: "Noto Sans Indic Siyaq Numbers",
      label: "Noto Sans Indic Siyaq Numbers",
    },
    {
      value: "Noto Sans Inscriptional Pahlavi",
      label: "Noto Sans Inscriptional Pahlavi",
    },
    {
      value: "Noto Sans Inscriptional Parthian",
      label: "Noto Sans Inscriptional Parthian",
    },
    { value: "Noto Sans Japanese", label: "Noto Sans Japanese" },
    { value: "Noto Sans Javanese", label: "Noto Sans Javanese" },
    { value: "Noto Sans Kaithi", label: "Noto Sans Kaithi" },
    { value: "Noto Sans Kannada", label: "Noto Sans Kannada" },
    { value: "Noto Sans Kayah Li", label: "Noto Sans Kayah Li" },
    { value: "Noto Sans Kharoshthi", label: "Noto Sans Kharoshthi" },
    { value: "Noto Sans Khmer", label: "Noto Sans Khmer" },
    { value: "Noto Sans Khojki", label: "Noto Sans Khojki" },
    { value: "Noto Sans Khudawadi", label: "Noto Sans Khudawadi" },
    { value: "Noto Sans Korean", label: "Noto Sans Korean" },
    { value: "Noto Sans Lao", label: "Noto Sans Lao" },
    { value: "Noto Sans Lepcha", label: "Noto Sans Lepcha" },
    { value: "Noto Sans Limbu", label: "Noto Sans Limbu" },
    { value: "Noto Sans Linear A", label: "Noto Sans Linear A" },
    { value: "Noto Sans Linear B", label: "Noto Sans Linear B" },
    { value: "Noto Sans Lisu", label: "Noto Sans Lisu" },
    { value: "Noto Sans Lycian", label: "Noto Sans Lycian" },
    { value: "Noto Sans Lydian", label: "Noto Sans Lydian" },
    { value: "Noto Sans Mahajani", label: "Noto Sans Mahajani" },
    { value: "Noto Sans Malayalam", label: "Noto Sans Malayalam" },
    { value: "Noto Sans Mandaic", label: "Noto Sans Mandaic" },
    { value: "Noto Sans Manichaean", label: "Noto Sans Manichaean" },
    { value: "Noto Sans Marchen", label: "Noto Sans Marchen" },
    { value: "Noto Sans Masaram Gondi", label: "Noto Sans Masaram Gondi" },
    { value: "Noto Sans Math", label: "Noto Sans Math" },
    { value: "Noto Serif Thai", label: "Noto Serif Thai" },
    { value: "Noto Serif Tibetan", label: "Noto Serif Tibetan" },
    {
      value: "Noto Serif Traditional Chinese",
      label: "Noto Serif Traditional Chinese",
    },
    { value: "Noto Serif Yezidi", label: "Noto Serif Yezidi" },
    { value: "Noto Traditional Nüshu", label: "Noto Traditional Nüshu" },
    { value: "Nova Cut", label: "Nova Cut" },
    { value: "Nova Flat", label: "Nova Flat" },
    { value: "Nova Mono", label: "Nova Mono" },
    { value: "Nova Oval", label: "Nova Oval" },
    { value: "Nova Round", label: "Nova Round" },
    { value: "Nova Script", label: "Nova Script" },
    { value: "Nova Slim", label: "Nova Slim" },
    { value: "Nova Square", label: "Nova Square" },
    { value: "NTR", label: "NTR" },
    { value: "Numans", label: "Numans" },
    { value: "Nunito", label: "Nunito" },
    { value: "Nunito Sans", label: "Nunito Sans" },
    { value: "Odibee Sans", label: "Odibee Sans" },
    { value: "Odor Mean Chey", label: "Odor Mean Chey" },
    { value: "Offside", label: "Offside" },
    { value: "Oi", label: "Oi" },
    { value: "Old Standard TT", label: "Old Standard TT" },
    { value: "Oldenburg", label: "Oldenburg" },
    { value: "Ole", label: "Ole" },
    { value: "Oleo Script", label: "Oleo Script" },
    { value: "Oleo Script Swash Caps", label: "Oleo Script Swash Caps" },
    { value: "Oooh Baby", label: "Oooh Baby" },
    { value: "Open Sans", label: "Open Sans" },
    { value: "Open Sans Condensed", label: "Open Sans Condensed" },
    { value: "Oranienbaum", label: "Oranienbaum" },
    { value: "Orbitron", label: "Orbitron" },
    { value: "Oregano", label: "Oregano" },
    { value: "Orelega One", label: "Orelega One" },
    { value: "Orienta", label: "Orienta" },
    { value: "Original Surfer", label: "Original Surfer" },
    { value: "Oswald", label: "Oswald" },
    { value: "Otomanopee One", label: "Otomanopee One" },
    { value: "Outfit", label: "Outfit" },
    { value: "Over the Rainbow", label: "Over the Rainbow" },
    { value: "Overlock", label: "Overlock" },
    { value: "Overlock SC", label: "Overlock SC" },
    { value: "Overpass", label: "Overpass" },
    { value: "Overpass Mono", label: "Overpass Mono" },
    { value: "Ovo", label: "Ovo" },
    { value: "Oxanium", label: "Oxanium" },
    { value: "Oxygen", label: "Oxygen" },
    { value: "Oxygen Mono", label: "Oxygen Mono" },
    { value: "Pacifico", label: "Pacifico" },
    { value: "Padauk", label: "Padauk" },
    { value: "Palanquin", label: "Palanquin" },
    { value: "Palanquin Dark", label: "Palanquin Dark" },
    { value: "Palette Mosaic", label: "Palette Mosaic" },
    { value: "Pangolin", label: "Pangolin" },
    { value: "Paprika", label: "Paprika" },
    { value: "Parisienne", label: "Parisienne" },
    { value: "Passero One", label: "Passero One" },
    { value: "Passion One", label: "Passion One" },
    { value: "Passions Conflict", label: "Passions Conflict" },
    { value: "Pathway Gothic One", label: "Pathway Gothic One" },
    { value: "Patrick Hand", label: "Patrick Hand" },
    { value: "Patrick Hand SC", label: "Patrick Hand SC" },
    { value: "Pattaya", label: "Pattaya" },
    { value: "Patua One", label: "Patua One" },
    { value: "Pavanam", label: "Pavanam" },
    { value: "Paytone One", label: "Paytone One" },
    { value: "Peddana", label: "Peddana" },
    { value: "Peralta", label: "Peralta" },
    { value: "Permanent Marker", label: "Permanent Marker" },
    { value: "Philosopher", label: "Philosopher" },
    { value: "Piazzolla", label: "Piazzolla" },
    { value: "Piedra", label: "Piedra" },
    { value: "Pinyon Script", label: "Pinyon Script" },
    { value: "Pirata One", label: "Pirata One" },
    { value: "Plaster", label: "Plaster" },
    { value: "Play", label: "Play" },
    { value: "Playball", label: "Playball" },
    { value: "Playfair Display", label: "Playfair Display" },
    { value: "Playfair Display SC", label: "Playfair Display SC" },
    { value: "Podkova", label: "Podkova" },
    { value: "Poiret One", label: "Poiret One" },
    { value: "Poller One", label: "Poller One" },
    { value: "Poly", label: "Poly" },
    { value: "Pompiere", label: "Pompiere" },
    { value: "Pontano Sans", label: "Pontano Sans" },
    { value: "Poor Story", label: "Poor Story" },
    { value: "Poppins", label: "Poppins" },
    { value: "Port Lligat Sans", label: "Port Lligat Sans" },
    { value: "Port Lligat Slab", label: "Port Lligat Slab" },
    { value: "Potta One", label: "Potta One" },
    { value: "Pragati Narrow", label: "Pragati Narrow" },
    { value: "Praise", label: "Praise" },
    { value: "Prata", label: "Prata" },
    { value: "Preahvihear", label: "Preahvihear" },
    { value: "Press Start 2P", label: "Press Start 2P" },
    { value: "Pridi", label: "Pridi" },
    { value: "Princess Sofia", label: "Princess Sofia" },
    { value: "Prociono", label: "Prociono" },
    { value: "Prompt", label: "Prompt" },
    { value: "Prosto One", label: "Prosto One" },
    { value: "Proza Libre", label: "Proza Libre" },
    { value: "PT Mono", label: "PT Mono" },
    { value: "PT Sans", label: "PT Sans" },
    { value: "PT Sans Caption", label: "PT Sans Caption" },
    { value: "PT Sans Narrow", label: "PT Sans Narrow" },
    { value: "PT Serif", label: "PT Serif" },
    { value: "PT Serif Caption", label: "PT Serif Caption" },
    { value: "Public Sans", label: "Public Sans" },
    { value: "Puppies Play", label: "Puppies Play" },
    { value: "Puritan", label: "Puritan" },
    { value: "Purple Purse", label: "Purple Purse" },
    { value: "Pushster", label: "Pushster" },
    { value: "Qahiri", label: "Qahiri" },
    { value: "Quando", label: "Quando" },
    { value: "Quantico", label: "Quantico" },
    { value: "Quattrocento", label: "Quattrocento" },
    { value: "Quattrocento Sans", label: "Quattrocento Sans" },
    { value: "Questrial", label: "Questrial" },
    { value: "Quicksand", label: "Quicksand" },
    { value: "Quintessential", label: "Quintessential" },
    { value: "Qwigley", label: "Qwigley" },
    { value: "Qwitcher Grypen", label: "Qwitcher Grypen" },
    { value: "Racing Sans One", label: "Racing Sans One" },
    { value: "Radley", label: "Radley" },
    { value: "Rajdhani", label: "Rajdhani" },
    { value: "Rakkas", label: "Rakkas" },
    { value: "Raleway", label: "Raleway" },
    { value: "Raleway Dots", label: "Raleway Dots" },
    { value: "Ramabhadra", label: "Ramabhadra" },
    { value: "Ramaraja", label: "Ramaraja" },
    { value: "Rambla", label: "Rambla" },
    { value: "Rammetto One", label: "Rammetto One" },
    { value: "Rampart One", label: "Rampart One" },
    { value: "Ranchers", label: "Ranchers" },
    { value: "Rancho", label: "Rancho" },
    { value: "Ranga", label: "Ranga" },
    { value: "Rasa", label: "Rasa" },
    { value: "Rationale", label: "Rationale" },
    { value: "Ravi Prakash", label: "Ravi Prakash" },
    { value: "Readex Pro", label: "Readex Pro" },
    { value: "Recursive", label: "Recursive" },
    { value: "Red Hat Display", label: "Red Hat Display" },
    { value: "Red Hat Mono", label: "Red Hat Mono" },
    { value: "Red Hat Text", label: "Red Hat Text" },
    { value: "Red Rose", label: "Red Rose" },
    { value: "Redacted", label: "Redacted" },
    { value: "Redacted Script", label: "Redacted Script" },
    { value: "Redressed", label: "Redressed" },
    { value: "Reem Kufi", label: "Reem Kufi" },
    { value: "Reenie Beanie", label: "Reenie Beanie" },
    { value: "Reggae One", label: "Reggae One" },
    { value: "Revalia", label: "Revalia" },
    { value: "Rhodium Libre", label: "Rhodium Libre" },
    { value: "Ribeye", label: "Ribeye" },
    { value: "Ribeye Marrow", label: "Ribeye Marrow" },
    { value: "Righteous", label: "Righteous" },
    { value: "Risque", label: "Risque" },
    { value: "Road Rage", label: "Road Rage" },
    { value: "Roboto", label: "Roboto" },
    { value: "Roboto Condensed", label: "Roboto Condensed" },
    { value: "Roboto Mono", label: "Roboto Mono" },
    { value: "Roboto Slab", label: "Roboto Slab" },
    { value: "Rochester", label: "Rochester" },
    { value: "Rock 3D", label: "Rock 3D" },
    { value: "Rock Salt", label: "Rock Salt" },
    { value: "RocknRoll One", label: "RocknRoll One" },
    { value: "Rokkitt", label: "Rokkitt" },
    { value: "Romanesco", label: "Romanesco" },
    { value: "Ropa Sans", label: "Ropa Sans" },
    { value: "Rosario", label: "Rosario" },
    { value: "Rosarivo", label: "Rosarivo" },
    { value: "Rouge Script", label: "Rouge Script" },
    { value: "Rowdies", label: "Rowdies" },
    { value: "Rozha One", label: "Rozha One" },
    { value: "Rubik", label: "Rubik" },
    { value: "Rubik Beastly", label: "Rubik Beastly" },
    { value: "Rubik Mono One", label: "Rubik Mono One" },
    { value: "Ruda", label: "Ruda" },
    { value: "Rufina", label: "Rufina" },
    { value: "Ruge Boogie", label: "Ruge Boogie" },
    { value: "Ruluko", label: "Ruluko" },
    { value: "Rum Raisin", label: "Rum Raisin" },
    { value: "Ruslan Display", label: "Ruslan Display" },
    { value: "Russo One", label: "Russo One" },
    { value: "Ruthie", label: "Ruthie" },
    { value: "Rye", label: "Rye" },
    { value: "Sacramento", label: "Sacramento" },
    { value: "Sahitya", label: "Sahitya" },
    { value: "Sail", label: "Sail" },
    { value: "Saira", label: "Saira" },
    { value: "Saira Condensed", label: "Saira Condensed" },
    { value: "Saira Extra Condensed", label: "Saira Extra Condensed" },
    { value: "Saira Semi Condensed", label: "Saira Semi Condensed" },
    { value: "Saira Stencil One", label: "Saira Stencil One" },
    { value: "Salsa", label: "Salsa" },
    { value: "Sanchez", label: "Sanchez" },
    { value: "Sancreek", label: "Sancreek" },
    { value: "Sansita", label: "Sansita" },
    { value: "Sansita Swashed", label: "Sansita Swashed" },
    { value: "Sarabun", label: "Sarabun" },
    { value: "Sarala", label: "Sarala" },
    { value: "Sarina", label: "Sarina" },
    { value: "Sarpanch", label: "Sarpanch" },
    { value: "Sassy Frass", label: "Sassy Frass" },
    { value: "Satisfy", label: "Satisfy" },
    { value: "Sawarabi Gothic", label: "Sawarabi Gothic" },
    { value: "Sawarabi Mincho", label: "Sawarabi Mincho" },
    { value: "Scada", label: "Scada" },
    { value: "Scheherazade New", label: "Scheherazade New" },
    { value: "Schoolbell", label: "Schoolbell" },
    { value: "Scope One", label: "Scope One" },
    { value: "Seaweed Script", label: "Seaweed Script" },
    { value: "Secular One", label: "Secular One" },
    { value: "Sedgwick Ave", label: "Sedgwick Ave" },
    { value: "Sedgwick Ave Display", label: "Sedgwick Ave Display" },
    { value: "Sen", label: "Sen" },
    { value: "Sevillana", label: "Sevillana" },
    { value: "Seymour One", label: "Seymour One" },
    { value: "Shadows Into Light", label: "Shadows Into Light" },
    { value: "Shadows Into Light Two", label: "Shadows Into Light Two" },
    { value: "Shalimar", label: "Shalimar" },
    { value: "Shanti", label: "Shanti" },
    { value: "Share", label: "Share" },
    { value: "Share Tech", label: "Share Tech" },
    { value: "Share Tech Mono", label: "Share Tech Mono" },
    { value: "Shippori Antique", label: "Shippori Antique" },
    { value: "Shippori Antique B1", label: "Shippori Antique B1" },
    { value: "Shippori Mincho", label: "Shippori Mincho" },
    { value: "Shippori Mincho B1", label: "Shippori Mincho B1" },
    { value: "Shizuru", label: "Shizuru" },
    { value: "Shojumaru", label: "Shojumaru" },
    { value: "Short Stack", label: "Short Stack" },
    { value: "Shrikhand", label: "Shrikhand" },
    { value: "Siemreap", label: "Siemreap" },
    { value: "Sigmar One", label: "Sigmar One" },
    { value: "Signika", label: "Signika" },
    { value: "Signika Negative", label: "Signika Negative" },
    { value: "Simonetta", label: "Simonetta" },
    { value: "Single Day", label: "Single Day" },
    { value: "Sintony", label: "Sintony" },
    { value: "Sirin Stencil", label: "Sirin Stencil" },
    { value: "Six Caps", label: "Six Caps" },
    { value: "Skranji", label: "Skranji" },
    { value: "Slabo 13px", label: "Slabo 13px" },
    { value: "Slabo 27px", label: "Slabo 27px" },
    { value: "Slackey", label: "Slackey" },
    { value: "Smokum", label: "Smokum" },
    { value: "Smooch", label: "Smooch" },
    { value: "Smythe", label: "Smythe" },
    { value: "Sniglet", label: "Sniglet" },
    { value: "Snippet", label: "Snippet" },
    { value: "Snowburst One", label: "Snowburst One" },
    { value: "Sofadi One", label: "Sofadi One" },
    { value: "Sofia", label: "Sofia" },
    { value: "Solway", label: "Solway" },
    { value: "Song Myung", label: "Song Myung" },
    { value: "Sonsie One", label: "Sonsie One" },
    { value: "Sora", label: "Sora" },
    { value: "Sorts Mill Goudy", label: "Sorts Mill Goudy" },
    { value: "Source Code Pro", label: "Source Code Pro" },
    { value: "Source Sans 3", label: "Source Sans 3" },
    { value: "Source Sans Pro", label: "Source Sans Pro" },
    { value: "Source Serif 4", label: "Source Serif 4" },
    { value: "Source Serif Pro", label: "Source Serif Pro" },
    { value: "Space Grotesk", label: "Space Grotesk" },
    { value: "Space Mono", label: "Space Mono" },
    { value: "Spartan", label: "Spartan" },
    { value: "Special Elite", label: "Special Elite" },
    { value: "Spectral", label: "Spectral" },
    { value: "Spectral SC", label: "Spectral SC" },
    { value: "Spicy Rice", label: "Spicy Rice" },
    { value: "Spinnaker", label: "Spinnaker" },
    { value: "Spirax", label: "Spirax" },
    { value: "Spline Sans", label: "Spline Sans" },
    { value: "Squada One", label: "Squada One" },
    { value: "Sree Krushnadevaraya", label: "Sree Krushnadevaraya" },
    { value: "Sriracha", label: "Sriracha" },
    { value: "Srisakdi", label: "Srisakdi" },
    { value: "Staatliches", label: "Staatliches" },
    { value: "Stalemate", label: "Stalemate" },
    { value: "Stalinist One", label: "Stalinist One" },
    { value: "Stardos Stencil", label: "Stardos Stencil" },
    { value: "Stick", label: "Stick" },
    { value: "Stick No Bills", label: "Stick No Bills" },
    { value: "Stint Ultra Condensed", label: "Stint Ultra Condensed" },
    { value: "Stint Ultra Expanded", label: "Stint Ultra Expanded" },
    { value: "STIX Two Text", label: "STIX Two Text" },
    { value: "Stoke", label: "Stoke" },
    { value: "Strait", label: "Strait" },
    { value: "Style Script", label: "Style Script" },
    { value: "Stylish", label: "Stylish" },
    { value: "Sue Ellen Francisco", label: "Sue Ellen Francisco" },
    { value: "Suez One", label: "Suez One" },
    { value: "Sulphur Point", label: "Sulphur Point" },
    { value: "Sumana", label: "Sumana" },
    { value: "Sunflower", label: "Sunflower" },
    { value: "Sunshiney", label: "Sunshiney" },
    { value: "Supermercado One", label: "Supermercado One" },
    { value: "Sura", label: "Sura" },
    { value: "Suranna", label: "Suranna" },
    { value: "Suravaram", label: "Suravaram" },
    { value: "Suwannaphum", label: "Suwannaphum" },
    { value: "Swanky and Moo Moo", label: "Swanky and Moo Moo" },
    { value: "Syncopate", label: "Syncopate" },
    { value: "Syne", label: "Syne" },
    { value: "Syne Mono", label: "Syne Mono" },
    { value: "Syne Tactile", label: "Syne Tactile" },
    { value: "Tajawal", label: "Tajawal" },
    { value: "Tangerine", label: "Tangerine" },
    { value: "Taprom", label: "Taprom" },
    { value: "Tauri", label: "Tauri" },
    { value: "Taviraj", label: "Taviraj" },
    { value: "Teko", label: "Teko" },
    { value: "Telex", label: "Telex" },
    { value: "Tenali Ramakrishna", label: "Tenali Ramakrishna" },
    { value: "Tenor Sans", label: "Tenor Sans" },
    { value: "Text Me One", label: "Text Me One" },
    { value: "Texturina", label: "Texturina" },
    { value: "Thasadith", label: "Thasadith" },
    { value: "The Girl Next Door", label: "The Girl Next Door" },
    { value: "The Nautigal", label: "The Nautigal" },
    { value: "Tienne", label: "Tienne" },
    { value: "Tillana", label: "Tillana" },
    { value: "Timmana", label: "Timmana" },
    { value: "Tinos", label: "Tinos" },
    { value: "Titan One", label: "Titan One" },
    { value: "Titillium Web", label: "Titillium Web" },
    { value: "Tomorrow", label: "Tomorrow" },
    { value: "Tourney", label: "Tourney" },
    { value: "Trade Winds", label: "Trade Winds" },
    { value: "Train One", label: "Train One" },
    { value: "Trirong", label: "Trirong" },
    { value: "Trispace", label: "Trispace" },
    { value: "Trocchi", label: "Trocchi" },
    { value: "Trochut", label: "Trochut" },
    { value: "Truculenta", label: "Truculenta" },
    { value: "Trykker", label: "Trykker" },
    { value: "Tulpen One", label: "Tulpen One" },
    { value: "Turret Road", label: "Turret Road" },
    { value: "Twinkle Star", label: "Twinkle Star" },
    { value: "Ubuntu", label: "Ubuntu" },
    { value: "Ubuntu Condensed", label: "Ubuntu Condensed" },
    { value: "Ubuntu Mono", label: "Ubuntu Mono" },
    { value: "Uchen", label: "Uchen" },
    { value: "Ultra", label: "Ultra" },
    { value: "Uncial Antiqua", label: "Uncial Antiqua" },
    { value: "Underdog", label: "Underdog" },
    { value: "Unica One", label: "Unica One" },
    { value: "UnifrakturCook", label: "UnifrakturCook" },
    { value: "UnifrakturMaguntia", label: "UnifrakturMaguntia" },
    { value: "Unkempt", label: "Unkempt" },
    { value: "Unlock", label: "Unlock" },
    { value: "Unna", label: "Unna" },
    { value: "Urbanist", label: "Urbanist" },
    { value: "Vampiro One", label: "Vampiro One" },
    { value: "Varela", label: "Varela" },
    { value: "Varela Round", label: "Varela Round" },
    { value: "Varta", label: "Varta" },
    { value: "Vast Shadow", label: "Vast Shadow" },
    { value: "Vesper Libre", label: "Vesper Libre" },
    { value: "Viaoda Libre", label: "Viaoda Libre" },
    { value: "Vibes", label: "Vibes" },
    { value: "Vibur", label: "Vibur" },
    { value: "Vidaloka", label: "Vidaloka" },
    { value: "Viga", label: "Viga" },
    { value: "Voces", label: "Voces" },
    { value: "Volkhov", label: "Volkhov" },
    { value: "Vollkorn", label: "Vollkorn" },
    { value: "Vollkorn SC", label: "Vollkorn SC" },
    { value: "Voltaire", label: "Voltaire" },
    { value: "VT323", label: "VT323" },
    { value: "Vujahday Script", label: "Vujahday Script" },
    { value: "Waiting for the Sunrise", label: "Waiting for the Sunrise" },
    { value: "Wallpoet", label: "Wallpoet" },
    { value: "Walter Turncoat", label: "Walter Turncoat" },
    { value: "Warnes", label: "Warnes" },
    { value: "Waterfall", label: "Waterfall" },
    { value: "Wellfleet", label: "Wellfleet" },
    { value: "Wendy One", label: "Wendy One" },
    { value: "WindSong", label: "WindSong" },
    { value: "Wire One", label: "Wire One" },
    { value: "Work Sans", label: "Work Sans" },
    { value: "Xanh Mono", label: "Xanh Mono" },
    { value: "Yaldevi", label: "Yaldevi" },
    { value: "Yanone Kaffeesatz", label: "Yanone Kaffeesatz" },
    { value: "Yantramanav", label: "Yantramanav" },
    { value: "Yatra One", label: "Yatra One" },
    { value: "Yellowtail", label: "Yellowtail" },
    { value: "Yeon Sung", label: "Yeon Sung" },
    { value: "Yeseva One", label: "Yeseva One" },
    { value: "Yesteryear", label: "Yesteryear" },
    { value: "Yomogi", label: "Yomogi" },
    { value: "Yrsa", label: "Yrsa" },
    { value: "Yuji Boku", label: "Yuji Boku" },
    { value: "Yuji Hentaigana Akari", label: "Yuji Hentaigana Akari" },
    { value: "Yuji Hentaigana Akebono", label: "Yuji Hentaigana Akebono" },
    { value: "Yuji Mai", label: "Yuji Mai" },
    { value: "Yuji Syuku", label: "Yuji Syuku" },
    { value: "Yusei Magic", label: "Yusei Magic" },
    { value: "ZCOOL KuaiLe", label: "ZCOOL KuaiLe" },
    { value: "ZCOOL QingKe HuangYou", label: "ZCOOL QingKe HuangYou" },
    { value: "ZCOOL XiaoWei", label: "ZCOOL XiaoWei" },
    { value: "Zen Antique", label: "Zen Antique" },
    { value: "Zen Antique Soft", label: "Zen Antique Soft" },
    { value: "Zen Dots", label: "Zen Dots" },
    { value: "Zen Kaku Gothic Antique", label: "Zen Kaku Gothic Antique" },
    { value: "Zen Kaku Gothic New", label: "Zen Kaku Gothic New" },
    { value: "Zen Kurenaido", label: "Zen Kurenaido" },
    { value: "Zen Loop", label: "Zen Loop" },
    { value: "Zen Maru Gothic", label: "Zen Maru Gothic" },
    { value: "Zen Old Mincho", label: "Zen Old Mincho" },
    { value: "Zen Tokyo Zoo", label: "Zen Tokyo Zoo" },
    { value: "Zeyada", label: "Zeyada" },
    { value: "Zhi Mang Xing", label: "Zhi Mang Xing" },
    { value: "Zilla Slab", label: "Zilla Slab" },
    { value: "Zilla Slab Highlight", label: "Zilla Slab Highlight" },
  ]);

  if (document.getElementById("react-select-3-input")) {
    document.getElementById("react-select-3-input").style.color = "#fff";
  }

  const [activeFont, setActiveFont] = useState(value ? value : "Roboto");

  const changeFont = (value) => {
    setActiveFont(value.value);
  };

  useEffect(() => {
    cbValue({
      key: "fontFamily",
      value: activeFont,
    });
  }, [activeFont]);

  const colourStyles = {
    container: (styles) => ({
      ...styles,
      backgroundColor: "#333 !important",
      color: "#fff !important",
      border: "solid 1px #333",
    }),
    singleValue: (styles) => ({
      ...styles,
      backgroundColor: "#333 !important",
      color: "#fff !important",
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "#333 !important",
      color: "#fff !important",
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? "#333333" : "#333333",
        color: isFocused ? "#fff" : "#FFF !important",
        cursor: isDisabled ? "not-allowed" : "default",
        border: "solid 1px #333",
      };
    },
  };
  return (
    <>
      <Form.Group controlId="name">
        <Form.Label>
          <span style={{ color: "red" }}>*</span>
          {label}
        </Form.Label>
        <Select
          name="fontfamily"
          value={{
            label: value ? value : activeFont,
            value: value ? value : activeFont,
          }}
          options={select2Data}
          onChange={changeFont}
          styles={colourStyles}
          color="#fff"
        />
      </Form.Group>
    </>
  );
};
export default GoogleFontsComp;
