import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import RangeSlider from '../form/Rangeslider';
import labelCSS from '../../helpers/labelCss'
import mediaQuery from '../../helpers/mediaQueryHelper';
import { MEDIALARGE, MEDIAMEDIUM, MEDIAQUERY, MEDIASMALL, SIZEMEDIUM, SIZESMALL } from '../../variable';
const SpaceView = ({ data, ViewCB, widthDevice }) => {

    const [key, setKey] = useState('main')
    const [colData, setColData] = useState(data)
    const [isReady, setIsReady] = useState(false)
    const [masterData, setMasterData] = useState(data)
    const [widthDevice2, setWidthDevice2] = useState(widthDevice)
    const cbValue = (data) => {
        let key = labelCSS(data.key)
        let value = data.value
        let extension = key === "width" ? "%" : "px"
        if (data.key === "textAlign") {
            extension = ""
        }

        if (widthDevice === MEDIALARGE) {
            if (extension === "") {
                setColData(prev => {
                    return {
                        ...prev,
                        [key]: value
                    }
                })
            } else {
                setColData(prev => {
                    return {
                        ...prev,
                        [key]: value + extension
                    }
                })
            }
        }
        if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
            let widthDeviceStr = SIZEMEDIUM
            if (widthDevice === MEDIASMALL) {
                widthDeviceStr = SIZESMALL
            }
            let temp = { ...colData }
            if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
                let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`]
                if (extension !== "") {
                    value = value + extension
                }
                let newObject = {
                    [key]: value
                }
                let newJoin = { ...tempNewObject, ...newObject }
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin
                setColData(temp)

            } else {
                if (extension !== "") {
                    value = value + extension
                }
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
                    [key]: value
                }
                setColData(temp)
            }
        }
    }

    useEffect(() => {
        if (colData) {
            ViewCB(colData)
            setMasterData(colData)
        }
    }, [colData])


    const calculateSetting = () => {
        setIsReady(false)
        let tempState = { ...masterData }
        tempState.marginTop = mediaQuery(tempState, "marginTop", tempState.marginTop, widthDevice)
        setMasterData(tempState)
        setTimeout(() => {
            setIsReady(true)
        }, 500)
    }


    useEffect(() => {
        setIsReady(false)
        setTimeout(() => {
            calculateSetting(widthDevice2)
        }, 500)
    }, [widthDevice2])


    useEffect(() => {
        if (widthDevice !== widthDevice2) {
            setWidthDevice2(widthDevice)
        }
    }, [widthDevice])


    return (
        <div style={{ textAlign: 'left' }}>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="main" title="main">
                    {isReady && <RangeSlider value={masterData.marginTop} label="Margin Top" cbValue={cbValue} maxvalue="1000"></RangeSlider>}
                </Tab>
            </Tabs>
        </div>
    )
}

export default SpaceView
