import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import LoginPage from "./pages/LoginPages";
import NotFoundPage from "./pages/NotFoundPage";
import LogoutPages from "./pages/LogoutPages";
import WebsiteEditorPage from "./pages/Editor/WebsiteEditorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/login",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/editor-web",
    element: <WebsiteEditorPage></WebsiteEditorPage>,
  },
  {
    path: "/logout",
    element: <LogoutPages></LogoutPages>,
  },
  {
    path: "*",
    element: <NotFoundPage></NotFoundPage>
  }
]);

export default router;
