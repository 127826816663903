import { DragHandle } from '../../partials/DragHandle'
const CustomColMenu = ({ id, settingCol, provided }) => {
    return <div className="slide-col-custom">
        
        <div className='slide-col-flex'>
            <div  className='slide-col-flex-content' >
                <DragHandle {...provided.dragHandleProps} />
            </div>
            <div className='slide-col-flex-content' onClick={() => settingCol("edit", id)}>
                <i className='fas fa-pen slide-col-flex-icon'></i>
            </div>
            <div className='slide-col-flex-content' onClick={() => settingCol("remove", id)}>
                <i className='fas fa-trash slide-col-flex-icon'></i>
            </div>
        </div>
    </div>
}

export default CustomColMenu