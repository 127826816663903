import axios from "axios"
// import { removeUserInfo } from "./storageHelper";
const baseURL = process.env.REACT_APP_API_BASE_URL
let headers = {}
const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: baseURL
})

const axiosInstancePublic = axios.create({
    baseURL: baseURL
})


axiosInstance.interceptors.request.use(config => {
    // if (config.url !== "/api/users/login") {
    //     var token = localStorage.getItem('token')
    //     console.log("userInfo",token)
    //     if (token) {
    //         var token = JSON.parse(token)
    //         headers.Authorization = `Bearer ${token}`
    //         config.headers['Authorization'] = `Bearer ${token}`
    //     } else {

    //     }

    // }
    return config;
},

    error => {

        Promise.reject(error)
    });

axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
}, function (error) {
    if (error.response.status === 401) {
        if(!window.location.href.includes("login")){
            window.location.replace('https://app.idinvitebook.com/logout');
        }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
export default axiosInstance

export {axiosInstancePublic}