const imageState = {
    id: "",
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    rotate:'',
    zIndex:'',
    type: 'Image',
    textAlign: 'center',
    link: '',
    image: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg',
    image378: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg',
    image678: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg',
    width: '100',
    height: 'auto',
    borderRadius: 0,
    borderTopLeftRadius:0,
    borderTopRightRadius:0,
    borderBottomLeftRadius:0,
    borderBottomRightRadius:0,
    animation: '',
    position: 'static',
    positionAbsolute: {
        top: "",
        right: "",
        bottom: "",
        left: ""
    }
}
const mapState = {
    iframe: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63129.54739003594!2d115.0845270960223!3d-8.538474478630054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd23a5be9804c43%3A0xb7d0c1d30d7b6159!2sTabanan%2C%20Kec.%20Tabanan%2C%20Kabupaten%20Tabanan%2C%20Bali!5e0!3m2!1sid!2sid!4v1652793663445!5m2!1sid!2sid" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
    type: 'Map',
    animation: '',
}
const colomState = {
    overflow:'hidden',
    backgroundColor: '',
    backgroundColor2:'',
    backgroundImage: '',
    backgroundSize: 'cover',
    backgroundType: 'color',
    parallax: false,
    flexDirection:'row',
    cover: {
        backgroundColor:"#fff",
        rgb:"rgb(255,255,255)",
        opacity:'1'
    },
    cover2: {
        backgroundColor:"#fff",
        rgb:"rgb(255,255,255)",
        opacity:'1'
    },
    foreground:false,
    linear:'solid',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    paddingBottom: "20px",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "20px",
    isTransparent: '',
    borderRadius: 0,
    border: '',
    borderType: '',
    borderColor: '',
    opacity:10,
    content: [],
    position:'static',
    width:'100%',
    widthType:'flex',
    borderTopLeftRadius:0,
    borderTopRightRadius:0,
    borderBottomLeftRadius:0,
    borderBottomRightRadius:0,
    view:'column',
    image_slide: [
        {
            id: "",
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            id: "",
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            id: "",
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
    images378: [
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
    images678: [
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ]
}

const listState = {

    icon: "",
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    type: 'List',
    content: '-pertama -kedua -ketiga',
    width: 'auto',
    height: 'auto',
    fontSize: '14px',
    color: "#333",
    fontFamily: 'roboto',
    animation: '',
}

const musicState = {
    type: 'Music',
    autoplay: true,
    music: '',
}

const iconState = {
    icon: "fas fa-address-card",
    action: '',
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    type: 'Icon',
    textAlign: 'center',
    width: 'auto',
    height: 'auto',
    background: '',
    borderRadius: '16px',
    animation: '',
    fontSize:'23px'
}

const textState = {
    type: 'Teks',
    tags: 'p',
    content: 'Teks disini',
    marginTop: 5,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    textAlign: 'center',
    color: '#000',
    fontSize: "16px",
    is_insidecard: false,
    animation: {
        value:"none",
        delay:1,
        duration:1
    },
    backgroundColor: '',
    isTransparent: '',
    borderRadius: '',
    border: '',
    borderType: '',
    borderColor: '',
    lineHeight: '20px',
    fontFamily: 'roboto',
    fontWeight: 300,
}

const imagesState = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    type: 'ImageSlider',
    textAlign: 'center',
    animation: '',
    image: [
        {
            id: "",
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            id: "",
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            id: "",
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
    images378: [
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
    images678: [
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
    width: 'auto',
    height: 'auto',
    viewtype: 'masonry'
}
const imagesGalleryState = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    type: 'ImageGallery',
    textAlign: 'center',
    animation: '',
    image: [
        {
            id: "",
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            id: "",
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            id: "",
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
    images378: [
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
    images678: [
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
    width: 'auto',
    height: 'auto',
    viewtype: 'masonry'
}
const carouselState = {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    type: 'Carousel',
    textAlign: 'center',
    animation: '',
    image: [
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
    images378: [
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
    images678: [
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
    width: 'auto',
    height: 'auto',
    viewtype: 'masonry'
}
const videoState = {
    type: 'Video',
    youtube_url: 'https://www.youtube.com/watch?v=6yWumRsXwsM&pbjreload=102',
    content: '',
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    textAlign: 'center',
    color: '#333',
    fontSize: '6vw',
    animation: '',
}

const countdownState = {
    type: 'Countdown',
    duedate: Date.now(),
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    textAlign: 'center',
    color: '#333',
    fontSize: '16px',
    animation: '',
}

const buttonState = {
    type: 'Button',
    action: 'popup',
    link: 'link URL',
    content: 'Klik Tombol',
    marginTop: 10,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    textAlign: 'center',
    backgroundColor: '#094aa3',
    color: '#fff',
    borderRadius: 20,
    fontSize: "13px",
    fontWeight: 'bold',
    fontFamily: '',
    icon: "",
    iconPosition: "",
    isTransparent: false,
    animation: '',
}
const bankState = {
    type: 'Bank',
    bank: 'Mandiri',
    account: '0238943274',
    account_name: 'a/n John Doe',
    image: 'https://d1jcw7e5n1q2pt.cloudfront.net/1701655033971-mandr.png',
    marginTop: 10,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    textAlign: 'center',
    backgroundColor: '#fff',
    color: '#333',
    borderRadius: 20,
    border: '',
    borderType: '',
    borderColor: '',
    fontSize: '15',
    fontWeight: 'bold',
    fontFamily: '',
    icon: "",
    iconPosition: "",
    isTransparent: false,
    animation: '',
}

const giftState = {
    type: 'Gift',
    address: '0238943274',
    receiver_name: 'a/n John Doe',
    marginTop: 10,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    textAlign: 'center',
    backgroundColor: '#fff',
    color: '#333',
    borderRadius: 20,
    border: '',
    borderType: '',
    borderColor: '',
    fontSize: '15',
    fontWeight: 'bold',
    fontFamily: '',
    icon: "",
    iconPosition: "",
    isTransparent: false,
    animation: '',
}

const deviderState = {
    type: 'Devider',
    shape: 'waves',
    position: 'top',
    color: '#333',
    flip: 'false',
    invert: 'false',
    width: 100,
    height: 106,
}
const navbarState = {
    type: "Navbar",
    logo: 'https://d3vqnu8iq57j2l.cloudfront.net/1678596375220-mandiri.png',
    marginTop: 10,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 10,
    paddingRight: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    textAlign: 'center',
    backgroundColor: '#fff',
    color: '#333',
    borderRadius: 20,
    border: '',
    borderType: '',
    borderColor: '',
    fontSize: '15',
    fontWeight: 'bold',
    fontFamily: '',
    icon: "",
    iconPosition: "",
    isTransparent: false,
    animation: '',
    menu: [
        {
            name: "home",
            link: "#"
        },
        {
            name: "profile",
            link: "#"
        },
        {
            name: "contact",
            link: "#"
        }
    ]
}

const spaceState = {
    marginTop: 10,
    marginBottom: 0,
    type: 'Space',
}

const lineState = {
    type: 'Line',
    tags: 'p',
    content: 'Teks disini',
    marginTop: 5,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    textAlign: 'center',
    color: '#000',
    fontSize: "16px",
    is_insidecard: false,
    animation: '',
    backgroundColor: '',
    isTransparent: '',
    borderRadius: '',
    border: '',
    borderType: '',
    borderColor: '',
    lineHeight: '20px',
}

const timelineState = {
    type: 'Timeline',
    content: [
        {
            title:"Judul disini",
            desc:"Deskripsi disini",
            year:"Tahun disini",
            editMode:true
        }
    ],
    marginTop: 5,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    textAlign: 'left',
    color: '#000',
    backgroundColor: '',

}
const formState = {
    fontFamily: 'Roboto',
    fontSize: "14px",
    type: "Form",
    fontWeight: "bold",
    color: "#333",
    borderRadius: 20,
    backgroundColor: "#fff",
    title: "Isi Nama dan No Whatsapp",
    buttonTeks: "Kirim",
    nameLabel: "Nama",
    namePlaceholder: "Masukkan Nama",
    pesanLabel: "Pesan",
    pesanPlaceholder: "Masukkan Pesan",
    questionLabel: "Kamu akan hadir?",
    hadirLabel: "Hadir",
    tidakHadirLabel: "Tidak Hadir",
    raguLabel: "Ragu - ragu",
    buttonColor: "#fff",
    buttonBackground: "blue",
    animation: '',
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 10,
    paddingRight: 0,
    paddingBottom: 10,
    paddingLeft: 0,
}
const codeState = {
    type: 'Code',
    html: '<div><p>Hello world!!!!</p></div>',
}

const rowState = {
    overflow:'hidden',
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    paddingTop: 3,
    paddingRight: 0,
    paddingBottom: 3,
    paddingLeft: 0,
    borderRadius: 0,
    width: '100',
    flexDirection:'row',
    linear:'solid',
    height: 'auto',
    backgroundColor: "#fff",
    backgroundColor2:'#fff',
    backgroundImage: '',
    backgroundSize: 'cover',
    backgroundType: 'color',
    parallax: false,
    cover: '',
    cover2: '',
    col: [],
    isTransparent: false,
    islock: false,
    effect: '',
    opacity:8,
    mobileView:'column',
    mediaQueri768:{},
    mediaQueri360:{},
    image_slide: [
        {
            id: "",
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            id: "",
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            id: "",
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
    images378: [
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
    images678: [
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        },
        {
            img: 'https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg'
        }
    ],
}
export { deviderState, giftState, colomState, listState, mapState, imageState, imagesGalleryState,imagesState, musicState, iconState, textState, videoState, countdownState, buttonState, formState, codeState, rowState, bankState, spaceState, navbarState, carouselState, lineState, timelineState } 