import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import Colors from '../../Colors'

const ComponentView = ({ viewActive, list, drag, mobileMoveAction, addItemMobile }) => {
    return (
        <>
            <ul style={{ breakInside: 'avoid', padding: 0, margin: 0, listStyle: 'none', padding: 0, margin: 0, textAlign: 'center', height: '100vh',borderRadius:'15px',  overflow: 'scroll' }}>
                {list && list.map((menu, index) => {
                    var key = index + 1
                    return <li
                        index={key}
                        draggable={true}
                        onDragStart={($e) => {
                            drag($e, menu.menu)
                        }}
                        onTouchStart={($e) => {
                            document.getElementById('canvas').style.pointerEvents = "auto"
                            drag($e, menu.menu)
                           
                        }}
                        onTouchMove={($e) => {
                            const x = $e.changedTouches[0].clientX
                            if(parseInt(x)>250){
                                mobileMoveAction()
                            }
                        }}
                        onTouchEnd={($e) => {
                            $e.preventDefault();
                            const x = $e.changedTouches[0].clientX
                            const y = $e.changedTouches[0].clientY
                            let elem = document.elementFromPoint(x, y);
                            let idElement = elem.getAttribute("id");
                            addItemMobile(idElement)
                            
                        }}
                        style={{ color: '#fff', cursor: 'pointer', border: `solid 5px ${Colors.dark2}`, height: '80px', overflow: 'hidden', margin: '0px', padding: '0px', width: '120px', float: 'left' }}>
                        <div className="diableSelection"  >
                            <OverlayTrigger
                                key="left"
                                placement="left"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip id={`tooltip-left`}>
                                        {menu.description}
                                    </Tooltip>
                                }
                            >
                                <div style={{ marginTop: '10px' }}>
                                    <i className={menu.iconClass} ></i>
                                </div>
                            </OverlayTrigger>
                        </div>
                        <p style={{ color: '#fff', marginTop: '6px', fontSize: '12px' }}>{menu.description}</p>

                    </li>

                })}
            </ul>
        </>
    )
}

export default ComponentView
