import React,{useState, useEffect} from 'react'
import { LARGEDEVICE } from '../variable'
import { checkDevice } from '../helpers/windowHelper'

const ScrollMenuY = ({id, scrollActionCB, page,children,iHeight}) => {
    const [innerHeigh,setInnerHeight] = useState(iHeight===undefined?90:iHeight)
    const [device, setDevice] = useState('')
    const [hasScroll,setHasScroll] = useState('')
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const [loadMore,setLoadMore] = useState(false)
    const [currentPage,setCurrentPage] = useState(page)
    useEffect(() => {
        setDevice(checkDevice())
    },[])


    const scrolling = () => {
        if(document.getElementById(id)){
            let scrollTop = document.getElementById(id).scrollTop
            let scrollSize = document.getElementById(id).scrollHeight
        
            if(scrollTop > scrollSize-1000){
     
                setLoadMore(true)
            }
         
        }
    }

    useEffect(() => {
        if(loadMore){
            scrollActionCB(page+1)
            setLoadMore(false)
        }
    },[loadMore])


    useEffect(() => {
       
        if(currentPage===page+1){
            setLoadMore(true)
        }
    },[currentPage])

    return <div id={id} style={{ textAlign: 'left', color: '#fff', overflow: 'hidden', textAlign: 'center', overflowY: device === LARGEDEVICE ? hasScroll : 'scroll', height: windowHeight - innerHeigh }}
        onMouseEnter={(() => setHasScroll("scroll"))}
        onMouseLeave={(() => setHasScroll("hidden"))}
        onScroll={() => scrolling()}
    >
       {children}
    </div>
}

export default ScrollMenuY