import { upload } from "../services/uploadServices";
import compressHelper from "./compressHelper"


const checkImageWidth = (imageToCheck) => {
    var img = document.createElement("img");
    img.src = imageToCheck
    img.onload = function () {
        img.style.height = "200px";
        img.style.visibility = 'hidden';
        document.body.appendChild(img);
    }
    return img.clientWidth
}


const checkFileSize = (file,customSize) => {
    let limitInMB = 5
    if(customSize){
        limitInMB=customSize
    }
    let sizeInBytes = file.size
    let sizeInMB =  sizeInBytes / Math.pow(1024,2)
    if(customSize){}
    if(sizeInMB>limitInMB){
        return false
    } else {
        return true
    }
}

const uploadHelper = async (event, totalFile,customSize) => {
    var file
    if (totalFile === undefined) {
        totalFile = 1
    }
    var originalImgWidth = ""
    var isgif = false

    var widthimage = [1000, 678, 378]
    let result = []
    let widthImage378 = ""
    let widthImage678 = ""
    let widthImage = ""
    for (let b = 0; b < totalFile; b++) {
        file = event.target.files[b]
        for(let c=0;c<totalFile;c++){
            let filecek = event.target.files[c]
            let validSize = checkFileSize(filecek,customSize)
            if(validSize===false){
                alert("ukuran gambar maximal 5mb")
                return false
            }
        }
        if (file.name.includes("gif")) {
            isgif = true
        }
        var filename = file.name.split(".")
        filename = filename[0]
        for (let a = 0; a < widthimage.length; a++) {
            const data = new FormData()
            var compress
            if (a === 0) {
                compress = await compressHelper(file)
            } else {
                compress = await compressHelper(file, widthimage[a])
            }
            if (a > 0) {
                var filenameAfter = filename + "-" + widthimage[a]
            } else {
                var filenameAfter = filename
            }
            var convertblobToFIle = new File([compress], filenameAfter, { type: file.type, lastModified: Date.now() })
            if (isgif) {
                data.append('file', file)
            } else {
                data.append('file', convertblobToFIle)
            }
            await upload(data).then(res => {
                if (a === 0) {
                    widthImage = res.data.files[0]
                    originalImgWidth = checkImageWidth(widthImage)
                }
                if (a === 1) {
                    widthImage678 = res.data.files[0]
                }
                if (a === 2) {
                    widthImage378 = res.data.files[0]
                }
            }).catch(err => {
              
            })
        }
        result.push(
            {
                widthImage,
                widthImage678,
                widthImage378
            }
        )
    }
    return result

}

export default uploadHelper