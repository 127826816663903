import React from 'react'

const TabView = ({menus,active,actionTab}) => {
    return <>
        <div style={{ display: 'flex', textAlign: 'center', height: '35px', fontSize: '14px', background: '#333333', border: 'solid 1px #000' }}>
            {menus.map(menu => {
                return  <div style={{ width: '50%', cursor: 'pointer', background: active === menu?.toLowerCase() ? '#14B2D0' : '#333333', border: 'solid 1px #000', paddingTop: '3px' }} onClick={() => {
                    actionTab(menu?.toLowerCase())
                }}>{menu}</div>
            })}
        </div>
    </>
}

export default TabView