import axiosInstance from "../helpers/axiosHelper"

export const userLoginServices = (email, password) => {
    return axiosInstance.post(`/api/v1/auth/login`, { email, password })
}

export const userLogoutServices = () => {
    return axiosInstance.post(`/api/v1/auth/logout`)
}


