import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ColorPicker, useColor } from "react-color-palette";
import tinycolor from "tinycolor2";
import { TRANSCOLOR } from "../../variable";
const ColorComp = ({ label, colorCB, primaryColor, showNoColor, noColor }) => {
  console.log("primaryColor", primaryColor);
  let hasColor = true;
  if (primaryColor === TRANSCOLOR) {
    hasColor = false;
  }
  const delay = 1;
  let timer1;
  const [isTransparantClicked, setIsTransparantClicked] = useState(false);
  const [color, setColor] = useColor("hex", primaryColor ? primaryColor : "");
  const [defaultColor, setDefaultColor] = useColor(primaryColor);
  const [enableNoColor, setEnableNoColor] = useState(
    showNoColor ? showNoColor : false
  );
  const [enableColor, setEnableColor] = useState(false);
  const [isSupportDropper, setIsSupportDropper] = useState(
    !window.EyeDropper ? false : true
  );
  const [isReady, setIsReady] = useState(false);
  const showColor = (val) => {
    setEnableColor(val);
  };

  const changeColor = (value) => {
    setIsTransparantClicked(false);
    setColor(value);
    setDefaultColor(value.hex);
  };

  useEffect(() => {
    let desc = setTimeout(() => {
      if (!isTransparantClicked) {
        if (isReady) {
          colorCB(color);
        }
      }
    }, delay * 1000);
    return () => {
      clearTimeout(desc);
    };
  }, [defaultColor]);

  const changeNoColor = () => {
    setIsTransparantClicked(true);
    noColor(true);
  };

  const openDropper = () => {
    if (!window.EyeDropper) {
      return;
    } else {
      const eyeDropper = new window.EyeDropper();
      eyeDropper
        .open()
        .then((result) => {
          let hexstring = tinycolor(result.sRGBHex).toHexString();
          let rgb = result.sRGBHex.split(",");
          let r = rgb[0].replace("rgba(", "").replace(/ /g, "");
          let g = rgb[1].replace(/ /g, "");
          let b = rgb[2].replace(/ /g, "");
          let value = {
            hex: hexstring,
            rgb: {
              r: r,
              g: g,
              b: b,
            },
            hsv: {
              h: 0,
              s: 76.66666666666667,
              v: 82.63157894736842,
            },
          };
          changeColor(value);
          setEnableColor(false);
          setIsTransparantClicked(false);
        })
        .catch((e) => {
          // resultElement.textContent = e;
        });
    }
  };
  return (
    <div>
      <div style={{ position: "relative" }}>
        <Form>
          <Form.Group>
            <Form.Label><span style={{fontSize:'13px'}}>{label}</span></Form.Label>
            <Row>
              <Col>
                {hasColor ? (
                  <>
                    {defaultColor && (
                      <div
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor: primaryColor,
                          float: "left",
                          marginRight: "15px",
                          borderRadius: 100,
                          marginTop: 5,
                          border: "solid 1px #fff",
                        }}
                      ></div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                <Button
                  variant="primary"
                  style={{
                    float: "left",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                  onClick={() => {
                    setIsReady(true);
                    setTimeout(() => {
                      showColor(true);
                    }, 100);
                  }}
                >
                  {" "}
                  <i
                    className="fas fa-pen pt-2 pr-1"
                    style={{ fontSize: 10, color: "#fff" }}
                  ></i>
                  Ubah
                </Button>
                {enableColor && (
                  <div style={{ zIndex: "999999!" }}>
                    <div style={{ position: "absolute", zIndex: 999 }}>
                      <ColorPicker
                        width={180}
                        height={170}
                        color={color}
                        onChange={changeColor}
                        hideHSV
                        hideRGB
                        light
                      />
                      <div
                        style={{
                          backgroundColor: "#fff",
                          width: "25px",
                          height: "25px",
                          right: -25,
                          top: 0,
                          position: "absolute",
                          borderRadius: "100px",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => showColor(false)}
                      >
                        <i
                          className="fas fa-times pt-2"
                          style={{ fontSize: 10, color: "#333" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                )}
                {enableNoColor && (
                  <Button
                    variant="primary"
                    style={{
                      paddingTop: "2px",
                      paddingBottom: "2px",
                      paddingLeft: "10px",
                      paddingRight: "7px",
                      marginLeft: "10px",
                      borderRadius: 0,
                      background: "none",
                      border: "red 1px dashed",
                    }}
                    onClick={() => changeNoColor()}
                  >
                    {" "}
                    <i
                      className="fas fa-tint-slash pt-2 pr-1"
                      style={{ fontSize: 14, color: "#fff" }}
                    ></i>
                  </Button>
                )}
                {isSupportDropper && (
                  <div onClick={() => openDropper()} style={{ float: "left" }}>
                    <i
                      className="fas fa-eye-dropper pt-2"
                      style={{
                        fontSize: 14,
                        color: "#fff",
                        marginLeft: "18px",
                        marginTop: "2px",
                        cursor: "pointer",
                        marginRight: "7px",
                      }}
                    ></i>
                  </div>
                )}
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default ColorComp;
