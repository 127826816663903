import React from 'react'
import { Form } from "react-bootstrap"
const InputSelectComp = ({
    label,
    placeholder,
    value,
    onChangeCB,
    isRequired,
    options,
    isMultiValue
}) => {
 
    let isMultiMode = isMultiValue !== undefined ? isMultiValue : false
    let isShowRed = isRequired !== undefined?false:isRequired
    return <>
        <Form.Group controlId="name">
            <Form.Label>
                {isShowRed && <span style={{ color: 'red' }}></span>}
               <span style={{fontSize:'13px'}}>{label}</span></Form.Label>
            {!isMultiMode && <Form.Control
                as="select"
                placeholder={placeholder}
                onChange={($e) => onChangeCB($e.target.value)}
                className='form-control-dark'>
                {options && options.map(option => {
                    return <option value={option} selected={value === option}>{option}</option>
                })}
            </Form.Control>
            }
            {isMultiMode && <Form.Control
                as="select"
                placeholder={placeholder}
                onChange={($e) => onChangeCB($e.target.value)}
                className='form-control-dark'>
                {options && options.map(option => {
                    return <option value={option.value} selected={value === option.value}>{option.label}</option>
                })}
            </Form.Control>}
        </Form.Group>
    </>
}

export default InputSelectComp