import imageCompression from 'browser-image-compression';
async function compressHelper(file,width) {
    const imageFile = file;
  
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: width?width:1800,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
   
      return compressedFile
    } catch (error) {
     
    }
}
export default compressHelper