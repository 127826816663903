import React, { useState, useEffect } from 'react'
import { Form, Row, Col,Tabs, Tab } from 'react-bootstrap'
const MapView = ({ data, ViewCB }) => {

    const [key, setKey] = useState('main')
    const [colData, setColData] = useState(data)
    
    useEffect(() => {
        if (colData && data) {
            ViewCB(colData)
        }
    }, [colData])


    const changeValue = (key,value) => {
        if(!value.includes('iframe')){
            alert("format map salah silahkan tonton tutorial")
        } else {
            setColData(prev => {
                return {
                    ...prev,
                    [key]:value
                }
             })
        }
    }

    return (
        <div style={{ textAlign: 'left' }}>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="main" title="main">
                    <Form.Group controlId="name">
                        <Form.Label><span style={{ color: 'red' }}>*</span>Iframe Google Map</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control as='textarea' rows={4} placeholder='' value={colData.iframe} onChange={($e) => changeValue('iframe',$e.target.value)}  className='form-control-dark'></Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>
                    <a href="https://www.youtube.com/watch?v=8S8nKxUmaT0" target='_blank' style={{cursor:'pointer',color:'#fff',fontWeight:'bold'}}>Tonton tutorial</a>
                </Tab>
            </Tabs>
        </div>
    )
}

export default MapView
