import React,{useState,useEffect} from 'react'
const ColView = ({colnumber , ColCB}) => {
    const [content,setContent] = useState()
    const [clickedRow,setClickedRow] = useState(colnumber)
    const [showRow,setShowRow] = useState(false)
  
    const changeRow = (value) => {
        ColCB(value)
    }
 
    const colSwitcher = (colnumber) => {
        var contentTemp=""
 
        switch(colnumber){
            case "1":
                contentTemp = <>
                     {/* 1 kolom sama lebar */}
                      <div style={{cursor:'pointer',marginTop:'5px'}} onClick={() => {changeRow("col-md-12")}}>
                                <div style={{width:'100%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}} >
                                    Kolom 1
                                </div>
                              
                            </div>
                   

                        {/* 1 kolom offset */}
                        
                    
                </>
                break
            case "2":
                    contentTemp = <>
                         {/* 2 kolom sama lebar */}
                         <div style={{cursor:'pointer',marginTop:'5px'}} onClick={() => {changeRow("col-md-6 col-md-6")}}>
                            <div style={{width:'50%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                Kolom 1
                            </div>
                            <div style={{width:'50%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                Kolom 2
                            </div>
                        </div>

                        {/* 2 kolom kiri lebih lebar */}
                        {/* <div style={{cursor:'pointer',marginTop:'5px'}} onClick={() => {changeRow("col-md-8 col-md-4")}}>
                        <div style={{width:'70%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                            Kolom 1
                        </div>
                        <div style={{width:'30%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                            Kolom 2
                        </div>
                        </div> */}

                          {/* 2 kolom kanan lebih lebar */}
                          {/* <div style={{cursor:'pointer',marginTop:'5px'}} onClick={() => {changeRow("col-md-4 col-md-8")}}>
                          <div style={{width:'30%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                            Kolom 1
                        </div>
                        <div style={{width:'70%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                            Kolom 2
                        </div>   </div> */}
                    </>
                    break
            case "3":
                contentTemp = <>
                         {/* 3 kolom sama lebar */}
                         <div style={{cursor:'pointer',marginTop:'5px'}} onClick={() => {changeRow("col-md-4 col-md-4 col-md-4")}}>
                                <div style={{width:'33.33%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 1
                                </div>
                                <div style={{width:'33.33%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 2
                                </div>
                                <div style={{width:'33.33%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 3
                                </div>
                            </div>

                      {/* 3 kolom kiri sempit */}
                      {/* <div style={{cursor:'pointer',marginTop:'5px'}} onClick={() => {changeRow("col-md-2 col-md-5 col-md-5")}}>
                                <div style={{width:'20%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 1
                                </div>
                                <div style={{width:'40%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 2
                                </div>
                                <div style={{width:'40%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 3
                                </div>
                            </div> */}

                             {/* 3 kolom tengah sempit */}
                      {/* <div style={{cursor:'pointer',marginTop:'5px'}} onClick={() => {changeRow("col-md-5 col-md-2 col-md-5")}}>
                                <div style={{width:'40%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 1
                                </div>
                                <div style={{width:'20%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 2
                                </div>
                                <div style={{width:'40%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 3
                                </div>
                            </div> */}

                                {/* 3 kolom kanan sempit */}
                      {/* <div style={{cursor:'pointer',marginTop:'5px'}} onClick={() => {changeRow("col-md-5 col-md-5 col-md-2")}}>
                                <div style={{width:'40%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 1
                                </div>
                                <div style={{width:'40%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 2
                                </div>
                                <div style={{width:'20%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 3
                                </div>
                            </div> */}
                   
                </>
                break;
                case "4":
                    contentTemp = <>
                         {/* 4 kolom sama lebar */}
                         <div style={{cursor:'pointer',marginTop:'5px'}} onClick={() => {changeRow("col-md-3 col-md-3 col-md-3 col-md-3")}}>
                            <div style={{width:'25%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                Kolom 1
                            </div>
                            <div style={{width:'25%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                Kolom 2
                            </div>
                            <div style={{width:'25%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                Kolom 3
                            </div>
                            <div style={{width:'25%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                Kolom 4
                            </div>
                        </div>
                    </>
                    break
                    case "5":
                        contentTemp = <>
                             {/* 4 kolom sama lebar */}
                             <div style={{cursor:'pointer',marginTop:'5px',display:'flex'}} onClick={() => {changeRow("col-md-3 col-md-3 col-md-3 col-md-3 col-md-3")}}>
                                <div style={{flex:1,border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 1
                                </div>
                                <div style={{flex:1,border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 2
                                </div>
                                <div style={{flex:1,border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 3
                                </div>
                                <div style={{flex:1,border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 4
                                </div>
                                <div style={{flex:1,border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}}>
                                    Kolom 5
                                </div>
                            </div>
                        </>
                        break
        }
       setContent(contentTemp)
       
    
    }

    useEffect(() => {
        colSwitcher(clickedRow)
     
    }, [clickedRow])

    useEffect(() => {
       setClickedRow(colnumber)
        if(colnumber !==1){
            setShowRow(true)
        }
    }, [colnumber])

    
    return (
        <div style={{textAlign:'left'}}>
           {clickedRow && content}

           {!showRow && <>
            <div style={{cursor:'pointer',marginTop:'5px'}} onClick={() => {changeRow("col-md-12")}}>
                                <div style={{width:'100%',border:'dashed 1px red',height:'30px;',backgroundColor:"#ccc",float:'left',textAlign:'center',padding:'10px'}} >
                                    Kolom 1
                                </div>
                              
                            </div>
                        {/* 1 kolom offset */}
                         
           </>}
        </div>
    )
}

export default ColView
