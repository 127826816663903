import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Tabs, Tab } from 'react-bootstrap'
import RangeSlider from '../form/Rangeslider';
import labelCSS from '../../helpers/labelCss'
import { useColor } from "react-color-palette";
import TextAlign from '../form/TextAlign';
import ColorComp from '../form/ColorComp';
import FontIconComp from './FontIconComp';
import GoogleFontsComp from '../form/GoogleFontsComp';
import AnimationComp from '../form/AnimationComp';
import mediaQuery,{ clearBorderRadius, isAdvancedRadius } from '../../helpers/mediaQueryHelper';
import { MEDIALARGE, MEDIAMEDIUM, MEDIAQUERY, MEDIASMALL, SIZEMEDIUM, SIZESMALL, TRANSCOLOR } from '../../variable';
import BorderRadiusComp from '../form/BorderRadiusComp';
import ButtonSpaceComp from '../ButtonSpaceComp';
const ButtonView = ({ data, ViewCB,fontCB, widthDevice }) => {
    const [isBorderRadiusAdvanced, setIsBorderRadiusAdvanced] = useState(isAdvancedRadius(data))
    const delay = 1;
    let desc
    const [key, setKey] = useState('main')
    const [color, setColor] = useColor("rgb", "98, 142, 48");
    const [background, setBackground] = useColor("rgb", "98, 142, 48");
    const [isLink, setIsLink] = useState(true)
    const [colData, setColData] = useState(data)
    const [isReady, setIsReady] = useState(false)
    const [masterData, setMasterData] = useState(data)
    const [widthDevice2, setWidthDevice2] = useState(widthDevice)
    const cbAnimation = (value) => {
        setColData(prev => {
            return {
                ...prev,
                animation: value
            }
        })
    }
    
    const boderRadiusAdvancedMode = (val) => {
        setIsBorderRadiusAdvanced(val)
        let resetRadius = clearBorderRadius(masterData)
        setColData(resetRadius)
      
    }

    const cbValue = (data,isGlobal) => {
        let key = labelCSS(data.key)
        let value = data.value
        let extension = key === "width" ? "%" : "px"
        if (key === "textAlign" || key === "fontFamily") {
            extension = ""
            if (key === "fontFamily") {
                fontCB(value)
            }
        }
        if (widthDevice === MEDIALARGE || isGlobal) {
            if (extension === "") {
                setColData(prev => {
                    return {
                        ...prev,
                        [key]: value
                    }
                })
            } else {
                setColData(prev => {
                    return {
                        ...prev,
                        [key]: value + extension
                    }
                })
            }
        }
        if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
            let widthDeviceStr = SIZEMEDIUM
            if(widthDevice===MEDIASMALL){
                widthDeviceStr = SIZESMALL
            }
            let temp = { ...colData }
            if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
                let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`]
                if (extension !== "") {
                    value = value + extension
                }
                let newObject = {
                    [key]: value
                }
                let newJoin = { ...tempNewObject, ...newObject }
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin
                setColData(temp)

            } else {
                if (extension !== "") {
                    value = value + extension
                }
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
                    [key]: value
                }
                setColData(temp)
            }
        }



    }
    const setContent = (value) => {

        setColData(prev => {
            return {
                ...prev,
                content: value
            }
        })

    }


    const setAction = (value) => {
        setColData(prev => {
            return {
                ...prev,
                action: value,
                link: value === "popup" ? "" : colData.link
            }
        })
     
        if (value === "link" || value === "menu") {
            setIsLink(true)
        } else {
            setIsLink(false)
        }

    }


    const changeLink = (value) => {
        setColData(prev => {
            return {
                ...prev,
                link: value
            }
        })

    }

    const setFontWeight = (value) => {

        setColData(prev => {
            return {
                ...prev,
                fontWeight: value
            }
        })
    }

    const changeColor = (value) => {

        var color
        if (value === "none") {
            color = "none !important"
        } else {
            color = value.hex
        }
        setColor(color)
        setColData(prev => {
            return {
                ...prev,
                color: color
            }
        })

    }

    const calculateSetting = () => {
        setIsReady(false)
        let tempState = { ...masterData }
        tempState.marginTop = mediaQuery(tempState, "marginTop", tempState.marginTop, widthDevice)
        tempState.marginLeft = mediaQuery(tempState, "marginLeft", tempState.marginLeft, widthDevice)
        tempState.marginRight = mediaQuery(tempState, "marginRight", tempState.marginRight, widthDevice)
        tempState.marginBottom = mediaQuery(tempState, "marginBottom", tempState.marginBottom, widthDevice)
        tempState.paddingTop = mediaQuery(tempState, "paddingTop", tempState.paddingTop, widthDevice)
        tempState.paddingRight = mediaQuery(tempState, "paddingRight", tempState.paddingRight, widthDevice)
        tempState.paddingBottom = mediaQuery(tempState, "paddingBottom", tempState.paddingBottom, widthDevice)
        tempState.paddingLeft = mediaQuery(tempState, "paddingLeft", tempState.paddingLeft, widthDevice)
        if (isBorderRadiusAdvanced) {
            tempState.borderRadius = 0
            tempState.borderTopLeftRadius = mediaQuery(tempState, "borderTopLeftRadius", tempState.borderTopLeftRadius, widthDevice)
            tempState.borderTopRightRadius = mediaQuery(tempState, "borderTopRightRadius", tempState.borderTopRightRadius, widthDevice)
            tempState.borderBottomLeftRadius = mediaQuery(tempState, "borderBottomLeftRadius", tempState.borderBottomLeftRadius, widthDevice)
            tempState.borderBottomRightRadius = mediaQuery(tempState, "borderBottomRightRadius", tempState.borderBottomRightRadius, widthDevice)
        } else {
            tempState.borderRadius = mediaQuery(tempState, "borderRadius", tempState.borderRadius, widthDevice)
            tempState.borderTopLeftRadius = 0
            tempState.borderTopRightRadius = 0
            tempState.borderBottomLeftRadius = 0
            tempState.borderBottomRightRadius = 0
        }
        tempState.backgroundColor = mediaQuery(tempState, "backgroundColor", tempState.backgroundColor, widthDevice)
        tempState.color = mediaQuery(tempState, "color", tempState.color, widthDevice)
        tempState.fontWeight = mediaQuery(tempState, "fontWeight", tempState.fontWeight, widthDevice)
        tempState.fontSize = mediaQuery(tempState, "fontSize", tempState.fontSize, widthDevice)
        tempState.textAlign = mediaQuery(tempState, "textAlign", tempState.textAlign, widthDevice)
        setMasterData(tempState)
        setTimeout(() => {
            setIsReady(true)
        }, 500)
    }


    useEffect(() => {
        setIsReady(false)
        setTimeout(() => {
            calculateSetting(widthDevice2)
        }, 500)
    }, [widthDevice2])


    useEffect(() => {
        if (widthDevice !== widthDevice2) {
            setWidthDevice2(widthDevice)
        }
    }, [widthDevice])



    const noColor = (value) => {
        setColData(prev => {
            return {
                ...prev,
                backgroundColor: TRANSCOLOR
            }
        })
    }
    const changeBackgroundColor = (value) => {

        setBackground(value)
        setColData(prev => {
            return {
                ...prev,
                isTransparent: false,
                backgroundColor: value.hex
            }
        })




    }



    const changeIcon = (value) => {
        if (value !== undefined) {
            setColData(prev => {
                return {
                    ...prev,
                    iconPosition: colData.iconPosition === "" ? "left" : colData.iconPosition,
                    icon: value
                }
            })
        }
    }

    const changeIconPosition = (value) => {
        if (value !== "none") {
            setColData(prev => {
                return {
                    ...prev,
                    icon: value === "right" ? "fas fa-angle-right" : "fas fa-angle-left"
                }
            })
        }
        setColData(prev => {
            return {
                ...prev,
                iconPosition: value
            }
        })
    }



    useEffect(() => {
        let desc = setTimeout(() => {
            if (colData) {
                ViewCB(colData)
                setMasterData(colData)
            }
        }, delay * 1000);

        // this will clear Timeout
        // when component unmount like in willComponentUnmount
        // and show will not change to true
        return () => {
            clearTimeout(desc);
        };
    },
        // useEffect will run only one time with empty []
        // if you pass a value to array,
        // like this - [data]
        // than clearTimeout will run every time
        // this value changes (useEffect re-run)
        [colData]);



    useEffect(() => {
        if (colData.action === "link" || colData.action === "menu") {
            setIsLink(true)
        } else {
            setIsLink(false)
        }
    }, [colData])

    return (
        <div style={{ textAlign: 'left' }}>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="main" title="main">
                    {
                        isReady && <>
                            <Form.Group controlId="name">
                                <Form.Label><span style={{ color: 'red' }}>*</span>Teks</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Control type='text' placeholder='' value={colData.content} onChange={($e) => setContent($e.target.value)} className='form-control-dark'></Form.Control>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <TextAlign value={masterData.textAlign} label="Text Align" cbValue={cbValue}></TextAlign>
                            <RangeSlider value={masterData.fontSize} label="Font Size" cbValue={cbValue} maxvalue="100"></RangeSlider>
                            <Form.Group controlId="name">
                                <Form.Label><span style={{ color: 'red' }}>*</span>Action</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Control as="select" onChange={($e) => setAction($e.target.value)} className='form-control-dark'>
                                            {/* <option value="popup" selected={colData.action === "popup"}>Pop Up</option>
                                    <option value="ctwa" selected={colData.action === "ctwa"}>CTWA</option> */}
                                            <option value="open lock" selected={colData.action === "open lock"}>Open Lock</option>
                                            <option value="link" selected={colData.action === "link"}>Link</option>
                                            <option value="menu" selected={colData.action === "menu"}>Menu</option>
                                        </Form.Control>
                                    </Col>
                                </Row>
                            </Form.Group>
                            {isLink && <Form.Group controlId="name">
                                <Form.Label><span style={{ color: 'red' }}>*</span>Link</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Control type='text' placeholder='' value={colData.link} onChange={($e) => changeLink($e.target.value)} className='form-control-dark'></Form.Control>
                                    </Col>
                                </Row>
                            </Form.Group>
                            }
                            <GoogleFontsComp value={masterData.fontFamily} label="Font Family" cbValue={(data) => {
                                cbValue(data,true)
                            }}></GoogleFontsComp>

                            <Form.Group controlId="name">
                                <Form.Label><span style={{ color: 'red' }}>*</span>Font Weight</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Control as="select" onChange={($e) => setFontWeight($e.target.value)} className='form-control-dark'>
                                            <option value="regular" selected={masterData.fontWeight === "regular"}>Regular</option>
                                            <option value="bold" selected={masterData.fontWeight === "bold"}>Bold</option>

                                        </Form.Control>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <ColorComp label="Color" colorCB={changeColor} primaryColor={masterData.color}></ColorComp>
                            {/* <Form.Label><span style={{ color: 'red' }}>*</span>Background</Form.Label> */}
                            {/* <ColorPicker width={250} height={228} color={background} onChange={changeBackgroundColor} hideHSV hideRGB  light /> */}
                            <ColorComp label="Background Color" colorCB={changeBackgroundColor} primaryColor={masterData.backgroundColor} showNoColor={true} noColor={noColor}></ColorComp>
                            <Form.Group controlId="name">
                                <Form.Label><span style={{ color: 'red' }}>*</span>Icon Position</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Control as="select" onChange={($e) => changeIconPosition($e.target.value)} className='form-control-dark'>
                                            <option value="none" selected={masterData.iconPosition === "none"}>None</option>
                                            <option value="left" selected={masterData.iconPosition === "left"}>Left</option>
                                            <option value="right" selected={masterData.iconPosition === "right"}>Right</option>

                                        </Form.Control>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <FontIconComp iconclassName={colData.icon} iconClassCB={changeIcon}></FontIconComp>
                        </>
                    }
                    <ButtonSpaceComp></ButtonSpaceComp>
                </Tab>
                <Tab eventKey="setting" title="setting">
                    {isReady && <>
                        <BorderRadiusComp value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100" advancedValue={
                            {
                                borderTopLeftRadius: masterData.borderTopLeftRadius,
                                borderTopRightRadius: masterData.borderTopRightRadius,
                                borderBottomLeftRadius: masterData.borderBottomLeftRadius,
                                borderBottomRightRadius: masterData.borderBottomRightRadius
                            }
                        } isAdvancedMode={isBorderRadiusAdvanced} adVancedModeCB={boderRadiusAdvancedMode}></BorderRadiusComp>
                        {/* <RangeSlider value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100"></RangeSlider> */}
                        <RangeSlider value={masterData.marginTop} label="Margin Top" cbValue={cbValue} maxvalue="100"> </RangeSlider>
                        <RangeSlider value={masterData.marginRight} label="Margin Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginBottom} label="Margin Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginLeft} label="Margin Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingTop} label="Padding Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingRight} label="Padding Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingBottom} label="Padding Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingLeft} label="Padding Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    </>}
                    {/* <Form.Label><span style={{ color: 'red' }}>*</span>Color</Form.Label> */}
                    {/* <ColorPicker width={250} height={228} color={color} onChange={changeColor} hideHSV hideRGB  light /> */}
                    <ButtonSpaceComp></ButtonSpaceComp>
                </Tab>
                <Tab eventKey="animation" title="animasi" >
                    <AnimationComp value={colData.animation} cbValue={cbAnimation}></AnimationComp>
                </Tab>
            </Tabs>







        </div>
    )
}

export default ButtonView
