import React, { useState, useEffect } from 'react'
import RangeSlider from '../form/Rangeslider';
import labelCSS from '../../helpers/labelCss'
import { Form, Tabs, Tab } from 'react-bootstrap'
import mediaQuery from '../../helpers/mediaQueryHelper';
import { MEDIALARGE, MEDIAMEDIUM, MEDIAQUERY, MEDIASMALL, SIZEMEDIUM, SIZESMALL } from '../../variable';
import Colors from '../../Colors';
import ColorComp from '../form/ColorComp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import ButtonSpaceComp from '../ButtonSpaceComp';
const TimelineView = ({ data, ViewCB, widthDevice }) => {
    const [key, setKey] = useState('main')
    const [colData, setColData] = useState(data)
    const [isReady, setIsReady] = useState(false)
    const [masterData, setMasterData] = useState(data)
    const [widthDevice2, setWidthDevice2] = useState(widthDevice)

    const cbValue = (s_data) => {
        let key = labelCSS(s_data.key)
        let value = s_data.value
        let extension = key === "width" ? "%" : "px"
        if (widthDevice === MEDIALARGE) {
            setColData(prev => {
                return {
                    ...prev,
                    [key]: value + extension
                }
            })
        }

        if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
            let widthDeviceStr = SIZEMEDIUM
            if (widthDevice === MEDIASMALL) {
                widthDeviceStr = SIZESMALL
            }
            let temp = { ...colData }
            if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
                let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`]
                let newObject = {
                    [key]: value + extension
                }
                let newJoin = { ...tempNewObject, ...newObject }
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin
                setColData(temp)

            } else {
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
                    [key]: value + extension
                }
                setColData(temp)
            }
        }
    }



    useEffect(() => {
        if (colData) {
            ViewCB(colData)
            setMasterData(colData)
        }
    }, [colData])


    const addTimeline = () => {
        let temp = { ...colData }
        if (temp.content) {
            for (let a = 0; a < temp.content.length; a++) {
                temp.content[a].editMode = false
            }
        }
        temp.content.push({
            title: "judul",
            desc: 'desc',
            year: "year",
            editMode: false
        })
        setColData(temp)
    }

    const removeTimeline = (index) => {
        let temp = { ...colData }
        if (temp.content) {
            temp.content.splice(index, 1)
        }
        setColData(temp)
    }



    const calculateSetting = () => {
        setIsReady(false)
        let tempState = { ...masterData }
        tempState.marginTop = mediaQuery(tempState, "marginTop", tempState.marginTop, widthDevice)
        tempState.marginLeft = mediaQuery(tempState, "marginLeft", tempState.marginLeft, widthDevice)
        tempState.marginRight = mediaQuery(tempState, "marginRight", tempState.marginRight, widthDevice)
        tempState.marginBottom = mediaQuery(tempState, "marginBottom", tempState.marginBottom, widthDevice)
        tempState.paddingTop = mediaQuery(tempState, "paddingTop", tempState.paddingTop, widthDevice)
        tempState.paddingRight = mediaQuery(tempState, "paddingRight", tempState.paddingRight, widthDevice)
        tempState.paddingBottom = mediaQuery(tempState, "paddingBottom", tempState.paddingBottom, widthDevice)
        tempState.paddingLeft = mediaQuery(tempState, "paddingLeft", tempState.paddingLeft, widthDevice)

        setMasterData(tempState)
        setTimeout(() => {
            setIsReady(true)
        }, 500)
    }


    useEffect(() => {
        setIsReady(false)
        setTimeout(() => {
            calculateSetting(widthDevice2)
        }, 500)
    }, [widthDevice2])


    useEffect(() => {
        if (widthDevice !== widthDevice2) {
            setWidthDevice2(widthDevice)
        }
    }, [widthDevice])



    const changeData = (key, value, index) => {

        let temp = { ...colData }
        if (temp.content) {
            if (temp.content[index]) {
                temp.content[index][key] = value
            }
        }
        setColData(temp)
    }

    const save = (index) => {
        let temp = { ...colData }
        if (temp.content) {
            temp.content[index].editMode = false
        }
        setColData(temp)
    }


    const editMode = (index) => {
        let temp = { ...colData }
        if (temp.content) {
            for (let a = 0; a < temp.content.length; a++) {
                if (a !== index) {
                    temp.content[a].editMode = false
                } else {
                    temp.content[a].editMode = true
                }
            }
        }
        setColData(temp)
    }

    const changeColor = (value) => {

        // setColor(value)
        setColData(prev => {
            return {
                ...prev,
                color: value.hex
            }
        })

    }

    const changeBackground = (value) => {
        setColData(prev => {
            return {
                ...prev,
                backgroundColor: value.hex
            }
        })
    }


    return (
        <div style={{ textAlign: 'left' }}>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="main" title="main">
                    {isReady && <div style={{ color: "#fff" }}>
                        <ul style={{ paddingLeft: '0px', marginLeft: '-8px' }}>
                            {colData.content.map((c, index) => {
                                return <li style={{ background: Colors.dark, padding: '10px', borderRadius: '5px', marginBottom: '10px', cursor: 'pointer', listStyle: 'none', marginLeft: '0px' }}>
                                    {c.editMode && <>
                                        <Form.Group controlId="name">
                                            <Form.Label><span style={{ color: 'red' }}></span>Judul</Form.Label>
                                            <Form.Control
                                                type='text'
                                                value={c.title}
                                                onChange={($e) => { changeData("title", $e.target.value, index) }}
                                                className='form-control-dark'>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="name">
                                            <Form.Label><span style={{ color: 'red' }}></span>Deskripsi</Form.Label>
                                            <Form.Control
                                                as='textarea'
                                                rows="6"
                                                placeholder=''
                                                value={c.desc}
                                                onChange={($e) => {
                                                    changeData("desc", $e.target.value, index)
                                                }}
                                                className='form-control-dark'>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="name">
                                            <Form.Label><span style={{ color: 'red' }}></span>Tahun</Form.Label>
                                            <Form.Control
                                                type='text'
                                                value={c.year}
                                                onChange={($e) => { changeData("year", $e.target.value, index) }}
                                                className='form-control-dark'>
                                            </Form.Control>
                                        </Form.Group>
                                        <button className='btn btn-primary btn-sm' onClick={() => save(index)}>Simpan</button>
                                    </>}
                                    {!c.editMode && <>
                                        <h5 style={{ color: "#fff" }}>{c.title}</h5>
                                        <p style={{ color: '#fff' }}>{c.desc}</p>
                                        <p style={{ color: '#fff', marginTop: '-10px' }}>{c.year}</p>
                                        <span className='pr-3' onClick={() => editMode(index)}>
                                            <FontAwesomeIcon icon={faPencil} />
                                        </span>
                                        <span className='pr-3' onClick={() => removeTimeline(index)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </span>
                                    </>}
                                </li>
                            })}
                        </ul>
                        <button className='btn btn-primary btn-sm mt-3 mb-5' onClick={() => addTimeline()}> <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Tambah timeline</button>
                    </div>}
                    <ButtonSpaceComp></ButtonSpaceComp>
                </Tab>
                <Tab eventKey="setting" title="setting">
                    {isReady && <>

                        <ColorComp label="Background" colorCB={changeBackground} primaryColor={colData.backgroundColor}></ColorComp>
                        <ColorComp label="Color" colorCB={changeColor} primaryColor={colData.color}></ColorComp>

                        <RangeSlider value={masterData.marginTop} label="Margin Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginRight} label="Margin Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginBottom} label="Margin Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginLeft} label="Margin Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingTop} label="Padding Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingRight} label="Padding Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingBottom} label="Padding Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingLeft} label="Padding Left" cbValue={cbValue} maxvalue="100"></RangeSlider>

                    </>}
                    <ButtonSpaceComp></ButtonSpaceComp>
                </Tab>
                {/* <Tab eventKey="animation" title="animasi" >
                    <AnimationComp value={colData.animation ? colData.animation : ''} cbValue={cbAnimation}></AnimationComp>
                </Tab> */}
            </Tabs>




        </div>
    )
}

export default TimelineView
