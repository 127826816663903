import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Tabs, Tab } from 'react-bootstrap'
import RangeSlider from '../form/Rangeslider';
import labelCSS from '../../helpers/labelCss'
import { useColor } from "react-color-palette";
import TextAlign from '../form/TextAlign';
import ColorComp from '../form/ColorComp';
import FontIconComp from './FontIconComp';
import GoogleFontsComp from '../form/GoogleFontsComp';
import AnimationComp from '../form/AnimationComp';
const LineView = ({ data, ViewCB }) => {

    const [key, setKey] = useState('main')
    const [color, setColor] = useColor("rgb", "98, 142, 48");
    const [background, setBackground] = useColor("rgb", "98, 142, 48");
    const [isLink, setIsLink] = useState(true)

    const [colData, setColData] = useState(data)

    const cbAnimation = (value) => {
        setColData(prev => {
            return {
                ...prev,
                animation: value
            }
        })
    }

    const cbValue = (data) => {

        let key = labelCSS(data.key)

        let value = data.value
        let extension = key === "width" ? "%" : "px"
        if (data.key === "textAlign") {
            extension = ""

        }


        if (extension === "") {

            setColData(prev => {
                return {
                    ...prev,
                    [key]: value
                }
            })
        } else {

            setColData(prev => {
                return {
                    ...prev,
                    [key]: value + extension
                }
            })
        }


    }
    const setContent = (value) => {

        setColData(prev => {
            return {
                ...prev,
                content: value
            }
        })

    }


    const setAction = (value) => {
        setColData(prev => {
            return {
                ...prev,
                action: value,
                link: value === "popup" ? "" : colData.link
            }
        })
        if (value === "link") {
            setIsLink(true)
        } else {
            setIsLink(false)
        }

    }


    const changeLink = (value) => {
        setColData(prev => {
            return {
                ...prev,
                link: value
            }
        })

    }

    const changeBorderRadius = (value) => {
        setColData(prev => {
            return {
                ...prev,
                borderRadius: value
            }
        })

    }

    const setFontWeight = (value) => {

        setColData(prev => {
            return {
                ...prev,
                fontWeight: value
            }
        })
    }

    const changeColor = (value) => {

        var color
        if (value === "none") {
            color = "none !important"
        } else {
            color = value.hex
        }
        setColor(color)
        setColData(prev => {
            return {
                ...prev,
                color: color
            }
        })

    }


    const noColor = (value) => {
        setColData(prev => {
            return {
                ...prev,
                isTransparent: value
            }
        })
    }
    const changeBackgroundColor = (value) => {

        setBackground(value)
        setColData(prev => {
            return {
                ...prev,
                isTransparent: false,
                backgroundColor: value.hex
            }
        })




    }

    const cbFontValue = (data) => {

        let key = labelCSS(data.key)

        let value = data.value
        let extension = key === "width" ? "%" : "px"
        if (key === "textAlign" || key === "fontFamily") {
            extension = ""

        }



        setColData(prev => {
            return {
                ...prev,
                [key]: value + extension
            }
        })

    }

    const changeIcon = (value) => {

        setColData(prev => {
            return {
                ...prev,
                icon: value
            }
        })
    }

    const changeIconPosition = (value) => {
        if (value !== "none") {
            setColData(prev => {
                return {
                    ...prev,
                    icon: value === "right" ? "fas fa-angle-right" : "fas fa-angle-left"
                }
            })
        }
        setColData(prev => {
            return {
                ...prev,
                iconPosition: value
            }
        })
    }

    useEffect(() => {
        if (colData) {
            ViewCB(colData)
        }
    }, [colData])

    useEffect(() => {
        if(colData.action === "link"){
            setIsLink(true)
        } else {
            setIsLink(false)
        }
    },[colData])

    return (
        <div style={{ textAlign: 'left' }}>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="main" title="main">
                    <Form.Group controlId="name">
                        <Form.Label><span style={{ color: 'red' }}>*</span>Teks</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control type='text' placeholder='' value={colData.content} onChange={($e) => setContent($e.target.value)}></Form.Control>
                            </Col>
                        </Row>

                    </Form.Group>
                    <TextAlign value={colData.textAlign} label="Text Align" cbValue={cbValue}></TextAlign>
                    <RangeSlider value={colData.fontSize} label="Font Size" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <Form.Group controlId="name">
                        <Form.Label><span style={{ color: 'red' }}>*</span>Action</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control as="select" onChange={($e) => setAction($e.target.value)}>
                                    {/* <option value="popup" selected={colData.action === "popup"}>Pop Up</option>
                                    <option value="ctwa" selected={colData.action === "ctwa"}>CTWA</option> */}
                                    <option value="link" selected={colData.action === "link"}>Link</option>
                                    <option value="open lock" selected={colData.action === "open lock"}>Open Lock</option>
                                </Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>

                    {isLink && <Form.Group controlId="name">
                        <Form.Label><span style={{ color: 'red' }}>*</span>Link</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control type='text' placeholder='' value={colData.link} onChange={($e) => changeLink($e.target.value)} ></Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>
                    }                    
                    <GoogleFontsComp value={colData.fontFamily} label="Font Family" cbValue={cbFontValue}></GoogleFontsComp>

                    <Form.Group controlId="name">
                        <Form.Label><span style={{ color: 'red' }}>*</span>Font Weight</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control as="select" onChange={($e) => setFontWeight($e.target.value)}>
                                    <option value="regular" selected={colData.fontWeight === "regular"}>Regular</option>
                                    <option value="bold" selected={colData.fontWeight === "bold"}>Bold</option>

                                </Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>

                    <ColorComp label="Color" colorCB={changeColor} primaryColor={colData.color}></ColorComp>
                    {/* <Form.Label><span style={{ color: 'red' }}>*</span>Background</Form.Label> */}
                    {/* <ColorPicker width={250} height={228} color={background} onChange={changeBackgroundColor} hideHSV hideRGB  light /> */}
                    <ColorComp label="Background Color" colorCB={changeBackgroundColor} primaryColor={colData.backgroundColor} showNoColor={true} noColor={noColor}></ColorComp>
                    <Form.Group controlId="name">
                        <Form.Label><span style={{ color: 'red' }}>*</span>Icon Position</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control as="select" onChange={($e) => changeIconPosition($e.target.value)}>
                                    <option value="none" selected={colData.iconPosition === "none"}>None</option>
                                    <option value="left" selected={colData.iconPosition === "left"}>Left</option>
                                    <option value="right" selected={colData.iconPosition === "right"}>Right</option>

                                </Form.Control>
                            </Col>
                        </Row>
                    </Form.Group>
                    <FontIconComp iconclassName={colData.icon} iconClassCB={changeIcon}></FontIconComp>


                </Tab>
                <Tab eventKey="setting" title="setting">
                    <RangeSlider value={colData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.marginTop} label="Margin Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.marginRight} label="Margin Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.marginBottom} label="Margin Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.marginLeft} label="Margin Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.paddingTop} label="Padding Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.paddingRight} label="Padding Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.paddingBottom} label="Padding Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.paddingLeft} label="Padding Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    {/* <Form.Label><span style={{ color: 'red' }}>*</span>Color</Form.Label> */}
                    {/* <ColorPicker width={250} height={228} color={color} onChange={changeColor} hideHSV hideRGB  light /> */}

                </Tab>
                <Tab eventKey="animation" title="animasi" >
                    <AnimationComp value={colData.animation ? colData.animation.value : ''} cbValue={cbAnimation}></AnimationComp>
                </Tab>
            </Tabs>







        </div>
    )
}

export default LineView
