import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
function BreadCrumbComp({ data }) {
    return (
        <div>
            <Breadcrumb style={{ backgroundColor: '#fff', marginTop: '25px' }}>
                <Breadcrumb.Item href={data.root_link}>{data.root}</Breadcrumb.Item>
                <Breadcrumb.Item href={data.menu_link}>
                    {data.menu}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{data.current}</Breadcrumb.Item>
            </Breadcrumb>
        </div>
    )
}

export default BreadCrumbComp
