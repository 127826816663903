import React, { useState,useEffect } from 'react'
import { LARGEDEVICE } from '../variable'
import { checkDevice } from '../helpers/windowHelper'

const ScrollMenuX = ({ menus,active, actionCB }) => {
    const [device, setDevice] = useState('')
    const [hasScroll,setHasScroll] = useState('')


    useEffect(() => {
        setDevice(checkDevice())
    },[])

    return <>
        <div style={{ display: 'flex', overflowX: device === LARGEDEVICE ? hasScroll : 'scroll' }}
            onMouseEnter={(() => setHasScroll("scroll"))}
            onMouseLeave={(() => setHasScroll("hidden"))}
        >
            {menus && menus.map(menu => {
                return <div style={{ background: menu._id === active ? '#14B2D0' : '#343536', borderRadius: '5px', padding: '5px 10px', color: "#fff", border: 'solid 1px #4B4B4C', margin: '7px 5px', height: '35px', marginBottom: '10px', cursor: 'pointer',width:'auto' }} onClick={() => actionCB("category", menu._id)}>
                    {menu.name}
                </div>
            })}
        </div>
    </>
}
export default ScrollMenuX