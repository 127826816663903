import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { userLoginServices } from "../services/userServices";
import { useDispatch, useSelector } from "react-redux";
import { loginAction, logoutAction } from "../redux/features/userSlices";
import Alert from "react-bootstrap/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";
import queryString from 'query-string';
const LoginPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = queryString.parse(location.search);
  const clear = queryParams.clear; // 'react'
  if(queryParams?.clear !== undefined && clear==="true"){
    dispatch(logoutAction())
  }

  let navigate = useNavigate();
  const { user } = useSelector((state) => state);
  useEffect(() => {
    if(user?.app_id){
      navigate('/')
    }
  },[user])

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Format email salah")
      .required("Email diperlukan"),
    password: Yup.string().required("Password diperlukan"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    try {
      let res = await userLoginServices(values.email, values.password);
      if (res.status === 200) {
        setShowAlert(false);
        setAlertMessage("");
        let payload = res?.data;
        dispatch(loginAction(payload));
        navigate("/");
      }
    } catch (err) {
      setShowAlert(true);
      setAlertMessage(err?.response?.data?.message);
    }
  };

  const breakpointColumnsObj = {
    default: 8,
    1100: 8,
    700: 6,
    500: 4,
  };

  return (
    <div
      style={{
        background: "black",
        height: "100vh",
        width: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 2,
          margin: "0 auto",
          background:
            "linear-gradient(to bottom right, rgba(76, 15, 230, 0.6), rgba(129, 3, 92, 0.9))",
          width: "100vw",
          height: "100vh",
        }}
      >
        <div style={{ width: "270px", margin: "0 auto" }}>
          <div className="text-center">
            <img
              alt="idinvitebook"
              src="https://d1jcw7e5n1q2pt.cloudfront.net/1704675562861-Idinvitebo.png"
              width="110"
              className="text-center mt-5 pt-5"
            />
          </div>
          {showAlert && <Alert variant="danger mt-4">{alertMessage}</Alert>}
          <div
            style={{
              background: "#fff",
              borderRadius: "10px",
              padding: "10px 25px",
              marginTop: "25px",
            }}
          >
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div>
                    <div className="flex flex-column">
                      <div className="flex-1">
                        <label htmlFor="email" className="mt-2">
                          Email
                        </label>
                      </div>
                      <div className="flex-1">
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                        />
                        <ErrorMessage name="email" component="div" />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="mt-2">
                      Password
                    </label>
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      className="form-control"
                    />
                    <ErrorMessage name="password" component="div" />
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary mt-2 mb-5"
                    style={{ width: "100%", marginTop: "20px" }}
                  >
                    Login
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        <div>
          <img src="bg1.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg6.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg2.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg3.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg7.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg8.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg2.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg6.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg3.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg1.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg4.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg5.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg6.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg7.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg8.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg1.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg2.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg3.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg9.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg10.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg5.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg6.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg7.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg1.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg6.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg2.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg3.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg7.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg10.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg5.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg6.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg10.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg5.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="bg6.jpg" className="img-fluid" />
        </div>
      </Masonry>
    </div>
  );
};

export default LoginPage;
