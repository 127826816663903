import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  app_active: "",
  app_id: "",
  app_status: "",
  app_type: "",
  customerSetting: "",
  email: "",
  name: "",
  role: "",
  thumbnail: "",
  whatsapp: "",
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    loginAction: (state, action) => {
      let payload = action.payload;
      state.app_active = payload.app_active;
      state.app_id = payload.app_id;
      state.app_status = payload.app_status;
      state.app_type = payload.app_type;
      state.customerSetting = payload.customerSetting;
      state.email = payload.email;
      state.name = payload.name;
      state.thumbnail = payload.thumbnail;
      state.whatsapp = payload.whatsapp; 
      state.role = payload.role;
    },
    updateProfileAction: (state, action) => {
      let payload = action.payload;
      state.thumbnail = payload.thumbnail;
      state.whatsapp = payload.whatsapp;
    },
    updateSettingAction: (state, action) => {
      let payload = action.payload;
      state.customerSetting.domain = payload.domain;
      state.customerSetting.homepage_link = payload.homepage_link;
    },
    logoutAction: (state) => {
      return initialState;
    },
  },
});

export const { loginAction, logoutAction,updateProfileAction,updateSettingAction } = userSlice.actions;
export default userSlice.reducer;
