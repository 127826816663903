import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DataTable from 'react-data-table-component';
// import { getLandingpages, duplicateLandingpage, deleteLandingpage } from '../../services/landingpage'
import columns from './datatable/columns'
import { Modal, Button, Container, Row, Col, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import NavbarComp from '../../components/NavbarComp';
import ContactCSComp from '../../components/ContactCSComp';
import { checkDevice } from '../../helpers/windowHelper';
import { LARGEDEVICE } from '../../variable';
import { getLandingpages ,duplicateLandingpage,deleteLandingpage} from '../../services/invitationServices';
import LoaderComp from '../../components/LoaderComp';
const InvitationPage = () => {
    let navigate = useNavigate();
    const { user } = useSelector((state) => state);
    useEffect(() => {
      if(!user?.app_id){
        navigate('/login')
      }
    },[user])
    
    const [loader, setLoader] = useState(true)
    const [selectedUndangan, setSelectedUndangan] = useState("")
    const [selectedUndanganToRemove, setSelectedUndanganToRemove] = useState("")
    const [profile, setProfile] = useState()
    const [isEmpty, setIsEmpty] = useState(false)
    const [showModalShare, setShowModalShare] = useState(false)
    const [showModalDelete, setShowModalDelete] = useState(false)
    const [showModalCopy, setShowModalCopy] = useState(false)
    const [disableCopy,setDisableCopy] = useState(true)
    const [landingpage, setLandingpage] = useState({});
    const [undanganName,setUndanganName] = useState("")
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    const handleResize = () => {
        let toolbarHeight =60;
        setViewportHeight(window.innerHeight-toolbarHeight);
      };

    useEffect(() => {
        // Set initial viewport height
        handleResize();
    
        // Add resize event listener
        window.addEventListener('resize', handleResize);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    
    const [device, setDevice] = useState("")
    
 
    const [query, setQuery] = useState({
        filterBy: 'name',
        type: '',
        status: '',
        tempo: '',
        key: '',
        page: 1,
        pageSize: 25
    });


    const detailCB = (data, action) => {

        switch (action) {
            case "duplicate":
                setSelectedUndangan(data)
                setTimeout(() => {
                    toggleModalCopy(true)
                },100)
                
                break;
            case "share":
                setSelectedUndangan(data)
                setShowModalShare(true)

                break;
            case "delete":
                // alert("del")
                toggleModalDelete(true)
                setSelectedUndanganToRemove(data)

                break;

        }
    }
    const fetchInvitations = async () => {
        var q = `&filterBy=${query.filterBy}&keyword=${query.key}&status=${query.status}&type=${query.type}`
        getLandingpages(query.pageSize, query.page, q).then(res => {
        
          
            setLandingpage(res.data)
            setLoader(false)
        }).catch(err => {
            setIsEmpty(true)
            setLoader(false)
        })
    

    }


    useEffect(() => {
        fetchInvitations();
    }, [query])


    const getProfile = () => {
        if (user) {
            setProfile(user)
        }
    }

    useEffect(() => {
        getProfile()
        setDevice(checkDevice())
    }, [])


    const copyLink = () => {
        navigator.clipboard.writeText(selectedUndangan);
    }
    const closeShareDialog = () => {
        setShowModalShare(false)
        setSelectedUndangan("")
    }

    const toggleModalDelete = (value) => {
        setShowModalDelete(value)
    }
    const toggleModalCopy = (value) => {
        setShowModalCopy(value)
    }
    const deleteUndagnan = () => {
        deleteLandingpage(selectedUndanganToRemove._id).then(res => {
            fetchInvitations()
            toggleModalDelete(false)
        })
    }

    const createNew = () => {
        navigate.push('/editor-web')
    }

    const changeUndanganName = (value) => {
        if(value.trim() !== ""){
            setDisableCopy(false)
        } else {
            setDisableCopy(true)
        }
        setUndanganName(value)
    }

    const cloneNow = () => {
        duplicateLandingpage(selectedUndangan._id, {
            name:undanganName
        }).then(res => {
            toggleModalCopy(false)
            fetchInvitations()
           
        })
    }

    return (
        <div>
            <Modal show={showModalCopy} onHide={() => toggleModalCopy(false)} style={{ marginTop: '100px' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Duplikasi Undangan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Undangan</Form.Label>
                            <Form.Control type="text" placeholder="masukkan nama undangan" onChange={($e) => changeUndanganName($e.target.value) }/>
                            <Button variant='primary' className='btn-full mt-3' disabled={disableCopy} onClick={() => cloneNow()}>Buat Undangan</Button>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showModalDelete} onHide={() => toggleModalDelete(false)} style={{ marginTop: '100px' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Hapus Undangan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center' style={{ padding: '60px 50px' }}>
                        <h3>Yakin Hapus undangan {selectedUndanganToRemove.title}?</h3>
                        <Button className='ml-3 mr-3 mt-4' onClick={() => toggleModalDelete(false)}>Batal</Button>
                        <Button className='ml-3 mr-3 mt-4' onClick={() => deleteUndagnan()}>Hapus</Button>

                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showModalShare} onHide={() => closeShareDialog()} style={{ marginTop: '100px' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Share Link ke customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={10}>
                            <Form.Group className="mb-3">
                                <Form.Label>Link (copy link dan berikan ke customer)</Form.Label>
                                <Form.Control type="text" placeholder="Link" value={selectedUndangan} />
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Button className='mt-4' onClick={() => copyLink()}>Copy Link</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <NavbarComp></NavbarComp>
            <Container >
                <Row>
                    <Col md={12}>
                        {isEmpty && <div className='text-center pt-4 pb-4 mt-5 mb-5' style={{ background: '#fff' }}>
                            <h4 className='mt-3 mb-2'>Oops undangan masih kosong nih</h4>
                            <Button className='btn btn-primary mt-4 mb-4' onClick={createNew}>Buat Undangan</Button>
                        </div>}
                        {loader ? <div style={{marginTop:'200px'}}><LoaderComp /></div> :
                            <>
                                {!isEmpty && <>
                                    <div className=' pt-1' style={{ background: '#fff' }}>
                                        <div style={{ height: device === LARGEDEVICE ? 'auto' :viewportHeight+"px",marginTop:device === LARGEDEVICE?'70px':'0px' }}>
                                            <DataTable
                                                columns={columns(landingpage, detailCB, query, profile, device)}
                                                data={landingpage.landingpageList}
                                                highlightOnHover
                                                pagination
                                                paginationServer
                                                paginationTotalRows={landingpage.total}
                                                paginationPerPage={query.pageSize}
                                                paginationComponentOptions={{
                                                    noRowsPerPage: false
                                                }}
                                                paginationRowsPerPageOptions={[25, 50, 100, 125, 150]}
                                                onChangePage={page => setQuery(prev => {
                                                    return {
                                                        ...prev,
                                                        page
                                                    }
                                                })}
                                                onChangeRowsPerPage={pageSize => setQuery(prev => {
                                                    return {
                                                        ...prev,
                                                        page: 1,
                                                        pageSize
                                                    }
                                                })}
                                            />
                                        </div>
                                      {device !== LARGEDEVICE && <div style={{height:'50px'}}></div>}
                                    </div>
                                </>}
                            </>
                        }
                    </Col>
                </Row>
            </Container>
            <ContactCSComp></ContactCSComp>
        </div >
    )
}

export default InvitationPage
