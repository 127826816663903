import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Tabs, Tab } from 'react-bootstrap'
import RangeSlider from '../form/Rangeslider';
import labelCSS from '../../helpers/labelCss'
import AnimationComp from '../form/AnimationComp';
import ColorComp from '../form/ColorComp';
import BorderComp from '../form/BorderComp';
import mediaQuery, { clearBorderRadius, isAdvancedRadius } from '../../helpers/mediaQueryHelper';
import { MEDIALARGE, MEDIAMEDIUM, MEDIAQUERY, MEDIASMALL, SIZEMEDIUM, SIZESMALL } from '../../variable';
import BorderRadiusComp from '../form/BorderRadiusComp';
import ButtonSpaceComp from '../ButtonSpaceComp';
const BankView = ({ data, ViewCB, widthDevice }) => {
    const [isBorderRadiusAdvanced, setIsBorderRadiusAdvanced] = useState(isAdvancedRadius(data))
    const delay = 1;
    let desc
    const [key, setKey] = useState('main')
    const [isActive, setIsActive] = useState(false)
    const [isReady, setIsReady] = useState(false)

    const [masterData, setMasterData] = useState(data)
    const [widthDevice2, setWidthDevice2] = useState(widthDevice)
    const [bankList, setBankList] = useState([
        {
            "bank": "BRI",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701657989470-bripanjaNG.png"
        },
        {
            "bank": "BRI Syariah",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701515773673-brisyariah.png"
        },
        {
            "bank": "BNI",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701515887177-bni.png"
        },
        {
            "bank": "BNI Syariah",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701515983808-bnisyariah.png"
        },
        {
            "bank": "Bank Jateng",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701516059075-bankjateng.png"
        },
        {
            "bank": "Bank BJB",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701516114207-bankbjblogo.png"
        },
        {
            "bank": "Muamalat",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701516203449-muamalat.png"
        },
        {
            "bank": "Mandiri",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701655033971-mandr.png"
        },
        {
            "bank": "Mandiri Syariah",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701516586520-mandirisyariahlogo.png"
        },
        {
            "bank": "BCA",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701516715167-bca.png"
        },
        {
            "bank": "BCA syariah",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701516926764-bcasyariah.png"
        },
        {
            "bank": "DKI",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701517080737-bankdki.png"
        },
        {
            "bank": "Sinarmas",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701517144243-sinarmas.png"
        },
        {
            "bank": "Permata",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701517488763-permata.png"
        },
        {
            "bank": "Danamon",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701655239493-danamon.png"
        },
        {
            "bank": "Dana",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701517879409-dana.png"
        },
        {
            "bank": "Shopee Pay",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701655296298-shopeepay.png"
        },
        {
            "bank": "OVO",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701655357948-ovo.png"
        },
        {
            "bank": "Gopay",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701655416920-gopay.png"
        },
        {
            "bank": "Link Aja",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701655473080-linkaja.png"
        },
        {
            "bank": "BSI",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701655521498-bsi.png"
        },
        {
            "bank": "Bank Jatim",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701655665820-bankjatim.png"
        },
        {
            "bank": "Bank Nagari",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701655949891-nagari.png"
        },
        {
            "bank": "CIMB",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701655756993-cimb.png"
        },
        {
            "bank": "Bank Kalsel",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701655790573-bankkalsel.png"
        },
        {
            "bank": "Bank Sumsel Babel (BSB)",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701656010298-sumselbab.png"
        },
        {
            "bank": "Bank Sumsel Babel Syariah",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1701655881159-LOGO_BANK_SUMSEL_BABEL_SYARIAH.png"
        },
        {
            "bank": "Isaku",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1702446489450-isakuaslie.png"
        },
        {
            "bank": "Bank Aceh",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1703034361759-Logo_Bank_Aceh_Syariah.png"
        },
        {
            "bank": "Bank Bukopin",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1703386570520-bukopin-removebg-preview.png"
        },
        {
            "bank": "SEA Bank",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1703389407641-seabank-removebg-preview.png"
        },
        {
            "bank": "May Bank",
            "image": "https://file.idinvitebook.com/1713001048522-mybank.png.png"
        },
        {
            "bank": "Bank Mayora",
            "image": "https://d1jcw7e5n1q2pt.cloudfront.net/1704186781514-mayora.png"
        },
        {
            "bank": "Bank Sumut",
            "image": "https://file.idinvitebook.com/1706835939883-banksumut..png"
        },
        {
            "bank": "Bank BSG",
            "image": " https://file.idinvitebook.com/1708425569866-bsg.png.png"
        },
        {
            "bank": "Panin Bank",
            "image": " https://file.idinvitebook.com/1715398405932-paninbank..png"
        },
        {
            "bank": "Artha Graha International",
            "image": "https://file.idinvitebook.com/1716895983839-agi-remove.png"
        },   
        {
            "bank": "Bank BTN",
            "image": "https://file.idinvitebook.com/1716896169315-btn-remove.png"
        }, 
        {
            "bank": "Bank OCBC",
            "image": "https://file.idinvitebook.com/1717211635285-ocbc-remov.png"
        },          
        {
            "bank": "Paypal",
            "image": "https://file.idinvitebook.com/1708560077494-paypal-rem.png"
        },
        {
            "bank": "CCBI",
            "image": "https://file.idinvitebook.com/1717820542202-ccb-remove.png"
        },
        {
            "bank": "NTB Syariah",
            "image": "https://file.idinvitebook.com/1718108647785-ntbsyariah.png"
        } ,
        {
            "bank": "Bank Banten",
            "image": "https://file.idinvitebook.com/1719315446610-bankbanten.png"
        },
        {
            "bank": "Bank Jambi",
            "image": "https://file.idinvitebook.com/1723335394549-bankjambi-.png"
        }          
    ])

    const [colData, setColData] = useState(data)

    const cbAnimation = (value) => {
        setColData(prev => {
            return {
                ...prev,
                animation: value
            }
        })
    }

    const boderRadiusAdvancedMode = (val) => {
        setIsBorderRadiusAdvanced(val)
        let resetRadius = clearBorderRadius(masterData)
        setColData(resetRadius)
    }


    const changeBorderColor = (value) => {
        setColData(prev => {
            return {
                ...prev,
                isBorderTransparent: false,
                borderColor: value.hex
            }
        })
    }

    const changeValueCB = (value) => {
        setColData(prev => {
            return {
                ...prev,
                borderType: value
            }
        })
    }

    const cbValue = (data) => {
        let key = labelCSS(data.key)
        let value = data.value
        let extension = key === "width" ? "%" : "px"
        if (data.key === "textAlign") {
            extension = ""
        }
        if (widthDevice === MEDIALARGE) {
            if (extension === "") {
                setColData(prev => {
                    return {
                        ...prev,
                        [key]: value
                    }
                })
            } else {
                setColData(prev => {
                    return {
                        ...prev,
                        [key]: value + extension
                    }
                })
            }
        }
        if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
            let widthDeviceStr = SIZEMEDIUM
            if (widthDevice === MEDIASMALL) {
                widthDeviceStr = SIZESMALL
            }
            let temp = { ...colData }
            if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
                let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`]
                if (extension !== "") {
                    value = value + extension
                }
                let newObject = {
                    [key]: value
                }
                let newJoin = { ...tempNewObject, ...newObject }
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin
                setColData(temp)

            } else {
                if (extension !== "") {
                    value = value + extension
                }
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
                    [key]: value
                }
                setColData(temp)
            }
        }
    }

    const noColorBG = () => {
        setColData(prev => {
            return {
                ...prev,
                backgroundColor: ''
            }
        })
    }





    useEffect(() => {
        if (colData && data) {
            ViewCB(colData)
            setMasterData(colData)
        }
    }, [colData])



    const changeData = (key, value) => {
        if (key === "bank") {
            let imageBank = bankList.find(b => {
                return b.bank.toLowerCase() === value.toLowerCase()
            })
            setColData(prev => {
                return {
                    ...prev,
                    [key]: value,
                    image: imageBank.image
                }
            })
        } else {
            setColData(prev => {
                return {
                    ...prev,
                    [key]: value
                }
            })
        }

    }


    const setBgColortoBackground = (value) => {
        //  dddd
        if (isActive) {
            setColData(prev => {
                return {
                    ...prev,
                    backgroundColor: value.hex,
                }
            })
        }
        setTimeout(() => {
            setIsActive(true)
        }, 100)
        // 
    }


    const changeColor = (value) => {

        setColData(prev => {
            return {
                ...prev,
                color: value.hex
            }
        })
    }



    const calculateSetting = () => {
        setIsReady(false)
        let tempState = { ...masterData }
        tempState.marginTop = mediaQuery(tempState, "marginTop", tempState.marginTop, widthDevice)
        tempState.marginLeft = mediaQuery(tempState, "marginLeft", tempState.marginLeft, widthDevice)
        tempState.marginRight = mediaQuery(tempState, "marginRight", tempState.marginRight, widthDevice)
        tempState.marginBottom = mediaQuery(tempState, "marginBottom", tempState.marginBottom, widthDevice)
        tempState.paddingTop = mediaQuery(tempState, "paddingTop", tempState.paddingTop, widthDevice)
        tempState.paddingRight = mediaQuery(tempState, "paddingRight", tempState.paddingRight, widthDevice)
        tempState.paddingBottom = mediaQuery(tempState, "paddingBottom", tempState.paddingBottom, widthDevice)
        tempState.paddingLeft = mediaQuery(tempState, "paddingLeft", tempState.paddingLeft, widthDevice)
        if(isBorderRadiusAdvanced){
            tempState.borderRadius = 0
            tempState.borderTopLeftRadius = mediaQuery(tempState, "borderTopLeftRadius", tempState.borderTopLeftRadius, widthDevice)
            tempState.borderTopRightRadius = mediaQuery(tempState, "borderTopRightRadius", tempState.borderTopRightRadius, widthDevice)
            tempState.borderBottomLeftRadius = mediaQuery(tempState, "borderBottomLeftRadius", tempState.borderBottomLeftRadius, widthDevice)
            tempState.borderBottomRightRadius = mediaQuery(tempState, "borderBottomRightRadius", tempState.borderBottomRightRadius, widthDevice)
        } else {
            tempState.borderRadius = mediaQuery(tempState, "borderRadius", tempState.borderRadius, widthDevice)
            tempState.borderTopLeftRadius = 0
            tempState.borderTopRightRadius = 0
            tempState.borderBottomLeftRadius =0
            tempState.borderBottomRightRadius =0
        }
        tempState.backgroundColor = mediaQuery(tempState, "backgroundColor", tempState.backgroundColor, widthDevice)
        tempState.color = mediaQuery(tempState, "color", tempState.color, widthDevice)
        tempState.borderType = mediaQuery(tempState, "borderType", tempState.borderType, widthDevice)
        tempState.borderColor = mediaQuery(tempState, "borderColor", tempState.borderColor, widthDevice)
        setMasterData(tempState)
        setTimeout(() => {
            setIsReady(true)
        }, 500)
    }

    useEffect(() => {
        setIsReady(false)
        setTimeout(() => {
            calculateSetting(widthDevice2)
        }, 500)
    }, [widthDevice2])


    useEffect(() => {
        if (widthDevice !== widthDevice2) {
            setWidthDevice2(widthDevice)
        }
    }, [widthDevice])

    return (
        <div style={{ textAlign: 'left' }}>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="main" title="main">
                    {isReady && <>
                        <Form.Group controlId="name">
                            <Form.Label><span style={{ color: 'red' }}>*</span>Bank</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control as="select" onChange={($e) => changeData("bank", $e.target.value)} className='form-control-dark'>
                                        {bankList.map(b => {
                                            return <option value={b.bank} selected={colData.bank === b.bank}>{b.bank}</option>
                                        })}
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>No Rekening</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="No Rekening"
                                value={colData.account}
                                onChange={($e) => changeData("account", $e.target.value)}
                                className='form-control-dark'
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="">
                            <Form.Label>Atas Nama</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Atas Nama"
                                value={colData.account_name}
                                onChange={($e) => changeData("account_name", $e.target.value)}
                                className='form-control-dark'
                            />
                        </Form.Group>
                        <Row>
                            <Col>

                                <ColorComp label="Background" colorCB={setBgColortoBackground} primaryColor={masterData.backgroundColor} showNoColor={true} noColor={noColorBG}></ColorComp>
                            </Col>
                        </Row>
                        <ColorComp label="Border" colorCB={changeBorderColor} primaryColor={masterData.borderColor} showNoColor={false} ></ColorComp>
                        <ColorComp label="Color" colorCB={changeColor} primaryColor={masterData.color}></ColorComp>
                        <BorderComp defaultValue={masterData.borderType} changeValue={changeValueCB} ></BorderComp>
                    </>}
                    <ButtonSpaceComp></ButtonSpaceComp>
                </Tab>
                <Tab eventKey="setting" title="setting">
                    {isReady && <>
                        <BorderRadiusComp value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100" advancedValue={
                            {
                                borderTopLeftRadius: masterData.borderTopLeftRadius,
                                borderTopRightRadius: masterData.borderTopRightRadius,
                                borderBottomLeftRadius: masterData.borderBottomLeftRadius,
                                borderBottomRightRadius: masterData.borderBottomRightRadius
                            }
                        } isAdvancedMode={isBorderRadiusAdvanced} adVancedModeCB={boderRadiusAdvancedMode}></BorderRadiusComp> 
                        
                        {/* <RangeSlider value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100"></RangeSlider> */}
                        <RangeSlider value={masterData.marginTop} label="Margin Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        {/* <RangeSlider value={masterData.marginRight} label="Margin Right" cbValue={cbValue}></RangeSlider> */}
                        <RangeSlider value={masterData.marginBottom} label="Margin Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        {/* <RangeSlider value={masterData.marginLeft} label="Margin Left" cbValue={cbValue}></RangeSlider> */}
                        <RangeSlider value={masterData.paddingTop} label="Padding Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingRight} label="Padding Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingBottom} label="Padding Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingLeft} label="Padding Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.border} label="Border" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    </>}
                    {/* <Form.Label><span style={{ color: 'red' }}>*</span>Color</Form.Label> */}
                    {/* <ColorPicker width={250} height={228} color={color} onChange={changeColor} hideHSV hideRGB  light /> */}
                    <ButtonSpaceComp></ButtonSpaceComp>
                </Tab>
                <Tab eventKey="animation" title="animasi" >
                    <AnimationComp value={colData.animation} cbValue={cbAnimation}></AnimationComp>
                </Tab>
            </Tabs>







        </div>
    )
}

export default BankView