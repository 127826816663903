import axiosInstance from "../helpers/axiosHelper"
import { axiosInstancePublic } from "../helpers/axiosHelper"
export const upload = (data) => {
    return axiosInstancePublic.post(`/api/v1/public-image/file-upload`, data)
}

export const uploadwebp = (link) => {
    return axiosInstance.post(`https://api.jpegtowebp.com/api/v1/public-image/file-upload-webp`, {
        link:link,
        bucket:'invb'
    })
}
