import { DragHandle } from '../../partials/DragHandle'
const RowMenu = ({ row, provided, settingRow, duplicateRow, removeRow, showDragger,widthDev }) => {


    return <div className='slide-row' id={`slide-${row.index}`} style={{position:'absolute',top:0,left:widthDev==="100%"?"46%":widthDev==="768px"?"41%":"31%"}}>
        <div className="slide-row-flex " style={{ float: showDragger ? '' : 'right', width: row.islock ? '160px' : '140px' }}>
            <div style={{ flex: 1 }}>
                {showDragger && <div className='slide-row-flex-content' >
                    <DragHandle {...provided.dragHandleProps} />
                </div>}
            </div>
          
            <div className='slide-row-flex-content' onClick={() => settingRow()}>
                <i className='fas fa-pen slide-row-flex-icon' ></i>
            </div>
            <div className='slide-row-flex-content' onClick={() => duplicateRow()}>
                <i className='fas fa-copy slide-row-flex-icon'></i>
            </div>
            {row.index === 0 && row.islock === true && <div className='slide-row-flex-content'>
                <i className='fas fa-lock slide-row-flex-icon'></i>
            </div>}
            <div className='slide-row-flex-content' onClick={() => removeRow()}>
                <i className='fas fa-trash slide-row-flex-icon'></i>
            </div>
        </div>
    </div>
}

export default RowMenu