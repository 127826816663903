import { Form, Modal, ModalBody } from "react-bootstrap"
import ColView from "../landingpage/ColView"
import React from 'react'
const RowModalComp = ({numberOfCol,showWayToAdd,handleCloseWayToAdd,changeNumOfCol,ColCB}) => {
    return <Modal show={showWayToAdd} onHide={handleCloseWayToAdd} style={{ top: '20%' }}>
        <Modal.Body>
 
            <Form.Group controlId="name">
                <Form.Label><span style={{ color: 'red' }}>*</span>Jumlah Kolom</Form.Label>
                <Form.Control as="select" onChange={($e) => changeNumOfCol($e.target.value)}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                </Form.Control>
            </Form.Group>
            <ColView colnumber={numberOfCol} ColCB={ColCB}></ColView>
        </Modal.Body>
    </Modal>
}
export default RowModalComp