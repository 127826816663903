import React, { useState, useEffect,useRef } from 'react'
import "react-color-palette/css";
import { Form, Tabs, Tab } from 'react-bootstrap'
import RangeSlider from '../form/Rangeslider';
import labelCSS from '../../helpers/labelCss'
import ColorComp from '../form/ColorComp';
import uploadHelper from '../../helpers/uploadHelper';
import LoaderComp from '../LoaderComp';
import { MEDIALARGE, MEDIAMEDIUM, MEDIASMALL, TRANSCOLOR, TRANSCOLORRGBA } from '../../variable';
import { getActionId, saveActionId } from '../../helpers/storageHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import tinycolor from 'tinycolor2';
import rgbToHex from '../../helpers/rgbToHexHelper';
import mediaQuery from '../../helpers/mediaQueryHelper';
import ButtonSpaceComp from '../ButtonSpaceComp';
import { convertColor } from '../../helpers/colorHelper';
import InputComp from '../form/InputComp';
import InputUploadComp from '../form/Input/InputUploadComp';
const RowView = ({ data, changeRowData, widthDevice, colomCBParent, colomRemoveCBParent }) => {
    if (data.linear === undefined) {
        data.linear = "solid"
    }
    if (data?.cover?.backgroundColor) {
        let tempBgColor = data?.cover?.backgroundColor.split(",")
        if (tempBgColor) {
            data.cover.opacity = tempBgColor[3]?.replace(/\D/g, "");
        } else {
            data.cover.opacity = 1
        }
    }
    if (data?.flexDirection) {
        data.flexDirection = "row"
    }

    const [autofocus,setAutoFocus] = useState(false)
    const currentMenu = useRef('')
    const listOverflow = ['hidden', 'unset']
    const listFlexDirection = ['column', 'row']
    const listBackground = ['color', 'image', 'slide']
    const listContainer = ['container', 'full', 'container-fluid']
    const listGradient = ['solid', 'linear']
    const listBgType = ['cover', 'repeat']
    const listBooleanText = ['yes', 'no']
    const listPosition = ['static', 'fixed']
    const listFixedPosition = ['top', 'bottom']
    const listBoolean = ["false", "true"]
    const [rowData, setRowData] = useState(data)
    const [masterData, setMasterData] = useState()
    const [isUploading, setIsUploading] = useState(false)
    const [openFixed, setOpenFixed] = useState(data.position === "fixed" ? true : false)
    const [key, setKey] = useState('main')
    const [changeNow, setChangeNow] = useState(false)
    const [showColor, setShowColor] = useState(false)
    const [init, setInit] = useState(true)
    const [showOption, setShowOption] = useState(true)
    const [isReady, setIsReady] = useState(false)

    const cbValue = (data) => {
        if (widthDevice === MEDIALARGE) {
            let key = labelCSS(data.key)
            let value = data.value
            let extension = key === "width" ? "%" : "rem"
            if (data.key === "TextAlign" || data.key === "opacity" || data.key === "flexDirection") {
                extension = ""
                if (data.key === "opacity") {
                    value = value / 10
                }
            }
            if (key === "opacity") {
                if (rowData.linear === "linear") {
                    if (rowData?.cover?.backgroundColor?.trim() !== "" && rowData?.cover?.backgroundColor !== TRANSCOLOR && rowData?.cover2?.backgroundColor?.trim() !== "" && rowData?.cover2?.backgroundColor !== TRANSCOLOR) {
                        let bgColor = TRANSCOLOR
                        if (rowData.cover.backgroundColor) {
                            bgColor = convertColor(rowData.cover.backgroundColor, value)
                        }
                        let bgColor2 = TRANSCOLOR
                        if (rowData?.cover2?.backgroundColor) {
                            bgColor2 = convertColor(rowData.cover2.backgroundColor, value)
                        }
                        setRowData(prev => {
                            return {
                                ...prev,
                                [key]: value + extension,
                                cover: {
                                    backgroundColor: bgColor
                                },
                                cover2: {
                                    backgroundColor: bgColor2
                                }
                            }
                        })
                    } else {
                        if (rowData?.cover?.backgroundColor?.trim() !== "" && rowData?.cover?.backgroundColor !== TRANSCOLOR) {
                            let bgColor = convertColor(rowData.cover.backgroundColor, value)
                            setRowData(prev => {
                                return {
                                    ...prev,
                                    [key]: value + extension,
                                    cover: {
                                        backgroundColor: bgColor
                                    },
                                    cover2: {
                                        backgroundColor: TRANSCOLOR
                                    }
                                }
                            })
                        } else {
                            if (rowData?.cover2?.backgroundColor?.trim() !== "" && rowData?.cover2?.backgroundColor !== TRANSCOLOR) {
                                let bgColor = convertColor(rowData.cover2.backgroundColor, value)
                                setRowData(prev => {
                                    return {
                                        ...prev,
                                        [key]: value + extension,
                                        cover: {
                                            backgroundColor: TRANSCOLOR
                                        },
                                        cover2: {
                                            backgroundColor: bgColor
                                        }
                                    }
                                })
                            }
                        }

                    }
                } else {
                    if (rowData?.cover?.backgroundColor !== TRANSCOLOR && rowData?.cover?.backgroundColor?.trim() !== "") {
                        let bgColor = convertColor(rowData.cover.backgroundColor, value)
                        setRowData(prev => {
                            return {
                                ...prev,
                                [key]: value + extension,
                                cover: {
                                    backgroundColor: bgColor
                                }
                            }
                        })
                    }

                }

            } else {
                setRowData(prev => {
                    return {
                        ...prev,
                        [key]: value + extension
                    }
                })
            }

            if (key === "width") {
                setRowData(prev => {
                    return {
                        ...prev,
                        "width": value,
                        "horizontalspace": 12
                    }
                })
            }
            if (init === false) {
                setChangeNow(true)
            }
        }

        if (widthDevice === MEDIAMEDIUM) {
            console.log("data", data)
            let key = labelCSS(data.key)

            let value = data.value

            if (rowData.mediaQueri768) {
                let temp = { ...rowData }
                let temp768 = temp.mediaQueri768
                let newObject = {
                    [key]: value
                }
                let newJoin = { ...temp768, ...newObject }
                temp.mediaQueri768 = newJoin
                console.log("newJoin", newJoin)
                setRowData(temp)
                if (init === false) {
                    setChangeNow(true)
                }
            } else {
                let tempRowData = { ...rowData }
                tempRowData.mediaQueri768 = {
                    [key]: value
                }
                setRowData(tempRowData)
                if (init === false) {
                    setChangeNow(true)
                }
            }

        }
        if (widthDevice === MEDIASMALL) {
            let key = labelCSS(data.key)
            let value = data.value
            if (rowData.mediaQueri360) {
                let temp = { ...rowData }
                let temp360 = temp.mediaQueri360
                let newObject = {
                    [key]: value
                }
                let newJoin = { ...temp360, ...newObject }
                temp.mediaQueri360 = newJoin
                setRowData(temp)
            } else {
                let tempRowData = { ...rowData }
                tempRowData.mediaQueri360 = {
                    [key]: value
                }
                setRowData(tempRowData)
            }
        }
    }


    const visualRow = (tempState, key, widthDevice, value) => {
        switch (widthDevice) {
            case MEDIALARGE:
                if (key === "flexDirection") {
                    if (value) {
                        return "row"
                    } else {
                        return "row"
                    }
                } else {
                    return value;
                }

            case MEDIAMEDIUM:
                if (tempState.mediaQueri768 && tempState.mediaQueri768[key]) {
                    console.log("tempState.mediaQueri768", tempState.mediaQueri768)
                    return tempState.mediaQueri768[key]
                } else {
                    return value
                }
            case MEDIASMALL:
                if (tempState.mediaQueri360 && tempState.mediaQueri360[key]) {
                    return tempState.mediaQueri360[key]
                } else {
                    if (tempState.mediaQueri768 && tempState.mediaQueri768[key]) {
                        return tempState.mediaQueri768[key]
                    } else {
                        return value
                    }
                }
        }
    }

    useEffect(() => {
        setIsReady(false)
        let tempState = { ...masterData }
        if (tempState.flexDirection === undefined) {
            tempState.flexDirection = "row"
        }
        tempState.flexDirection = visualRow(tempState, "flexDirection", widthDevice, tempState.flexDirection)
        tempState.marginTop = visualRow(tempState, "marginTop", widthDevice, tempState.marginTop)
        tempState.marginLeft = visualRow(tempState, "marginLeft", widthDevice, tempState.marginLeft)
        tempState.marginRight = visualRow(tempState, "marginRight", widthDevice, tempState.marginRight)
        tempState.marginBottom = visualRow(tempState, "marginBottom", widthDevice, tempState.marginBottom)
        tempState.paddingTop = visualRow(tempState, "paddingTop", widthDevice, tempState.paddingTop)
        tempState.paddingRight = visualRow(tempState, "paddingRight", widthDevice, tempState.paddingRight)
        tempState.paddingBottom = visualRow(tempState, "paddingBottom", widthDevice, tempState.paddingBottom)
        tempState.paddingLeft = visualRow(tempState, "paddingLeft", widthDevice, tempState.paddingLeft)
        tempState.borderRadius = visualRow(tempState, "borderRadius", widthDevice, tempState.borderRadius)
        tempState.backgroundColor = visualRow(tempState, "backgroundColor", widthDevice, tempState.backgroundColor)
        tempState.backgroundSize = visualRow(tempState, "backgroundSize", widthDevice, tempState.backgroundSize)
        tempState.opacity = visualRow(tempState, "opacity", widthDevice, tempState.opacity)
        setMasterData(tempState)
        setTimeout(() => {
            setIsReady(true)
        }, [500])
    }, [widthDevice])


    const [foreground, setForgound] = useState(data?.cover !== "" ? "yes" : "no");
    const [background, setBackground] = useState(data?.backgroundType);

    const uploadfile = async (event, type) => {
        try {
            // setShowOption(false)
            setIsUploading(true)
            let result = await uploadHelper(event)
            if (result) {
                setRowData(prev => {
                    return {
                        ...prev,
                        backgroundImage: result[0].widthImage,
                        backgroundColor: '',
                        backgroundSize: 'cover'
                    }
                })

            }
            setIsUploading(false)
            setShowOption(true)
            setIsUploading(false)
        } catch {
            alert("file tidak compatible")
            setIsUploading(false)
            setShowOption(true)
        }
    }


    const setCover = (value) => {
        setRowData(prev => {
            return {
                ...prev,
                cover: {
                    backgroundColor: `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${rowData.opacity})`,
                    rgb: `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}`,
                }
            }
        })
    }
    const setCover2 = (value) => {
        setRowData(prev => {
            return {
                ...prev,
                cover2: {
                    backgroundColor: `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${rowData.opacity})`,
                    rgb: `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}`,
                }
            }
        })
    }

    const setCoverOpacity = (value) => {
        if (rowData?.cover?.rgb) {
            let opacity = value.value / 10
            if (rowData?.cover2?.backgroundColor) {
                setRowData(prev => {
                    return {
                        ...prev,
                        cover2: {
                            opacity: opacity,
                            backgroundColor: `${rowData?.cover2?.rgb},${opacity})`,
                            rgb: rowData?.cover2?.rgb
                        },
                        cover: {
                            opacity: opacity,
                            backgroundColor: `${rowData?.cover?.rgb},${opacity})`,
                            rgb: rowData?.cover?.rgb
                        }
                    }
                })
            } else {
                setRowData(prev => {
                    return {
                        ...prev,
                        cover: {
                            opacity: opacity,
                            backgroundColor: `${rowData?.cover?.rgb},${opacity})`,
                            rgb: rowData?.cover?.rgb
                        }
                    }
                })
            }
        } else {
            setRowData(prev => {
                return {
                    ...prev,
                    cover: {
                        backgroundColor: rowData?.cover?.backgroundColor ? rowData?.cover?.backgroundColor : "#fff",
                        opacity: value
                    },
                    cover2: {
                        backgroundColor: rowData?.cover2?.backgroundColor ? rowData?.cover2?.backgroundColor : rowData?.cover?.backgroundColor,
                        opacity: value
                    }
                }
            })
        }
    }

    const changeForground = (value) => {
        if (value === "yes" || value === true) {
            setShowColor(true)
            setRowData(prev => {
                return {
                    ...prev,
                    cover: {
                        backgroundColor: TRANSCOLOR,
                        opacity: 1
                    }
                }
            })
        } else {
            setShowColor(false)
            setRowData(prev => {
                return {
                    ...prev,
                    cover: ""
                }
            })
        }
        setTimeout(() => {
            setForgound(value)
        }, 1000)
    }

    const changeContainer = (value) => {
        setRowData(prev => {
            return {
                ...prev,
                container: value
            }
        })
    }


    const changeLock = (value) => {
        if (value === "true") {
            value = true
        } else {
            value = false
        }
        setRowData(prev => {
            return {
                ...prev,
                islock: value
            }
        })
    }
    const setBgColortoBackground = (level, value) => {
        if (level === "primary") {
            setRowData(prev => {
                return {
                    ...prev,
                    backgroundColor: value.hex,
                    backgroundImage: ''
                }
            })
        } else {
            setRowData(prev => {
                return {
                    ...prev,
                    backgroundColor2: value.hex,
                    backgroundImage: ''
                }
            })
        }
    }





    const changeRowName = (value) => {
        setRowData(prev => {
            return {
                ...prev,
                tag: value
            }
        })
    }

    const changeBackgroundSize = (value) => {
        setRowData(prev => {
            return {
                ...prev,
                backgroundSize: value
            }
        })
    }

    const noColorBG = (level) => {
        if (level === "primary") {
            setRowData(prev => {
                return {
                    ...prev,
                    backgroundColor: TRANSCOLOR
                }
            })
        } else {
            setRowData(prev => {
                return {
                    ...prev,
                    backgroundColor2: TRANSCOLOR
                }
            })
        }


    }

    const changeFixedPosition = (value) => {
        setRowData(prev => {
            return {
                ...prev,
                fixedTo: value,
                newPosition: true
            }
        })
    }

    const changePosition = (value) => {
        setOpenFixed(value === "fixed" ? true : false)
        if (value === "fixed") {

            setRowData(prev => {
                return {
                    ...prev,
                    position: value,
                    fixedTo: "top",

                }
            })
        } else {
            setRowData(prev => {
                return {
                    ...prev,
                    position: value,

                }
            })
        }

    }

    const changeOverflow = (value) => {
        setRowData(prev => {
            return {
                ...prev,
                overflow: value,
            }
        })
    }

    const changeLinear = (value) => {
        setRowData(prev => {
            return {
                ...prev,
                linear: value,
            }
        })
    }

    const changeBackground = (value) => {
        if (value === "color") {
            setRowData(prev => {
                return {
                    ...prev,
                    backgroundType: value,
                    cover: "",
                    backgroundImage: ""
                }
            })
        } else if (value === "slide") {
            setRowData(prev => {
                return {
                    ...prev,
                    backgroundType: value,
                    cover: "",
                    backgroundImage: ""
                }
            })
        } else {
            setRowData(prev => {
                return {
                    ...prev,
                    backgroundType: value,
                    cover: "",
                }
            })
        }
        setForgound("no")
        setBackground(value)
    }

    useEffect(() => {
        if (rowData.backgroundType === undefined) {
            let tempRowData = { ...rowData }
            if (rowData.backgroundImage !== "") {
                tempRowData.backgroundType = "image"
                setBackground("image")
            }
            if (rowData.backgroundColor !== "") {
                tempRowData.backgroundType = "color"
                setBackground("color")
            }
            setShowOption(true)
        }
        if (changeNow) {
            changeRowData(rowData)
        }
        console.log("masterdaaaa", masterData)
        setMasterData(rowData)
    }, [rowData])


    useEffect(() => {
        setTimeout(() => {
            setIsReady(true)
        }, 1000)
    }, [])


    useEffect(() => {
        if (data) {
            let showFG = "no"
            if (data?.cover !== "") {
                showFG = "yes"
            }
            changeForground(showFG)
        }
    }, [data])


    useEffect(() => {
        if (foreground === "no") {
            setShowColor(false)
            setRowData(prev => {
                return {
                    ...prev,
                    cover: ""
                }
            })
        } else {
            setShowColor(true)
            if (data.linear === "solid") {
                setRowData(prev => {
                    return {
                        ...prev,
                        cover: {
                            backgroundColor: data?.cover?.backgroundColor ? data?.cover?.backgroundColor : "#fff",
                            opacity: data?.cover?.opacity ? data?.cover?.opacity : 1
                        }
                    }
                })
            } else {
                setRowData(prev => {
                    return {
                        ...prev,
                        cover: {
                            backgroundColor: data?.cover?.backgroundColor ? data?.cover?.backgroundColor : "#fff",
                            opacity: data?.cover?.opacity ? data?.cover?.opacity : 1
                        },
                        cover2: {
                            backgroundColor: data?.cover2?.backgroundColor ? data?.cover2?.backgroundColor : "#fff",
                            opacity: data?.cover2?.opacity ? data?.cover2?.opacity : 1
                        }
                    }
                })
            }
        }
    }, [foreground])

    setTimeout(() => {
        setChangeNow(true)
    }, 10)

    const uploadfileMultiple = async (event, type) => {
        try {
            setIsUploading(true)
            var datatemp = { ...rowData }
            if (datatemp.image_slide.length > 0 && datatemp.image_slide[0].img === "https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg") {
                var tempImages = []
            } else {
                var tempImages = datatemp.image_slide

            }
            let result = await uploadHelper(event, event.target.files.length)
            if (result) {
                for (let a = 0; a < result.length; a++) {
                    tempImages.push({
                        img: result[a].widthImage
                    })
                }
                setRowData(prev => {
                    return {
                        ...prev,
                        image_slide: tempImages,
                    }
                })
            }
            setIsUploading(false)
        } catch {
            alert("gagal upload file tidak compatible")
            setIsUploading(false)
        }
    }

    const removeImage = (index) => {
        var datatemp = { ...rowData }
        if (datatemp.image_slide.length > 1) {
            datatemp.image_slide.splice(index, 1)
            datatemp.images378.splice(index, 1)
            datatemp.images678.splice(index, 1)
            setRowData(datatemp)
        }
    }


    const noCoverColorBG = (level) => {
        let tempdata = { ...rowData }
        if (level === "primary") {
            tempdata.cover.backgroundColor = TRANSCOLOR
            tempdata.cover.rgb = ""
        } else {
            tempdata.cover2.backgroundColor = TRANSCOLOR
            tempdata.cover2.rgb = ""
        }
        setRowData(tempdata)
    }


    const chjange = (v) => {
        cbValue(v)

    }

    const ColorView = () => {
        return <>
            <InputComp
                type="select"
                label="Linear"
                value={rowData.overflow}
                options={listGradient}
                onChange={changeLinear}
            />
            <ColorComp
                label="Background"
                colorCB={(value) => setBgColortoBackground("primary", value)}
                primaryColor={rowData.backgroundColor}
                showNoColor={true}
                noColor={() => noColorBG("primary")}>
            </ColorComp>
            {rowData.linear === "linear" && <>
                <ColorComp
                    label="Background"
                    colorCB={(value) => setBgColortoBackground("secondary", value)}
                    primaryColor={rowData.backgroundColor2}
                    showNoColor={true}
                    noColor={() => noColorBG("secondary")}>
                </ColorComp>
            </>}
        </>
    }

    const ImageView = () => {
        return <>
            <Form.Group controlId="name" className='form-control-dark'>
                {/* <Form.Label><span style={{ color: 'red' }}>*</span>Background</Form.Label> */}

               



      <InputUploadComp
                    id="custom-file"
                    label="Custom file input"
                    name="input" 
                    type="file" className="file" data-show-upload="false" data-show-caption="true" custom 
                    onChange={($e) => { uploadfile($e, "address") }}
                />
            </Form.Group>

            {isUploading ?
                <LoaderComp></LoaderComp>
                : <> {rowData.backgroundImage && <img src={rowData.backgroundImage} width="100%" height="200px" />}</>}
            <InputComp
                type="select"
                label="Background Type"
                value={rowData.backgroundSize}
                options={listBgType}
                onChange={changeBackgroundSize}
            />
            <InputComp
                type="select"
                label="Foreground"
                value={foreground}
                options={listBooleanText}
                onChange={changeForground}
            />
        </>
    }

    const onClickCB = (inputValue) => {
        currentMenu.current = inputValue.name
        if(currentMenu.current==="tag"){
            setAutoFocus(true)
        } 
    }

    const onBlur = (inputValue) => {
        if(inputValue.name==="tag"){
            setAutoFocus(false)
        }
    }


    return (<div style={{ textAlign: 'left' }}>
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="main" title="Main">
                {isReady &&
                    <div style={{ position: 'relative' }}>
                        <InputComp
                            isRequired={true}
                            type="text"
                            label="Tag Name"
                            value={rowData.tag}
                            onChange={changeRowName}
                            placeholder="Tag Name"
                            autofocus={autofocus}
                            onClick={onClickCB}
                            onBlur={onBlur}
                            name="tag"
                        />
                        <InputComp
                            isRequired={true}
                            type="select"
                            label="Container"
                            value={rowData.container}
                            options={listContainer}
                            onChange={changeContainer}
                        />
                        <InputComp
                            isRequired={true}
                            type="select"
                            label="Background"
                            value={rowData.backgroundType}
                            options={listBackground}
                            onChange={changeBackground}
                        />
                        <InputComp
                            isRequired={true}
                            type="select"
                            label="Direction"
                            value={masterData.flexDirection}
                            options={listFlexDirection}
                            onChange={(value) => {
                                chjange({
                                    key: 'flexDirection',
                                    value: value
                                })
                            }}
                        />
                        {/* <Form.Group controlId="name">
                                    {masterData.flexDirection}
                                    <Form.Label><span style={{ color: 'red' }}>*</span>Direction</Form.Label>
                                    <Form.Control as="select" onChange={($e) => chjange({
                                        key: 'flexDirection',
                                        value: $e.target.value
                                    })} className='form-control-dark'>
                                        <option value="row" selected={masterData.flexDirection === "row"}>Row {masterData.flexDirection}</option>
                                        <option value="column" selected={masterData.flexDirection === "column"}>Column {masterData.flexDirection}</option>
                                    </Form.Control>
                                </Form.Group> */}
                        <InputComp
                            type="select"
                            label="Overflow"
                            value={rowData.overflow}
                            options={listOverflow}
                            onChange={changeOverflow}
                        />
                        {showOption && background === "color" && <>
                            <ColorView></ColorView>
                        </>}
                        {showOption && background === "image" && <>
                            <ImageView></ImageView>
                            {
                                foreground === "yes" && <>
                                    <InputComp
                                        type="select"
                                        label="Linear"
                                        value={rowData.linear}
                                        options={listGradient}
                                        onChange={changeLinear}
                                    />
                                    {showColor && <ColorComp label="Color" colorCB={setCover} primaryColor={tinycolor(rowData?.cover?.backgroundColor).toHexString()} showNoColor={true} noColor={() => noCoverColorBG("primary")}></ColorComp>}
                                    {showColor && rowData.linear === "linear" && <ColorComp label="Color" colorCB={setCover2} primaryColor={tinycolor(rowData?.cover2?.backgroundColor).toHexString()} showNoColor={true} noColor={() => noCoverColorBG("secondary")}></ColorComp>}
                                    {showColor && <RangeSlider value={rowData.opacity} label="opacity" cbValue={cbValue} maxvalue="10"></RangeSlider>}
                                </>
                            }
                        </>}

                        {background === "slide" && <>
                            <Form.Group controlId="name">
                                <Form.Label><span style={{ color: 'red' }}>*</span> Gambar Produk</Form.Label>
                                <InputUploadComp
                                    id="custom-file"
                                    label="Custom file input"
                                    name="input2[]" type="file" className="file" data-show-upload="false" data-show-caption="true" custom multiple
                                    onChange={($e) => { uploadfileMultiple($e) }}
                                />
                                <div>
                                    <i>support jpg/png max 5mb</i>
                                </div>
                                {isUploading && <LoaderComp></LoaderComp>}

                                {rowData.image_slide && rowData.image_slide.map((img, index) => {
                                    return (
                                        <div style={{ display: 'flex' }} key={index}>
                                            <div style={{ flex: 1, marginBottom: '10px' }}>
                                                <img src={img.img} className='img-fluid' />
                                                {rowData.image_slide.length > 1 && <div style={{ width: '100%', height: '20px', background: '#f5f5f5', zIndex: 1, textAlign: 'center', cursor: 'pointer' }} onClick={() => removeImage(index)}>
                                                    <i className='fas fa-times' style={{ zIndex: 100, fontSize: 16, color: "red", cursor: 'pointer' }} ></i>
                                                </div>}
                                            </div>
                                        </div>
                                    )
                                })}
                            </Form.Group>
                            <InputComp
                                type="select"
                                label="Foreground"
                                value={foreground}
                                options={listBooleanText}
                                onChange={changeForground}
                            />
                            {
                                foreground === "yes" && <>
                                    <InputComp
                                        type="select"
                                        label="Linear"
                                        value={rowData.linear}
                                        options={listGradient}
                                        onChange={changeLinear}
                                    />
                                    {showColor && <ColorComp label="Color" colorCB={setCover} primaryColor={rgbToHex(rowData?.cover?.backgroundColor)} showNoColor={true} noColor={() => noCoverColorBG("primary")}></ColorComp>}
                                    {showColor && rowData.linear === "linear" && <ColorComp label="Color" colorCB={setCover2} primaryColor={rgbToHex(rowData?.cover2?.backgroundColor)} showNoColor={true} noColor={() => noCoverColorBG("secondary")}></ColorComp>}
                                    {showColor && <RangeSlider value={rowData?.cover?.opacity} label="opacity" cbValue={setCoverOpacity} maxvalue="10"></RangeSlider>}
                                </>
                            }
                        </>}
                        {rowData.index === 0 && <InputComp
                            type="select"
                            label="Lock"
                            value={rowData.islock === true ? "true" : "false"}
                            options={listBoolean}
                            onChange={changeLock}
                        />
                        }
                        {background !== "slide" && <>
                            <InputComp
                                type="select"
                                label="Position"
                                value={data.position}
                                options={listPosition}
                                onChange={changePosition}
                            />
                        </>}
                        {background !== "slide" && openFixed === true &&
                            <InputComp
                                type="select"
                                label="Fixed"
                                value={data.fixedTo}
                                options={listFixedPosition}
                                onChange={changeFixedPosition}
                            />}
                        {openFixed === true &&
                            <RangeSlider value={rowData.width} label="width" cbValue={cbValue}></RangeSlider>
                        }
                    </div>
                }
                <ButtonSpaceComp></ButtonSpaceComp>
            </Tab>
            <Tab eventKey="setting" title="Setting">
                {isReady && <>
                    <RangeSlider value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={masterData.marginTop} label="Margin Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={masterData.marginRight} label="Margin Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={masterData.marginBottom} label="Margin Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={masterData.marginLeft} label="Margin Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={masterData.paddingTop} label="Padding Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={masterData.paddingRight} label="Padding Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={masterData.paddingBottom} label="Padding Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={masterData.paddingLeft} label="Padding Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <ButtonSpaceComp></ButtonSpaceComp>
                </>}
            </Tab>
            <Tab eventKey="menu" title="Layers">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {rowData?.col && rowData?.col.map((c, colIndex) => {
                        return c?.content && c?.content.map((content, ColContentIndex) => {
                            if (content.type === "Image") {
                                return <div style={{ flex: 1, }}>
                                    <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                                        <div style={{ width: '40px', overflow: 'hidden' }}>
                                            <img src={content.image} width="40px" style={{
                                                transform: `rotate(${mediaQuery(content, 'rotate', content.rotate, widthDevice)}deg)`,
                                            }} />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ flex: 1 }}></div>
                                                <div style={{ width: '80px' }}>
                                                    <FontAwesomeIcon style={{ background: '#000', color: '#fff', padding: '10px', borderRadius: '4px', marginRight: '10px', cursor: 'pointer' }} icon={faPen} onClick={async () => {
                                                        let actionId = await getActionId()
                                                        if (actionId) {
                                                            if (document.getElementById(actionId)) {
                                                                document.getElementById(actionId).style.display = "none"
                                                            }
                                                        }
                                                        setTimeout(() => {
                                                            let actionIdNow = `actionbutton-${content.id}`
                                                            if (document.getElementById(actionIdNow)) {
                                                                document.getElementById(actionIdNow).style.display = "flex"
                                                                saveActionId(actionIdNow)
                                                            }
                                                        }, 1000)

                                                        setTimeout(() => {
                                                            content.id = `${rowData.index}-${colIndex}-${ColContentIndex}`
                                                            colomCBParent(content)
                                                        }, 1200)
                                                    }} />
                                                    <FontAwesomeIcon icon={faTrash} style={{ background: 'red', color: '#fff', padding: '10px', borderRadius: '4px', cursor: 'pointer' }} onClick={() => {
                                                        content.id = `${rowData.index}-${colIndex}-${ColContentIndex}`
                                                        colomRemoveCBParent(content)
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            if (content.type === "column") {
                                return content?.content && content?.content.map((c, ColContentColIndex) => {
                                    return c?.content && c?.content.map((lastContent, ColContentColContentIndex) => {
                                        if (lastContent.type === "Image") {
                                            return <div style={{ flex: 1, }}>
                                                <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                                                    <div style={{ width: '40px', overflow: 'hidden' }}>
                                                        <img src={lastContent.image} width="40px" style={{
                                                            transform: `rotate(${mediaQuery(lastContent, 'rotate', lastContent.rotate, widthDevice)}deg)`,
                                                        }} />
                                                    </div>
                                                    <div style={{ flex: 1 }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ flex: 1 }}></div>
                                                            <div style={{ width: '80px' }}>
                                                                <FontAwesomeIcon style={{ background: '#000', color: '#fff', padding: '10px', borderRadius: '4px', marginRight: '10px', cursor: 'pointer' }} icon={faPen} onClick={async () => {
                                                                    let actionId = await getActionId()
                                                                    if (actionId) {
                                                                        if (document.getElementById(actionId)) {
                                                                            document.getElementById(actionId).style.display = "none"
                                                                        }
                                                                    }
                                                                    setTimeout(() => {
                                                                        let actionIdNow = `actionbutton-${lastContent.id}`
                                                                        if (document.getElementById(actionIdNow)) {
                                                                            document.getElementById(actionIdNow).style.display = "flex"
                                                                            saveActionId(actionIdNow)
                                                                        }
                                                                    }, 100)

                                                                    setTimeout(() => {
                                                                        lastContent.id = `${rowData.index}-${colIndex}-${ColContentIndex}-${ColContentColIndex}-${ColContentColContentIndex}`
                                                                        colomCBParent(lastContent)
                                                                    }, 200)
                                                                }} />
                                                                <FontAwesomeIcon icon={faTrash} style={{ background: 'red', color: '#fff', padding: '10px', borderRadius: '4px', cursor: 'pointer' }} onClick={() => {
                                                                    lastContent.id = `${rowData.index}-${colIndex}-${ColContentIndex}-${ColContentColIndex}-${ColContentColContentIndex}`
                                                                    colomRemoveCBParent(lastContent)
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })
                                })
                            }

                        })
                    })}
                </div>
                <ButtonSpaceComp></ButtonSpaceComp>
            </Tab>
        </Tabs>
    </div>)
}

export default RowView
