import React, { useState, useEffect } from 'react'
import RangeSlider from '../form/Rangeslider';
import labelCSS from '../../helpers/labelCss'
import { Form, Row, Col, Tabs, Tab } from 'react-bootstrap'
import TextAlign from '../form/TextAlign';
import AnimationComp from '../form/AnimationComp';
import { MEDIALARGE, MEDIAMEDIUM, MEDIAQUERY, MEDIASMALL, SIZEMEDIUM, SIZESMALL } from '../../variable';
import mediaQuery from '../../helpers/mediaQueryHelper';
const VideoView = ({ data, ViewCB, widthDevice }) => {
    const delay = 1;
    let desc
    const [key, setKey] = useState('main')
    const [colData, setColData] = useState(data)
    const [isReady, setIsReady] = useState(false)
    const [masterData, setMasterData] = useState(data)
    const [widthDevice2, setWidthDevice2] = useState(widthDevice)
    const cbValue = (data) => {
        let key = labelCSS(data.key)
        let value = data.value
        let extension = key === "width" ? "%" : "rem"
        if (data.key === "textAlign") {
            extension = ""
        }

        if (widthDevice === MEDIALARGE) {
            setColData(prev => {
                return {
                    ...prev,
                    [key]: value + extension
                }
            })
        }
        if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
            let widthDeviceStr = SIZEMEDIUM
            if (widthDevice === MEDIASMALL) {
                widthDeviceStr = SIZESMALL
            }
            let temp = { ...colData }
            if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
                let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`]
                let newObject = {
                    [key]: value
                }
                let newJoin = { ...tempNewObject, ...newObject }
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin
                setColData(temp)

            } else {
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
                    [key]: value
                }
                setColData(temp)
            }
        }
    }
    const setYoutubeUrl = (value) => {
        setColData(prev => {
            return {
                ...prev,
                youtube_url: value
            }
        })

    }


    useEffect(() => {
        let desc = setTimeout(() => {
            if (colData) {
                ViewCB(colData)
                setMasterData(colData)
            }
        }, delay * 1000);

        // this will clear Timeout
        // when component unmount like in willComponentUnmount
        // and show will not change to true
        return () => {
            clearTimeout(desc);
        };
    },
        // useEffect will run only one time with empty []
        // if you pass a value to array,
        // like this - [data]
        // than clearTimeout will run every time
        // this value changes (useEffect re-run)
        [colData]);

    const cbAnimation = (value) => {
        setColData(prev => {
            return {
                ...prev,
                animation: value
            }
        })
    }


    const calculateSetting = () => {
        setIsReady(false)
        let tempState = { ...masterData }
        tempState.marginTop = mediaQuery(tempState, "marginTop", tempState.marginTop, widthDevice)
        tempState.marginLeft = mediaQuery(tempState, "marginLeft", tempState.marginLeft, widthDevice)
        tempState.marginRight = mediaQuery(tempState, "marginRight", tempState.marginRight, widthDevice)
        tempState.marginBottom = mediaQuery(tempState, "marginBottom", tempState.marginBottom, widthDevice)
        tempState.paddingTop = mediaQuery(tempState, "paddingTop", tempState.paddingTop, widthDevice)
        tempState.paddingRight = mediaQuery(tempState, "paddingRight", tempState.paddingRight, widthDevice)
        tempState.paddingBottom = mediaQuery(tempState, "paddingBottom", tempState.paddingBottom, widthDevice)
        tempState.paddingLeft = mediaQuery(tempState, "paddingLeft", tempState.paddingLeft, widthDevice)
        tempState.textAlign = mediaQuery(tempState, "textAlign", tempState.textAlign, widthDevice)
        setMasterData(tempState)
        setTimeout(() => {
            setIsReady(true)
        }, 500)
    }


    useEffect(() => {
        setIsReady(false)
        setTimeout(() => {
            calculateSetting(widthDevice2)
        }, 500)
    }, [widthDevice2])


    useEffect(() => {
        if (widthDevice !== widthDevice2) {
            setWidthDevice2(widthDevice)
        }
    }, [widthDevice])



    return (
        <div style={{ textAlign: 'left' }}>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="main" title="main">
                    {isReady && <>
                        <Form.Group controlId="name">
                            <Form.Label><span style={{ color: 'red' }}>*</span>Youtube Url</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control type='text' placeholder='' value={colData.youtube_url} onChange={($e) => setYoutubeUrl($e.target.value)} className='form-control-dark'></Form.Control>
                                </Col>
                            </Row>
                        </Form.Group>
                        <TextAlign value={masterData.textAlign} label="Text Align" cbValue={cbValue}></TextAlign>
                    </>}
                </Tab>
                <Tab eventKey="setting" title="setting">
                    {isReady && <>
                        <RangeSlider value={masterData.marginTop} label="Margin Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginRight} label="Margin Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginBottom} label="Margin Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginLeft} label="Margin Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingTop} label="Padding Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingRight} label="Padding Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingBottom} label="Padding Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingLeft} label="Padding Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    </>}
                </Tab>
                <Tab eventKey="animation" title="animasi" >
                    <AnimationComp value={colData.animation} cbValue={cbAnimation}></AnimationComp>
                </Tab>
            </Tabs>
        </div>
    )
}

export default VideoView
