import React, { useState, useEffect } from 'react'
import RangeSlider from '../form/Rangeslider';
import labelCSS from '../../helpers/labelCss'
import { Form, Tabs, Tab } from 'react-bootstrap'
import TextAlign from '../form/TextAlign';
import AnimationComp from '../form/AnimationComp';
import ImageShape from '../form/ImageShapeComp';
import InputPositionComp from '../form/InputPositionComp';
import uploadHelper from '../../helpers/uploadHelper';
import LoaderComp from '../LoaderComp';
import InputComp from '../form/InputComp';
import RotateComp from '../form/RotateComp';
import mediaQuery, { clearBorderRadius, isAdvancedRadius } from '../../helpers/mediaQueryHelper';
import { MEDIALARGE, MEDIAMEDIUM, MEDIAQUERY, MEDIASMALL, SIZEMEDIUM, SIZESMALL } from '../../variable';
import BorderRadiusComp from '../form/BorderRadiusComp';
import ButtonSpaceComp from '../ButtonSpaceComp';
const ImageView = ({ data, ViewCB, widthDevice }) => {
    
    const [isBorderRadiusAdvanced, setIsBorderRadiusAdvanced] = useState(isAdvancedRadius(data))
    const [key, setKey] = useState('main')
    const [isAbsolute,setIsAbsolute] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [colData, setColData] = useState(data)
    const [isReady, setIsReady] = useState(false)
    const [masterData, setMasterData] = useState(data)
    const [widthDevice2, setWidthDevice2] = useState(widthDevice)
    const cbAnimation = (value) => {
        setColData(prev => {
            return {
                ...prev,
                animation: value
            }
        })
    }

    const cbValue = (s_data, isGlobal) => {
        let key = labelCSS(s_data.key)
        let value = s_data.value
        let extension = key === "width" ? "%" : "px"
        if (s_data.key === "textAlign" || s_data.key === "shape" || s_data.key === "zIndex" || s_data.key === "rotate" || s_data.key === "positionAbsolute") {
            extension = ""
        }
        if (widthDevice === MEDIALARGE || isGlobal) {
            setColData(prev => {
                return {
                    ...prev,
                    [key]: value + extension
                }
            })
        } else {
            if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
                let widthDeviceStr = SIZEMEDIUM
                if (widthDevice === MEDIASMALL) {
                    widthDeviceStr = SIZESMALL
                }
                let temp = { ...colData }
                if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
                    let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`]
                    let newObject = {
                        [key]: value + extension
                    }
                    let newJoin = { ...tempNewObject, ...newObject }
                    temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin
                    setColData(temp)

                } else {
                    temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
                        [key]: value + extension
                    }
                    setColData(temp)
                }
            }
        }
    }



    useEffect(() => {
        if (colData && data) {
            ViewCB(colData)
            setMasterData(colData)
            if(colData.position==="absolute"){
                setIsAbsolute(true)
            } else {
                setIsAbsolute(false)
            }
        }
    }, [colData])



    const changePosition = (value) => {
        setColData(prev => {
            return {
                ...prev,
                position: value
            }
        })

    }

    const uploadSelectedFile = async (event) => {
        event.preventDefault()
        setIsUploading(true)
        let result = await uploadHelper(event)
        if (result) {
            setColData(prev => {
                return {
                    ...prev,
                    image: result[0].widthImage,
                    image378: result[0].widthImage378,
                    image678: result[0].widthImage678
                }
            })
        }

        setTimeout(() => {
            setIsUploading(false)
        }, 200)
    }

    const positionCB = (value) => {
        if (widthDevice === MEDIALARGE) {
            setColData(prev => {
                return {
                    ...prev,
                    positionAbsolute: value
                }
            })
        }

        if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
            let widthDeviceStr = SIZEMEDIUM
            if (widthDevice === MEDIASMALL) {
                widthDeviceStr = SIZESMALL
            }
            let temp = { ...colData }
            if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
                let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`]
                let newObject = {
                    "positionAbsolute": value
                }
                let newJoin = { ...tempNewObject, ...newObject }
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin
                setColData(temp)

            } else {
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
                    "positionAbsolute": value
                }
                setColData(temp)
            }
        }

    }

    const changeLink = (value) => {
        setColData(prev => {
            return {
                ...prev,
                link: value
            }
        })
    }

    const calculateSetting = () => {
        setIsReady(false)
        let tempState = { ...masterData }
        tempState.marginTop = mediaQuery(tempState, "marginTop", tempState.marginTop, widthDevice)
        tempState.marginLeft = mediaQuery(tempState, "marginLeft", tempState.marginLeft, widthDevice)
        tempState.marginRight = mediaQuery(tempState, "marginRight", tempState.marginRight, widthDevice)
        tempState.marginBottom = mediaQuery(tempState, "marginBottom", tempState.marginBottom, widthDevice)
        tempState.paddingTop = mediaQuery(tempState, "paddingTop", tempState.paddingTop, widthDevice)
        tempState.paddingRight = mediaQuery(tempState, "paddingRight", tempState.paddingRight, widthDevice)
        tempState.paddingBottom = mediaQuery(tempState, "paddingBottom", tempState.paddingBottom, widthDevice)
        tempState.paddingLeft = mediaQuery(tempState, "paddingLeft", tempState.paddingLeft, widthDevice)
        if (isBorderRadiusAdvanced) {
            tempState.borderRadius = 0
            tempState.borderTopLeftRadius = mediaQuery(tempState, "borderTopLeftRadius", tempState.borderTopLeftRadius, widthDevice)
            tempState.borderTopRightRadius = mediaQuery(tempState, "borderTopRightRadius", tempState.borderTopRightRadius, widthDevice)
            tempState.borderBottomLeftRadius = mediaQuery(tempState, "borderBottomLeftRadius", tempState.borderBottomLeftRadius, widthDevice)
            tempState.borderBottomRightRadius = mediaQuery(tempState, "borderBottomRightRadius", tempState.borderBottomRightRadius, widthDevice)
        } else {
            tempState.borderRadius = mediaQuery(tempState, "borderRadius", tempState.borderRadius, widthDevice)
            tempState.borderTopLeftRadius = 0
            tempState.borderTopRightRadius = 0
            tempState.borderBottomLeftRadius = 0
            tempState.borderBottomRightRadius = 0
        }
        tempState.backgroundColor = mediaQuery(tempState, "backgroundColor", tempState.backgroundColor, widthDevice)
        tempState.backgroundSize = mediaQuery(tempState, "backgroundSize", tempState.backgroundSize, widthDevice)
        tempState.border = mediaQuery(tempState, "border", tempState.border, widthDevice)
        tempState.borderColor = mediaQuery(tempState, "borderColor", tempState.borderColor, widthDevice)
        tempState.positionAbsolute = mediaQuery(tempState, "positionAbsolute", tempState.positionAbsolute, widthDevice)
        tempState.textAlign = mediaQuery(tempState, "textAlign", tempState.textAlign, widthDevice)
        tempState.width = mediaQuery(tempState, "width", tempState.width, widthDevice)
        tempState.zIndex = mediaQuery(tempState, "zIndex", tempState.zIndex, widthDevice)
        tempState.rotate = mediaQuery(tempState, "rotate", tempState.rotate, widthDevice)
        tempState.position = mediaQuery(tempState, "position", tempState.position, widthDevice)
        setMasterData(tempState)
        setTimeout(() => {
            setIsReady(true)
        }, 500)
    }

    useEffect(() => {
        setIsReady(false)
        setTimeout(() => {

            calculateSetting(widthDevice2)
        }, 500)
    }, [widthDevice2])


    useEffect(() => {
        if (widthDevice !== widthDevice2) {
            setWidthDevice2(widthDevice)
        }
    }, [widthDevice])

    const boderRadiusAdvancedMode = (val) => {
        setIsBorderRadiusAdvanced(val)
        let resetRadius = clearBorderRadius(masterData)
        setColData(resetRadius)
    }



    return (
        <div style={{ textAlign: 'left' }}>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="main" title="main">
                    {isReady && <>
                        <Form.Group controlId="name">
                            <Form.Label><span style={{ color: 'red' }}>*</span> Gambar Produk</Form.Label>
                            <Form.Control
                                className='form-control-dark'
                                id="custom-file"
                                label="Custom file input"
                                name="input2[]" type="file" data-show-upload="false" data-show-caption="true" custom
                                style={{ background: 'red !important' }}
                                onChange={($e) => { uploadSelectedFile($e) }}
                            />
                            <div>
                                <i>support jpg/png max 5mb</i>
                            </div>
                            <div>
                                {isUploading ? <LoaderComp></LoaderComp> : <>{colData.image && <img src={colData.image} width="100%" style={{
                                    marginBottom: '10px',
                                    // transform: `rotate(45deg)`,
                                    transform: `rotate(${mediaQuery(colData, 'rotate', colData.rotate, widthDevice)}deg)`
                                }} />}
                                </>}
                            </div>
                        </Form.Group>
                        <RotateComp value={masterData.rotate} label="Rotate" cbValue={(data) => cbValue(data, true)}></RotateComp>
                        <TextAlign value={masterData.textAlign} label="Text Align" cbValue={cbValue}></TextAlign>
                        <ImageShape value={colData.shape} label="Shape" cbValue={cbValue}></ImageShape>
                        <Form.Group controlId="name" className="mt-2">
                            <Form.Label><span style={{ color: 'red' }}>*</span> Position</Form.Label>
                            <Form.Control as="select" onChange={($e) => changePosition($e.target.value)} className='form-control-dark'>
                                <option value="static" selected={masterData.position === "static"}>Static</option>
                                <option value="absolute" selected={masterData.position === "absolute"}>Absolute</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="name">
                            <Form.Label><span style={{ color: 'red' }}>*</span>Action</Form.Label>
                            <Form.Control type='text' placeholder='' value={masterData.link} onChange={($e) => changeLink($e.target.value)} className='form-control-dark' ></Form.Control>
                        </Form.Group>
                        {masterData.position === "absolute" && <>
                            <InputComp
                                type="number"
                                label="z index"
                                placeholder="Masukkan z-index"
                                value={masterData.zIndex}
                                isRequired={false}
                                onChange={(val) => {
                                    let key = 'zIndex'
                                    let newData = {
                                        key,
                                        value: val
                                    }
                                    cbValue(newData)
                                }}>
                            </InputComp>
                            <InputPositionComp position={masterData.positionAbsolute} positionCB={positionCB}></InputPositionComp>
                            <div className='mb-5'></div>
                        </>}


                    </>}
                    <ButtonSpaceComp></ButtonSpaceComp>
                </Tab>
                <Tab eventKey="setting" title="setting">
                    {isReady && <>
                        <BorderRadiusComp value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100" advancedValue={
                            {
                                borderTopLeftRadius: masterData.borderTopLeftRadius,
                                borderTopRightRadius: masterData.borderTopRightRadius,
                                borderBottomLeftRadius: masterData.borderBottomLeftRadius,
                                borderBottomRightRadius: masterData.borderBottomRightRadius
                            }
                        } isAdvancedMode={isBorderRadiusAdvanced} adVancedModeCB={boderRadiusAdvancedMode}></BorderRadiusComp>
                        {/* <RangeSlider value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100"></RangeSlider> */}
                        <RangeSlider value={masterData.width} label="Width" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginTop} label="Margin Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginRight} label="Margin Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginBottom} label="Margin Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginLeft} label="Margin Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingTop} label="Padding Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingRight} label="Padding Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingBottom} label="Padding Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingLeft} label="Padding Left" cbValue={cbValue} maxvalue="100"></RangeSlider>

                    </>}
                    <ButtonSpaceComp></ButtonSpaceComp>
                </Tab>
                <Tab eventKey="animation" title="animasi" >
                    <AnimationComp value={colData.animation ? colData.animation : ''} cbValue={cbAnimation} isAbsolute={isAbsolute}></AnimationComp>
                </Tab>
            </Tabs>
        </div>
    )
}

export default ImageView
