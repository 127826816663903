import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogoutServices } from "../services/userServices";
import { logoutAction } from "../redux/features/userSlices";

const LogoutPages = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { user } = useSelector((state) => state);
  const doLogout = async (value) => {
    if (user) {
      dispatch(logoutAction());
      let res = await userLogoutServices();
      setTimeout(() => {
        window.location.href = "https://app.idinvitebook.com/login";
      }, 100);
    } else {
      setTimeout(() => {
        window.location.href = "https://app.idinvitebook.com/login";
      }, 100);
    }
  };

  useEffect(() => {
    doLogout();
  }, []);

  return <></>;
};

export default LogoutPages;
