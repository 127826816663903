import React, { useState, useEffect } from 'react'
import CodeEditor from '@uiw/react-textarea-code-editor';
import { Form, Button, Row, Col, Card, InputGroup, FormControl, Alert, Image, Modal, Tabs, Tab } from 'react-bootstrap'


const HTMLView = ({ data, ViewCB }) => {
    const delay = 1;
    let desc
    const [key, setKey] = useState('main')

    const [code, setCode] = React.useState(
        `<div>hello world</div>`
    );
    const [colData, setColData] = useState(data)
    const changeCode = (value) => {
        setColData(prev => {
            return {
                ...prev,
                html: value
            }
        })
    }


  
    useEffect(() => {
       
        let desc = setTimeout(() => {
            if (colData) {
                if (colData.index_col !== undefined && colData.index_row !== undefined && colData.index_content !== undefined || colData.is_custom_colom) {
                   
                    ViewCB(colData)
                } else {
    
                }
            }
        }, delay * 1000);

        // this will clear Timeout
        // when component unmount like in willComponentUnmount
        // and show will not change to true
        return () => {
            clearTimeout(desc);
        };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    [colData]);

    return (
        <div style={{ textAlign: 'left' }}>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="main" title="main">
                    <Form.Group controlId="name">
                        <Form.Label><span style={{ color: 'red' }}>*</span>Kode</Form.Label>
                        <Row>
                            <Col>
                                <CodeEditor
                                    value={colData.html}
                                    language="html"
                                    data-color-mode="light"
                                    placeholder="Please enter HTML code."
                                    // onChange={(evn) => setCode(evn.target.value)}
                                    onChange={($e) => changeCode($e.target.value)} 
                                    padding={15}
                                    style={{
                                        fontSize: 12,
                   
                                    }}
                                />
                                {/* <Form.Control as="textarea" rows={12} value={colData.html} onChange={($e) => changeCode($e.target.value)}  className='form-control-dark'/> */}
                            
                            </Col>
                        </Row>
                    </Form.Group>

                </Tab>
                {/* <Tab eventKey="setting" title="setting">
              
                </Tab>
                <Tab eventKey="animation" title="animasi" >
                    Comingsoon
                </Tab> */}
            </Tabs>




        </div>
    )
}

export default HTMLView
