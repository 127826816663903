import React, { useState } from 'react';
import { MEDIALARGE, MEDIAMEDIUM, MEDIASMALL } from '../../variable';
const ResponsiveComp = ({ changeWidthDevice,widthDevice }) => {
    const [view, setView] = useState(widthDevice)

    const changeView = (value) => {
        changeWidthDevice(value)
        setView(value)
    }
    return <div style={{width:'100px'}}>
        <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
            <div onClick={() => changeView(MEDIASMALL)}>
                <i className='fas fa-mobile-alt pt-1' style={{ cursor: 'pointer', fontSize: 18, color: view === MEDIASMALL ? "#333333" : "#fff", marginRight: '10px', marginLeft: '5px', padding: '10px' }}></i>
            </div>
        </div>
        <div style={{ flex: 1 }}>
            <div onClick={() => changeView(MEDIAMEDIUM)}>
                <i className='fas fa-tablet-alt pt-1' style={{ cursor: 'pointer', fontSize: 18, color: view === MEDIAMEDIUM ? "#333333" : "#fff", marginRight: '10px', marginLeft: '5px', padding: '10px' }}></i>
            </div>
        </div>
        <div style={{ flex: 1 }}>
            <div onClick={() => changeView(MEDIALARGE)}>
                <i className='fas fa-desktop pt-1' style={{ cursor: 'pointer', fontSize: 18, color: view === MEDIALARGE ? "#333333" : "#fff", marginRight: '10px', marginLeft: '5px', padding: '10px' }}></i>
            </div>
        </div>
    </div>
    </div>
}

export default ResponsiveComp