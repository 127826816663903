
import React, { useEffect, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import mediaQuery,{ borderRadiusMediaQuery } from '../../helpers/mediaQueryHelper'
function FormLPComp({ id, formData, formCB, widthDev }) {

    console.log("formValue", formData)
    const [formValue, setFormValue] = useState({
        nama: "",
        pesan: "",
        kehadiran: "Hadir"
    })



    const changeValue = (key, value) => {
        setFormValue(prev => {
            return {
                ...prev,
                [key]: value
            }
        })
    }

    const submitValue = () => {

        formCB(formValue)
        setTimeout(() => {
            setFormValue({
                nama: "",
                pesan: "",
                kehadiran: "Hadir"
            })
        }, 100)
    }

    return (
        <div id={id} style={{
            backgroundColor: formData.isTransparent ? '' : formData.backgroundColor,
            paddingTop: mediaQuery(formData, 'paddingTop', formData.paddingTop, widthDev),
            paddingRight: mediaQuery(formData, 'paddingRight', formData.paddingRight, widthDev),
            paddingBottom: mediaQuery(formData, 'paddingBottom', formData.paddingBottom, widthDev),
            paddingLeft: mediaQuery(formData, 'paddingLeft', formData.marginLeft, widthDev),
            borderRadius: borderRadiusMediaQuery(formData, 'borderRadius', {
                borderRadius: formData.borderRadius,
                borderTopLeftRadius: formData.borderTopLeftRadius,
                borderTopRightRadius: formData.borderTopRightRadius,
                borderBottomLeftRadius: formData.borderBottomLeftRadius,
                borderBottomRightRadius: formData.borderBottomRightRadius,
            }, widthDev),
        }}>
            <div id={id} style={{ padding: 20 }}>
                <Form id={id}>
                    <Form.Group id={id}>
                        <Form.Label id={id} style={{ color: formData.color, fontFamily: formData.fontFamily }}>{formData.nameLabel}</Form.Label>
                        <Form.Control
                            id={id}
                            type="text"
                            placeholder={formData.namePlaceholder}
                            style={{ borderRadius: formData.borderRadius }}
                            value={formValue.nama}
                            disabled={true}
                        // onChange={($e) => changeValue("nama", $e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group id={id}>
                        <Form.Label id={id} style={{ color: formData.color, fontFamily: formData.fontFamily }}>{formData?.pesanLabel}</Form.Label>
                        <Form.Control
                            id={id}
                            as="textarea"
                            rows={3}
                            placeholder={formData.pesanPlaceholder}
                            value={formValue.pesan}
                            // onChange={($e) => changeValue("pesan", $e.target.value)}
                            style={{ borderRadius: formData.borderRadius }}
                            disabled={true}
                        />
                    </Form.Group>
                    <Form.Group id={id} >
                        <Form.Label id={id} style={{ color: formData.color }}>{formData?.questionLabel}</Form.Label>
                        <Form.Check
                            id={id}
                            type="radio"
                            label={formData?.hadirLabel}
                            value="Hadir"
                            name="kehadiran"
                            checked={formValue.kehadiran === "Hadir" ? true : false}
                            onChange={($e) => changeValue("kehadiran", $e.target.value)}
                            style={{ color: formData.color }}
                            disabled={true}
                        />
                        <Form.Check
                            id={id}
                            type="radio"
                            label={formData?.tidakHadirLabel}
                            value="Tidak Hadir"
                            name="kehadiran"
                            checked={formValue.kehadiran === "Tidak Hadir" ? true : false}
                            // onChange={($e) => changeValue("kehadiran", $e.target.value)}
                            style={{ color: formData.color }}
                            disabled={true}
                        />
                        <Form.Check
                            id={id}
                            type="radio"
                            label={formData?.raguLabel}
                            value="Ragu ragu"
                            name="kehadiran"
                            disabled={true}
                            checked={formValue.kehadiran === "Ragu ragu" ? true : false}
                            // onChange={($e) => changeValue("kehadiran", $e.target.value)}
                            style={{ color: formData.color }}
                        />
                    </Form.Group>
                    <Button id={id} disabled={formValue.nama === "" || formValue.kehadiran === "" || formValue.pesan === "" ? true : false} style={{ borderRadius: formData.borderRadius, width: '100%', height: 'auto', padding: 10, backgroundColor: formData.buttonBackground, border: 'solid 0px #F94F30', fontWeight: 'bold', color: formData.buttonColor, fontFamily: formData.fontFamily, fontSize: formData.fontSize }} >
                        {formData.buttonTeks}
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default FormLPComp




