import React, { useState, useEffect } from "react";
import ScrollMenuY from "../ScrollMenuY";
import LoaderComp from "../LoaderComp";
import { getMedia } from "../../services/mediaServices";
import ScrollMenuX from "../ScrollMenuX";

const ElementView = ({
  list,
  drag,
  ViewCB,
  mobileMoveAction,
  addItemMobile,
}) => {
  const [media, setMedia] = useState(list);
  const [selectedMenu, setSelectedMenu] = useState("grafis");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [menus, setMenus] = useState(["grafis"]);
  const [showLoader, setShowLoader] = useState(true);
  const [disableScroll, setDisableScroll] = useState(false);
  const [loadNewData, setLoadNewData] = useState(false);
  const [colData, setColData] = useState("");
  const [listCategory, setListCategory] = useState([
    {
      _id: "Floral",
      name: "Floral",
    },
    {
      _id: "islamic couple",
      name: "couple",
    },
    {
      _id: "islamic symbol",
      name: "symbol",
    },
    {
      _id: "baby",
      name: "baby",
    },
  ]);
  const [category, setCategory] = useState("Floral");
  const delay = 1;
  let timer1;

  useEffect(() => {
    if (colData && colData.music !== "") {
      ViewCB(colData);
    }
  }, [colData]);

  const filterCat = async (newKey, category) => {
    let keynya = selectedMenu;
    if (newKey) {
      keynya = newKey;
    }
    setDisableScroll(false);
    setShowLoader(true);
    setMedia([]);
    setCategory(category);
    try {
      let res = await getMedia(1, pageSize, "grafis", "public", category);
      console.log("res", res);
      setMedia(res.data.mediaList);
      setPage(1);
      setShowLoader(false);
    } catch (err) {
      setMedia([]);
      setShowLoader(false);
    }
  };

  const scrollActionYCB = async (pageResult) => {
    clearTimeout(timer1);
    timer1 = setTimeout(() => {
      if (!disableScroll) {
        setShowLoader(true);
        if (pageResult === page + 1) {
          setLoadNewData(pageResult);
        }
      }
    }, delay * 700);
  };

  useEffect(() => {
    if (loadNewData) {
      setPage(page + 1);
    }
  }, [loadNewData]);

  useEffect(() => {
    if (page > 1) {
      fetchNewData();
    }
  }, [page]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      const { signal } = abortController;
      try {
        let files = await getMedia(
          page,
          pageSize,
          "grafis",
          "public",
          category,
          { signal }
        );
        setMedia(files.data.mediaList);
        setShowLoader(false);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();

    return () => {
      abortController.abort(); // Cleanup function to abort the fetch if the component unmounts
    };
  }, []); // Empty dependency array means this effect runs only once

  const fetchNewData = async () => {
    try {
      let result = await getMedia(
        page,
        pageSize,
        selectedMenu,
        "public",
        category
      );
      if (result) {
        if (result.data.mediaList.length > 0) {
          let prevThemes = [...media, ...result.data.mediaList];
          setMedia(prevThemes);
        } else {
          setDisableScroll(true);
        }
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
      // setLoadNewData(false)
    } catch (err) {
      setDisableScroll(true);
      setShowLoader(false);
    }
  };
  return (
    <>
      <ScrollMenuX
        menus={listCategory}
        active={category}
        actionCB={filterCat}
      ></ScrollMenuX>
      <ScrollMenuY id="mediaView" scrollActionCB={scrollActionYCB} page={page}>
        <div style={{ paddingLeft: "5px" }}>
          {media.length === 0 && selectedMenu === menus[0] && !showLoader && (
            <p className="mt-3">
              Maaf belum ada {selectedMenu} yang terupload{" "}
            </p>
          )}
          {media.length === 0 && selectedMenu === menus[1] && !showLoader && (
            <p className="mt-3">
              Anda belum memiliki {selectedMenu} yang terupload
            </p>
          )}
          {media &&
            media.map((image) => {
              return (
                <div
                  className="text-center"
                  style={{
                    height: "100px",
                    width: "48%",
                    float: "left",
                    marginBottom: "5px",
                    marginLeft: "1%",
                    marginRight: "1%",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                  // onClick={(() => applyTheme(theme))}
                  draggable={true}
                  onDragStart={($e) => {
                    drag($e, "Image", image.link);
                  }}
                  onTouchStart={($e) => {
                    drag($e, "Image", image.link);
                  }}
                  onTouchMove={($e) => {
                    const x = $e.changedTouches[0].clientX;
                    if (parseInt(x) > 350) {
                      mobileMoveAction();
                    }
                  }}
                  onTouchEnd={($e) => {
                    $e.preventDefault();
                    const x = $e.changedTouches[0].clientX;
                    const y = $e.changedTouches[0].clientY;
                    let elem = document.elementFromPoint(x, y);
                    let idElement = elem.getAttribute("id");
                    addItemMobile(idElement);
                  }}
                >
                  <img
                    src={image.link}
                    style={{ width: "100%", overflow: "hidden", float: "left" }}
                  />
                </div>
              );
            })}

          {showLoader && <LoaderComp></LoaderComp>}
        </div>
      </ScrollMenuY>
    </>
  );
};

export default ElementView;
