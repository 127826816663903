
import React, { useEffect, useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import Select from 'react-select';
const ImageShape = ({ value, label, cbValue }) => {
   
    const [select2Data, setSelect2Data] = useState([
        { value: 'default', label: 'default' },
        { value: 'pentagon', label: 'pentagon' },
        { value: 'hexagon', label: 'hexagon' },
        { value: 'octagon', label: 'octagon' },
        { value: 'nonagon', label: 'nonagon' },
        { value: 'decagon', label: 'decagon' },
        { value: 'circle', label: 'circle' },
        { value: 'ellipse', label: 'ellipse' },
        { value: 'triangle', label: 'triangle' },
        { value: 'rhombus', label: 'rhombus' },
        { value: 'trapezoid', label: 'trapezoid' },
        { value: 'parallelogram', label: 'parallelogram' },
        { value: 'bevel', label: 'bevel' },
        { value: 'rabbet', label: 'rabbet' },
        { value: 'sign-left', label: 'sign-left' },
        { value: 'sign-right', label: 'sign-right' },
        { value: 'chevron-left', label: 'chevron-left' },
        { value: 'chevron-right', label: 'chevron-right' },
     
 
    ])

    const logChange = (val) => {
       
    }

    const [activeShape, setActiveShape] = useState(value ? value : 'default')
  
    const changeCSS = (value) => {
       
        setActiveShape(value.value)
    }

    useEffect(() => {
        cbValue({
            key: "shape",
            value: activeShape
        })
        
    }, [activeShape])


    const colourStyles = {
        container: styles => ({ ...styles, backgroundColor: '#333 !important',color:'#fff !important',border:'solid 1px #333'}),
        singleValue: styles => ({ ...styles, backgroundColor: '#333 !important',color:'#fff !important' }),
        control: styles => ({ ...styles, backgroundColor: '#333 !important',color:'#fff !important' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? '#333333' : '#333333',
                color: '#FFF !important',
                cursor: isDisabled ? 'not-allowed' : 'default',
                border:'solid 1px #333'
            };
        },

    };
    
    return (
        <>
            <Form.Group controlId="name">
                <Form.Label><span style={{ color: 'red' }}>*</span>{label}</Form.Label>
                <Select
                name="shape"
                value={{ label: value?value:activeShape, value: value?value:activeShape }}
                options={select2Data}
                onChange={changeCSS}
                styles={colourStyles}
                    className="react-select-container"
            />
            </Form.Group>
        
        </>
    )
}
export default ImageShape