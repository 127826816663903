import React, {  useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import NavbarComp from '../components/NavbarComp'
const LogsPage = () => {
  
    const [list, setList] = useState([
        {
            version:'v1.0.1',
            log:'Add CSS media query in inline style',
            date:''
        },
        {
            version:'v1.0.1',
            log:'Add layers for every slides',
            date:''
        },
        {
            version:'v1.1.1',
            log:'replace inlince CSS by CSS Classes',
            date:'27-03-2024'
        },
        {
            version:'v1.1.3',
            log:'add mobile mode',
            date:'16-05-2024'
        },
        {
            version:'v1.1.4',
            log:'add my catalog',
            date:'21-05-2024'
        }
    ])


    return (
        <>
            <NavbarComp></NavbarComp>
            <Container className='mt-5 pt-5 mb-5 pb-5'>
                <h2>Logs</h2>
                <Row>
                    <Col>
                        <ul>
                            {list.map(l => {
                                return <li>
                                    {l.log} <b>{l.version}</b>
                                </li>
                            })}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default LogsPage