import React, { useState, useEffect } from 'react'
import "react-color-palette/css";
import { Form, Tabs, Tab } from 'react-bootstrap'
import RangeSlider from '../form/Rangeslider';
import labelCSS from '../../helpers/labelCss'
import ColorComp from '../form/ColorComp';
import tinycolor from 'tinycolor2';
import BorderComp from '../form/BorderComp';
import uploadHelper from '../../helpers/uploadHelper';
import Loader from '../Loader';
import { MEDIALARGE, MEDIAMEDIUM, MEDIAQUERY, MEDIASMALL, SIZEMEDIUM, SIZESMALL, TRANSCOLOR } from '../../variable';
import mediaQuery from '../../helpers/mediaQueryHelper';
import BorderRadiusComp from '../form/BorderRadiusComp';
import ButtonSpaceComp from '../ButtonSpaceComp';
import { convertColor } from '../../helpers/colorHelper';
import InputComp from '../form/InputComp';
import InputUploadComp from '../form/Input/InputUploadComp';
const ColomView = ({ data, changeColomData, widthDevice }) => {
    const [isBorderRadiusAdvanced, setIsBorderRadiusAdvanced] = useState(false)
    if (data?.cover?.backgroundColor) {
        let tempBgColor = data?.cover?.backgroundColor.split(",")
        if (tempBgColor) {
            data.cover.opacity = tempBgColor[3]?.replace(/\D/g, "");
        } else {
            data.cover.opacity = 1
        }
    }
    if (data.linear === undefined) {
        data.linear = "solid"
    }

    const listOverflow = ['hidden', 'unset']
    const listBackground = ['color', 'image', 'slide']
    const listGradient = ['solid', 'linear']
    const listBgType = ['cover', 'repeat']
    const listBooleanText = ['yes', 'no']
    const listPosition = ['static', 'fixed']
    const listFlexType = ["flex", "custom"]

    const [colData, setcolData] = useState(data)
    const [masterData, setMasterData] = useState(data)
    const [isUploading, setIsUploading] = useState(false)
    const [key, setKey] = useState('main')
    const [changeNow, setChangeNow] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [showColor, setShowColor] = useState(false)
    const [init, setInit] = useState(true)
    const [foreground, setForgound] = useState(data?.cover !== "" ? "yes" : "no");
    const [isReady, setIsReady] = useState(false)
    const changePosition = (position) => {
        setcolData(prev => {
            return {
                ...prev,
                position: position
            }
        })
    }

    const changeWidthType = (type) => {
        setcolData(prev => {
            return {
                ...prev,
                widthType: type
            }
        })
    }
    const cbValue = (data) => {
        let key = labelCSS(data.key)
        let value = data.value
        let extension = key === "width" ? "%" : "px"
        if (data.key === "TextAlign" || data.key === "opacity") {
            extension = ""
            if (data.key === "opacity") {
                value = value / 10
            }
        }
        if (widthDevice === MEDIALARGE) {
            setcolData(prev => {
                return {
                    ...prev,
                    [key]: value + extension
                }
            })
            if (key === "width") {
                setcolData(prev => {
                    return {
                        ...prev,
                        "width": value + "px",
                        "horizontalspace": 12
                    }
                })
            }

            if (key === "opacity") {
                if (colData.linear === "linear") {
                    if (colData?.cover?.backgroundColor?.trim() !== "" && colData?.cover?.backgroundColor !== TRANSCOLOR && colData?.cover2?.backgroundColor?.trim() !== "" && colData?.cover2?.backgroundColor !== TRANSCOLOR) {
                        let bgColor = TRANSCOLOR
                        if (colData.cover.backgroundColor) {
                            bgColor = convertColor(colData.cover.backgroundColor, value)
                        }
                        let bgColor2 = TRANSCOLOR
                        if (colData?.cover2?.backgroundColor) {
                            bgColor2 = convertColor(colData.cover2.backgroundColor, value)
                        }
                        setcolData(prev => {
                            return {
                                ...prev,
                                [key]: value + extension,
                                cover: {
                                    backgroundColor: bgColor
                                },
                                cover2: {
                                    backgroundColor: bgColor2
                                }
                            }
                        })
                    } else {
                        if (colData?.cover?.backgroundColor?.trim() !== "" && colData?.cover?.backgroundColor !== TRANSCOLOR) {
                            let bgColor = convertColor(colData.cover.backgroundColor, value)
                            setcolData(prev => {
                                return {
                                    ...prev,
                                    [key]: value + extension,
                                    cover: {
                                        backgroundColor: bgColor
                                    },
                                    cover2: {
                                        backgroundColor: TRANSCOLOR
                                    }
                                }
                            })
                        } else {
                            if (colData?.cover2?.backgroundColor?.trim() !== "" && colData?.cover2?.backgroundColor !== TRANSCOLOR) {
                                let bgColor = convertColor(colData.cover2.backgroundColor, value)
                                setcolData(prev => {
                                    return {
                                        ...prev,
                                        [key]: value + extension,
                                        cover: {
                                            backgroundColor: TRANSCOLOR
                                        },
                                        cover2: {
                                            backgroundColor: bgColor
                                        }
                                    }
                                })
                            }
                        }

                    }
                } else {
                    if (colData?.cover?.backgroundColor !== TRANSCOLOR && colData?.cover?.backgroundColor?.trim() !== "") {
                        let bgColor = convertColor(colData.cover.backgroundColor, value)
                        setcolData(prev => {
                            return {
                                ...prev,
                                [key]: value + extension,
                                cover: {
                                    backgroundColor: bgColor
                                }
                            }
                        })
                    }

                }
            } else {
                setcolData(prev => {
                    return {
                        ...prev,
                        [key]: value + extension
                    }
                })
            }
        }
        if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
            if (key === "width") {
                extension = "px"
            }

            let widthDeviceStr = SIZEMEDIUM
            if (widthDevice === MEDIASMALL) {
                widthDeviceStr = SIZESMALL
            }
            let temp = { ...colData }
            if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
                let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`]

                let newObject = {
                    [key]: value + extension
                }
                let newJoin = { ...tempNewObject, ...newObject }
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin
                setcolData(temp)

            } else {
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
                    [key]: value + extension
                }
                setcolData(temp)
            }
        }
        if (init === false) {
            setTimeout(() => {
                setChangeNow(true)
            }, 100)
        }

    }


    useEffect(() => {
        if (foreground === "no") {
            setShowColor(false)
            setcolData(prev => {
                return {
                    ...prev,
                    cover: ""
                }
            })
        } else {
            setShowColor(true)
            if (data.linear === "solid") {
                setcolData(prev => {
                    return {
                        ...prev,
                        cover: {
                            backgroundColor: data?.cover?.backgroundColor ? data?.cover?.backgroundColor : "#fff",
                            opacity: data?.cover?.opacity ? data?.cover?.opacity : 1
                        }
                    }
                })
            } else {
                setcolData(prev => {
                    return {
                        ...prev,
                        cover: {
                            backgroundColor: data?.cover?.backgroundColor ? data?.cover?.backgroundColor : "#fff",
                            opacity: data?.cover?.opacity ? data?.cover?.opacity : 1
                        },
                        cover2: {
                            backgroundColor: data?.cover2?.backgroundColor ? data?.cover2?.backgroundColor : "#fff",
                            opacity: data?.cover2?.opacity ? data?.cover2?.opacity : 1
                        }
                    }
                })
            }
        }
    }, [foreground])


    const noCoverColorBG = (level) => {
        let tempdata = { ...colData }
        if (level === "primary") {
            tempdata.cover.backgroundColor = TRANSCOLOR
            tempdata.cover.rgb = ""
        } else {
            tempdata.cover2.backgroundColor = TRANSCOLOR
            tempdata.cover2.rgb = ""
        }
        setcolData(tempdata)
    }

    useEffect(() => {
        if (colData.backgroundType === undefined) {
            let tempRowData = { ...colData }
            // setcolData(tempRowData)
        }
        if (changeNow) {
            changeColomData(colData)
        }

        setMasterData(colData)
    }, [colData])

    useEffect(() => {
        let tempState = { ...masterData }
        tempState.borderRadius = 0
        tempState.borderTopLeftRadius = 0
        tempState.borderTopRightRadius = 0
        tempState.borderBottomLeftRadius = 0
        tempState.borderBottomRightRadius = 0
        if (tempState[`${MEDIAQUERY}${MEDIASMALL}`]) {
            tempState[`${MEDIAQUERY}${MEDIASMALL}`].borderTopLeftRadius = 0
            tempState[`${MEDIAQUERY}${MEDIASMALL}`].borderTopRightRadius = 0
            tempState[`${MEDIAQUERY}${MEDIASMALL}`].borderBottomLeftRadius = 0
            tempState[`${MEDIAQUERY}${MEDIASMALL}`].borderBottomRightRadius = 0
            tempState[`${MEDIAQUERY}${MEDIASMALL}`].borderRadius = 0
        }
        if (tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`]) {
            tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`].borderTopLeftRadius = 0
            tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`].borderTopRightRadius = 0
            tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`].borderBottomLeftRadius = 0
            tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`].borderBottomRightRadius = 0
            tempState[`${MEDIAQUERY}${MEDIAMEDIUM}`].borderRadius = 0
        }
        setcolData(tempState)

    }, [isBorderRadiusAdvanced])

    useEffect(() => {
        setIsReady(false)
        let tempState = { ...masterData }
        tempState.marginTop = mediaQuery(tempState, "marginTop", tempState.marginTop, widthDevice)
        tempState.marginLeft = mediaQuery(tempState, "marginLeft", tempState.marginLeft, widthDevice)
        tempState.marginRight = mediaQuery(tempState, "marginRight", tempState.marginRight, widthDevice)
        tempState.marginBottom = mediaQuery(tempState, "marginBottom", tempState.marginBottom, widthDevice)
        tempState.paddingTop = mediaQuery(tempState, "paddingTop", tempState.paddingTop, widthDevice)
        tempState.paddingRight = mediaQuery(tempState, "paddingRight", tempState.paddingRight, widthDevice)
        tempState.paddingBottom = mediaQuery(tempState, "paddingBottom", tempState.paddingBottom, widthDevice)
        tempState.paddingLeft = mediaQuery(tempState, "paddingLeft", tempState.paddingLeft, widthDevice)
        if (isBorderRadiusAdvanced) {
            tempState.borderRadius = 0
            tempState.borderTopLeftRadius = mediaQuery(tempState, "borderTopLeftRadius", tempState.borderTopLeftRadius, widthDevice)
            tempState.borderTopRightRadius = mediaQuery(tempState, "borderTopRightRadius", tempState.borderTopRightRadius, widthDevice)
            tempState.borderBottomLeftRadius = mediaQuery(tempState, "borderBottomLeftRadius", tempState.borderBottomLeftRadius, widthDevice)
            tempState.borderBottomRightRadius = mediaQuery(tempState, "borderBottomRightRadius", tempState.borderBottomRightRadius, widthDevice)
        } else {
            tempState.borderRadius = mediaQuery(tempState, "borderRadius", tempState.borderRadius, widthDevice)
            tempState.borderTopLeftRadius = 0
            tempState.borderTopRightRadius = 0
            tempState.borderBottomLeftRadius = 0
            tempState.borderBottomRightRadius = 0
        }
        tempState.backgroundColor = mediaQuery(tempState, "backgroundColor", tempState.backgroundColor, widthDevice)
        tempState.backgroundSize = mediaQuery(tempState, "backgroundSize", tempState.backgroundSize, widthDevice)
        tempState.opacity = mediaQuery(tempState, "opacity", tempState.opacity, widthDevice)
        setMasterData(tempState)
        setTimeout(() => {
            setIsReady(true)
        }, 500)
    }, [widthDevice])


    const uploadfileMultiple = async (event, type) => {

        try {
            setIsUploading(true)

            var datatemp = { ...colData }
            if (datatemp.image_slide.length > 0 && datatemp.image_slide[0].img === "https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg") {
                var tempImages = []

            } else {

                var tempImages = datatemp.image_slide

            }

            let result = await uploadHelper(event, event.target.files.length)
            if (result) {
                for (let a = 0; a < result.length; a++) {
                    tempImages.push({
                        img: result[a].widthImage
                    })
                }
                setcolData(prev => {
                    return {
                        ...prev,
                        image_slide: tempImages,
                    }
                })
            }
            setIsUploading(false)
        } catch {
            alert("gagal upload file tidak compatible")
            setIsUploading(false)
        }
    }



    const uploadfile = async (event, type) => {
        try {
            setIsUploading(true)
            let result = await uploadHelper(event)
            if (result) {
                setcolData(prev => {
                    return {
                        ...prev,
                        backgroundImage: result[0].widthImage,
                        backgroundColor: '',
                        backgroundSize: 'cover'
                    }
                })
            }
            setIsUploading(false)

            setIsUploading(false)
        } catch {
            alert("file tidak compatible")
            setIsUploading(false)

        }
    }


    const setCover = (level, value) => {
        if (colData.opacity === undefined) {
            colData.opacity = 10
        }
        if (level === "primary") {
            setcolData(prev => {
                return {
                    ...prev,
                    cover: {
                        backgroundColor: `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${colData.opacity})`,
                        rgb: `rgb(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}`,
                    }
                }
            })
        } else {
            setcolData(prev => {
                return {
                    ...prev,
                    cover2: {
                        backgroundColor: `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${colData.opacity})`,
                        rgb: `rgb(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}`,
                    }
                }
            })
        }

    }


    const changeBackgroundSize = (value) => {
        setcolData(prev => {
            return {
                ...prev,
                backgroundSize: value
            }
        })
    }

    const changeOverflow = (value) => {
        setcolData(prev => {
            return {
                ...prev,
                overflow: value,
            }
        })
    }

    const removeImage = (index) => {
        var datatemp = { ...data }
        if (datatemp.image_slide.length > 1) {
            datatemp.image_slide.splice(index, 1)
            datatemp.images378.splice(index, 1)
            datatemp.images678.splice(index, 1)
            setcolData(datatemp)
        }
    }



    const changeForground = (value) => {
        if (value === "yes" || value === true) {
            setShowColor(true)
            setcolData(prev => {
                return {
                    ...prev,
                    cover: {
                        backgroundColor: TRANSCOLOR,
                        opacity: 1,
                    }
                }
            })
        } else {
            setShowColor(false)
            setcolData(prev => {
                return {
                    ...prev,
                    cover: ""
                }
            })
        }

        setForgound(value)
    }

    useEffect(() => {
        setcolData(prev => {
            return {
                ...prev,
                foreground: foreground === "no" ? false : true
            }
        })
    }, [foreground])


    const setBgColortoBackground = (level, value) => {
        if (isActive) {
            if (level === "primary") {
                setcolData(prev => {
                    return {
                        ...prev,
                        backgroundColor: `rgb(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b})`
                    }
                })
            } else {
                setcolData(prev => {
                    return {
                        ...prev,
                        backgroundColor2: `rgb(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b})`
                    }
                })
            }
        }
        setTimeout(() => {
            setIsActive(true)
        }, 100)
        // 
    }
    const changeRowName = (value) => {
        setcolData(prev => {
            return {
                ...prev,
                tag: value
            }
        })
    }
    // useEffect(() => {
    //     if (changeNow) {
    //         changeColomData(colData)
    //         setMasterData(colData)
    //     }
    // }, [colData])


    setTimeout(() => {
        setChangeNow(true)
    }, 100)

    const changeBorderColor = (value) => {
        setcolData(prev => {
            return {
                ...prev,
                isBorderTransparent: false,
                borderColor: value.hex
            }
        })
    }

    const changeValueCB = (value) => {
        setcolData(prev => {
            return {
                ...prev,
                borderType: value
            }
        })
    }

    const changeBorder = (value) => {
        setcolData(prev => {
            return {
                ...prev,
                border: value.value
            }
        })
    }

    const noColorBG = () => {
        setcolData(prev => {
            return {
                ...prev,
                backgroundColor: TRANSCOLOR
            }
        })
    }
    const changeLinear = (value) => {
        setcolData(prev => {
            return {
                ...prev,
                linear: value,
            }
        })
    }

    const changeBackground = (value) => {
        if (value !== "image") {
            setcolData(prev => {
                return {
                    ...prev,
                    backgroundType: value,
                    cover: "",
                    backgroundImage: ""
                }
            })
        } else {
            setcolData(prev => {
                return {
                    ...prev,
                    backgroundType: value,
                    cover: "",
                }
            })
        }
        setForgound("no")
    }

    const boderRadiusAdvancedMode = (val) => {
        setIsBorderRadiusAdvanced(val)
    }

    const ImageSection = () => {
        return <>
            {isUploading ? <Loader></Loader> : <> {data.backgroundImage && <img src={data.backgroundImage} width="100%" height="200px" />}</>}
            <Form.Group controlId="name" className='form-control-dark'>
                {/* <Form.Label><span style={{ color: 'red' }}>*</span>Background</Form.Label> */}
<InputUploadComp
                    id="custom-file"
                    label="Custom file input"
                    name="input" 
                    type="file" className="file" data-show-upload="false" data-show-caption="true" custom 
                    onChange={($e) => { uploadfile($e, "address") }}
                />
            </Form.Group>
            <InputComp
                type="select"
                label="Background Type"
                value={data.backgroundSize}
                options={listBgType}
                onChange={changeBackgroundSize}
            />
            <InputComp
                type="select"
                label="Foreground"
                value={foreground}
                options={listBooleanText}
                onChange={changeForground}
            />
            {
                foreground === "yes" && <>
                    <InputComp
                        type="select"
                        label="Linear"
                        value={colData.linear}
                        options={listGradient}
                        onChange={changeLinear}
                    />
                    {showColor && <ColorComp
                        label="Color"
                        colorCB={(value) => setCover("primary", value)}
                        primaryColor={tinycolor(colData.cover.backgroundColor).toHexString()}
                        showNoColor={true}
                        noColor={() => noCoverColorBG("primary")
                        }
                    ></ColorComp>}
                    {showColor && colData.linear === "linear" && <ColorComp
                        label="Color"
                        colorCB={(value) => setCover("secondary", value)}
                        primaryColor={tinycolor(colData?.cover2?.backgroundColor).toHexString()}
                        showNoColor={true}
                        noColor={() => noCoverColorBG("secondary")}
                    ></ColorComp>}
                    {showColor && <RangeSlider value={colData.opacity} label="opacity" cbValue={cbValue} maxvalue="10"></RangeSlider>}
                </>
            }
        </>
    }

    const SlideSection = () => {
        return <>
            <Form.Group controlId="name">
                <Form.Label><span style={{ color: 'red' }}>*</span> Gambar Produk</Form.Label>
                <Form.Control
                    id="custom-file"
                    label="Custom file input"
                    name="input2[]" type="file" className="file" data-show-upload="false" data-show-caption="true" custom multiple
                    onChange={($e) => { uploadfileMultiple($e) }}
                />
                <div>
                    <i>support jpg/png max 5mb</i>
                </div>
                {data.image_slide && data.image_slide.map((img, index) => {
                    return (
                        <div style={{ display: 'flex' }} key={index}>
                            <div style={{ flex: 1, marginBottom: '10px' }}>
                                <img src={img.img} className='img-fluid' />
                                {data.image_slide.length > 1 && <div style={{ width: '100%', height: '20px', background: '#f5f5f5', zIndex: 1, textAlign: 'center', cursor: 'pointer' }} onClick={() => removeImage(index)}>
                                    <i className='fas fa-times' style={{ zIndex: 100, fontSize: 16, color: "red", cursor: 'pointer' }} ></i>
                                </div>}
                            </div>
                        </div>
                    )
                })}
            </Form.Group>
            <InputComp
                type="select"
                label="Foreground"
                value={foreground}
                options={listBooleanText}
                onChange={changeForground}
            />
            {
                foreground === "yes" && <>
                    <InputComp
                        type="select"
                        label="Linear"
                        value={colData.linear}
                        options={listGradient}
                        onChange={changeLinear}
                    />
                    {showColor && <ColorComp
                        label="Color"
                        colorCB={(value) => setCover("primary", value)}
                        primaryColor={tinycolor(colData?.cover?.backgroundColor).toHexString()}
                        showNoColor={true}
                        noColor={() => noCoverColorBG("primary")
                        }
                    ></ColorComp>}
                    {showColor && colData.linear === "linear" && <ColorComp
                        label="Color"
                        colorCB={(value) => setCover("secondary", value)}
                        primaryColor={tinycolor(colData?.cover2?.backgroundColor).toHexString()}
                        showNoColor={true}
                        noColor={() => noCoverColorBG("secondary")}
                    ></ColorComp>}
                    {showColor && <RangeSlider value={colData.opacity} label="opacity" cbValue={cbValue} maxvalue="10"></RangeSlider>}
                </>
            }
        </>
    }

    const ColorSection = () => {
        return <>
            <InputComp
                type="select"
                label="Linear"
                value={colData.linear}
                options={listGradient}
                onChange={changeLinear}
            />
            <ColorComp
                label="Background"
                colorCB={(value) => setBgColortoBackground("primary", value)}
                primaryColor={tinycolor(data?.backgroundColor).toHexString()}
                showNoColor={true} noColor={noColorBG}>
            </ColorComp>
            {data.linear === "linear" && <>
                <ColorComp
                    label="Background"
                    colorCB={(value) => setBgColortoBackground("secondary", value)}
                    primaryColor={tinycolor(data?.backgroundColor2).toHexString()}
                    showNoColor={true} noColor={noColorBG}>
                </ColorComp>
            </>}
        </>
    }

    return (<div style={{ textAlign: 'left' }}>
        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="main" title="main">
                {isReady && <div>
                    <InputComp
                        type="select"
                        label="Background"
                        value={data.backgroundType}
                        options={listBackground}
                        onChange={changeBackground}
                    />
                    {data.backgroundType === "color" && <ColorSection></ColorSection>}
                    {data.backgroundType === "image" && <ImageSection></ImageSection>}
                    {/* {background === "color" && <RangeSlider value={colData.opacity} label="opacity" cbValue={cbValue} maxvalue="10"></RangeSlider>} */}
                    {data.backgroundType === "slide" && <SlideSection></SlideSection>}
                    <ColorComp label="Border" colorCB={changeBorderColor} primaryColor={colData.borderColor} showNoColor={false} ></ColorComp>
                    <InputComp
                        type="select"
                        label="Position"
                        value={data.position}
                        options={listPosition}
                        onChange={changePosition}
                    />
                    <InputComp
                        type="select"
                        label="Width Type"
                        value={data.widthType}
                        options={listFlexType}
                        onChange={changeWidthType}
                    />
                    <InputComp
                        type="select"
                        label="Overflow"
                        value={colData.overflow}
                        options={listOverflow}
                        onChange={changeOverflow}
                    />
                    {colData.widthType === "custom" && <>
                        <RangeSlider value={colData.width} label="Width" cbValue={cbValue} maxvalue="800"></RangeSlider>
                    </>}
                    <BorderComp defaultValue={colData.borderType} changeValue={changeValueCB} ></BorderComp>
                </div>}
                <ButtonSpaceComp></ButtonSpaceComp>
            </Tab>
            <Tab eventKey="setting" title="setting">
                {isReady && <>
                    <BorderRadiusComp value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100" advancedValue={
                        {
                            borderTopLeftRadius: masterData.borderTopLeftRadius,
                            borderTopRightRadius: masterData.borderTopRightRadius,
                            borderBottomLeftRadius: masterData.borderBottomLeftRadius,
                            borderBottomRightRadius: masterData.borderBottomRightRadius
                        }
                    } isAdvancedMode={isBorderRadiusAdvanced} adVancedModeCB={boderRadiusAdvancedMode}></BorderRadiusComp>
                    <RangeSlider value={masterData.marginTop} label="Margin Top" cbValue={cbValue}></RangeSlider>
                    <RangeSlider value={masterData.marginRight} label="Margin Right" cbValue={cbValue}></RangeSlider>
                    <RangeSlider value={masterData.marginBottom} label="Margin Bottom" cbValue={cbValue}></RangeSlider>
                    <RangeSlider value={masterData.marginLeft} label="Margin Left" cbValue={cbValue}></RangeSlider>
                    <RangeSlider value={masterData.paddingTop} label="Padding Top" cbValue={cbValue}></RangeSlider>
                    <RangeSlider value={masterData.paddingRight} label="Padding Right" cbValue={cbValue}></RangeSlider>
                    <RangeSlider value={masterData.paddingBottom} label="Padding Bottom" cbValue={cbValue}></RangeSlider>
                    <RangeSlider value={masterData.paddingLeft} label="Padding Left" cbValue={cbValue}></RangeSlider>
                    {/* <RangeSlider value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100"></RangeSlider> */}
                    <RangeSlider value={masterData.border} label="Border" cbValue={changeBorder} maxvalue="100"></RangeSlider>
                </>}
                <ButtonSpaceComp></ButtonSpaceComp>
            </Tab>
            <Tab eventKey="menu" title="menu">
                <Form.Group controlId="name">
                    <Form.Label><span style={{ color: 'red' }}></span>Tag Name</Form.Label>
                    <Form.Control type='text' placeholder='' value={data.tag} onChange={($e) => { changeRowName($e.target.value) }}></Form.Control>
                </Form.Group>
                {/* <FontIconComp iconclassName={data.icon} iconClassCB={changeIcon}></FontIconComp> */}
                <ButtonSpaceComp></ButtonSpaceComp>
            </Tab>
        </Tabs>
    </div>)
}

export default ColomView
