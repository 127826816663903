import React from "react";
import { Link } from "react-router-dom";
import momentService from "../../../helpers/momentHelper";
import { LARGEDEVICE, SMARTPHONE } from "../../../variable";

const columns = (row, detail, query, profile, device) => {
  var domain = `https://${process.env.REACT_APP_DOMAIN}`;
  if (profile?.customerSetting) {
    if (
      profile?.customerSetting?.domain !== "" &&
      profile?.customerSetting?.domain !== undefined
    ) {
      domain = "https://" + profile?.customerSetting?.domain;
    }
  }
  const sumbitDetail = (row, action) => {
    detail(row, action);
  };
  const share = (row, action) => {
    detail(row, action);
  };

  const dekstop = [
    {
      name: "",
      cell: (row, index) => (query.page - 1) * query.pageSize + index + 1,
      width: device === SMARTPHONE ? "10%" : "60px",
    },
    {
      name: "Undangan",
      cell: (row) => (
        <>
          <div style={{ display: "flex" }}>
            <div style={{ width: "110px" }}>
              <img
                src={
                  row?.meta_image
                    ? row?.meta_image
                    : "https://d1jcw7e5n1q2pt.cloudfront.net/1701822331883-notfound.jpg"
                }
                style={{
                  borderRadius: "20px",
                  padding: "10px",
                  width: "100px",
                }}
                className="img-responsive"
              />
            </div>
            <div style={{ flex: 1 }}>
              <div>
                <ul
                  style={{
                    listStyle: "none",
                    display: "inline",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  <li
                    style={{
                      margin: "0px",
                      padding: "0px",
                      marginTop: "24px",
                      marginBottom: "0px",
                    }}
                  >
                    {" "}
                    <h5>
                      {row.title.length > 15
                        ? `${row.title.substring(0, 15)}..`
                        : row.title}
                    </h5>
                  </li>
                  <li
                    style={{
                      margin: "0px",
                      padding: "0px",
                      marginTop: "-18px",
                    }}
                  >
                    <ul
                      style={{
                        listStyle: "none",
                        display: "inline",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {/* <li style={{ display: 'inline' }}>    <a href={`http://${domain}/landingpage/${row.slug}`} target="_blank"> <i className='fas fa-eye mr-3' style={{ fontSize: 18 }}></i>  </a></li> */}
                      <li style={{ display: "inline" }}>
                        {" "}
                        <a
                          href={`${domain}/undangan/${row.slug}`}
                          target="_blank"
                        >
                          {" "}
                          <i
                            className="fas fa-eye mr-3"
                            style={{ fontSize: 18 }}
                          ></i>{" "}
                        </a>
                      </li>
                      <li style={{ display: "inline" }}>
                        {" "}
                        <Link to={`/editor-web/${row._id}`}>
                          {" "}
                          <i
                            className="fas fa-pen mr-3"
                            style={{ fontSize: 18 }}
                          ></i>{" "}
                        </Link>
                      </li>
                      <li style={{ display: "inline" }}>
                        {" "}
                        <Link to={`/rsvp/${row._id}`}>
                          {" "}
                          <i
                            className="fas fa-database mr-3"
                            style={{ fontSize: 18 }}
                          ></i>{" "}
                        </Link>
                      </li>
                      <li
                        style={{ display: "inline" }}
                        onClick={() => sumbitDetail(row, "duplicate")}
                      >
                        {" "}
                        <i
                          className="fas fa-copy mr-3 mt-4 "
                          style={{ fontSize: 18, color: "#2E3159" }}
                        ></i>{" "}
                      </li>
                      <li
                        style={{ display: "inline" }}
                        onClick={() =>
                          share(`${domain}/undangan/${row.slug}`, "share")
                        }
                      >
                        {" "}
                        <i
                          className="fas fa-share mr-3 mt-4 "
                          style={{ fontSize: 18, color: "#2E3159" }}
                        ></i>{" "}
                      </li>

                      <li
                        style={{ display: "inline" }}
                        onClick={() => sumbitDetail(row, "delete")}
                      >
                        <i
                          className="fas fa-trash mr-3"
                          style={{ fontSize: 18 }}
                        ></i>{" "}
                      </li>
                      {/* <li style={{ display: 'inline' }}>    <Link to={`/plugins/visual/lead/${row._id}`}> <i className='fas fa-database mr-3' style={{ fontSize: 18 }}></i>  </Link></li> */}
                    </ul>
                  </li>
                </ul>
              </div>
              <div></div>
            </div>
          </div>
        </>
      ),
      width: "75%",
    },
    {
      name: "Tanggal dibuat",
      cell: (row) => momentService(row.createdAt),
      width: "150px",
    },
  ];

  const mobile = [
    {
      name: "Undangan",
      cell: (row) => (
        <>
          <div
            style={{
              display: "flex",
              paddingBottom: "20px",
              paddingTop: "10px",
            }}
          >
            <div style={{ width: "85px" }}>
              <img
                src={
                  row?.meta_image
                    ? row?.meta_image
                    : "https://d1jcw7e5n1q2pt.cloudfront.net/1701822331883-notfound.jpg"
                }
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "15px",
                  padding: "10px",
                  marginLeft: "-10px",
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <div>
                <ul
                  style={{
                    listStyle: "none",
                    display: "inline",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  <li
                    style={{
                      margin: "0px",
                      padding: "0px",
                      marginTop: "4px",
                      marginBottom: "0px",
                    }}
                  >
                    {" "}
                    <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                      {row.title.length > 10
                        ? `${row.title.substring(0, 10)}..`
                        : row.title}
                    </p>
                  </li>
                  <li
                    style={{
                      margin: "0px",
                      padding: "0px",
                      marginTop: "-32px",
                    }}
                  >
                    <ul
                      style={{
                        listStyle: "none",
                        display: "inline",
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {/* <li style={{ display: 'inline' }}>    <a href={`http://${domain}/landingpage/${row.slug}`} target="_blank"> <i className='fas fa-eye mr-3' style={{ fontSize: 18 }}></i>  </a></li> */}
                      <li style={{ display: "inline" }}>
                        {" "}
                        <a
                          href={`${domain}/undangan/${row.slug}`}
                          target="_blank"
                        >
                          {" "}
                          <i
                            className="fas fa-eye mr-3"
                            style={{ fontSize: 18 }}
                          ></i>{" "}
                        </a>
                      </li>
                      <li style={{ display: "inline" }}>
                        {" "}
                        <Link to={`/editor-web/${row._id}`}>
                          {" "}
                          <i
                            className="fas fa-pen mr-3"
                            style={{ fontSize: 18 }}
                          ></i>{" "}
                        </Link>
                      </li>
                      <li style={{ display: "inline" }}>
                        {" "}
                        <Link to={`/rsvp/${row._id}`}>
                          {" "}
                          <i
                            className="fas fa-database mr-3"
                            style={{ fontSize: 18 }}
                          ></i>{" "}
                        </Link>
                      </li>
                      <li
                        style={{ display: "inline" }}
                        onClick={() => sumbitDetail(row, "duplicate")}
                      >
                        {" "}
                        <i
                          className="fas fa-copy mr-3 mt-4 "
                          style={{ fontSize: 18, color: "#2E3159" }}
                        ></i>{" "}
                      </li>
                      <li
                        style={{ display: "inline" }}
                        onClick={() =>
                          share(`${domain}/undangan/${row.slug}`, "share")
                        }
                      >
                        {" "}
                        <i
                          className="fas fa-share mr-3 mt-4 "
                          style={{ fontSize: 18, color: "#2E3159" }}
                        ></i>{" "}
                      </li>

                      <li
                        style={{ display: "inline" }}
                        onClick={() => sumbitDetail(row, "delete")}
                      >
                        <i
                          className="fas fa-trash mr-3"
                          style={{ fontSize: 18 }}
                        ></i>{" "}
                      </li>
                      {/* <li style={{ display: 'inline' }}>    <Link to={`/plugins/visual/lead/${row._id}`}> <i className='fas fa-database mr-3' style={{ fontSize: 18 }}></i>  </Link></li> */}
                    </ul>
                  </li>
                </ul>
              </div>
              <div></div>
            </div>
          </div>
        </>
      ),
      width: "100%",
    },
  ];
  return device === LARGEDEVICE ? dekstop : mobile;
};

export default columns;
