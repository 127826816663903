const convertColor = (bgColor, value) => {
    if(bgColor !== undefined && bgColor.includes("(")){
        let stringColor = bgColor.split('(')
        stringColor = stringColor[1].split(',')
        let r = parseInt(stringColor[0])
        let g = parseInt(stringColor[1])
        let b = parseInt(stringColor[2])
        return `rgba(${r}, ${g}, ${b},${value})`
    } else {
        return bgColor
    }
}
export {convertColor}