
import React, { useEffect, useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { Range, getTrackBackground } from "react-range";

const RangeSlider = ({ value, label, cbValue, maxvalue }) => {
  if (typeof value === "string") {
    value = value.replace(/\D/g, "");
  } else {
    value = Math.round(value);
  }
  const STEP = 0.1;
  const MIN = 0;
  if (maxvalue !== undefined || maxvalue !== null || maxvalue === "") {
    var MAX = parseInt(maxvalue)
  } else {
    var MAX = label === "width" ? 100 : 100;
  }

  const [formValue, setFormValue] = useState({
    values: value>MAX?[MAX]:[value]
  })

  const changeValue = (value) => {
    value = Math.round(value);
    console.log("changerange",value)
    setFormValue({
      values: [value]
    })
    
  }


  useEffect(() => {
    if(formValue.values[0] !== value){
      cbValue({
        key: label.replace(/ /g, ''),
        value: formValue.values[0]
      })
    }
  }, [formValue])

  const changeRangeValue = (va) => {
    value = Math.round(va.values[0]);
    console.log("changerange",value)
    setFormValue({
      values: [value]
    })
  }
  
  return (
    <Row>
      <Col md={7} xs={7}>
        <Form.Label><span style={{ color: 'red' }}>*</span><span style={{fontSize:'13px'}}>{label}</span></Form.Label>
        <Range
          values={formValue.values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => changeRangeValue({ values })}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "16px",
                display: "flex",
                width: "100%"
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: formValue.values,
                    colors: ["#548BF4", "#ccc"],
                    min: MIN,
                    max: MAX
                  }),
                  alignSelf: "center"
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "22px",
                width: "22px",
                borderRadius: "4px",
                backgroundColor: "#333",
                color:'red',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA"
              }}
            >
              <div
                style={{
                  height: "16px",
                  width: "5px",
                  backgroundColor: isDragged ? "#548BF4" : "#CCC"
                }}
              />
            </div>
          )}
        />
      </Col>
      <Col md={5} xs={5}>
        <Form.Group controlId="name" className="mt-2">

          <Row>
            <Col>
              <Form.Control type='text' placeholder='' value={formValue.values[0]} onChange={($e) => changeValue($e.target.value)} className='form-control-dark' ></Form.Control>
            </Col>
          </Row>
        </Form.Group>
      </Col>
    </Row>
  )
}

export default RangeSlider

