import { LARGEDEVICE, SMARTPHONE } from "../variable"

const checkDevice = () => {
    let monitorWidth = window && parseInt(window.innerWidth)
    let device = ""
    let MIN_LARGEDEVICE_WIDTH = 600
    if(monitorWidth > MIN_LARGEDEVICE_WIDTH){
        device = LARGEDEVICE
    }

    if(monitorWidth > 10 && monitorWidth<MIN_LARGEDEVICE_WIDTH){
        device = SMARTPHONE
    }
    return device
}

export { checkDevice }