import axiosInstance from "../helpers/axiosHelper"
const path ="/api/v1/customer/media"

export const getMedia = (page,pageSize,type,status,category) => {
    let url = `${path}?page=${page}&pageSize=${pageSize}&type=${type}`
    if(category !==undefined){
        url = url+`&name=${category}`
    }
    if(status !==undefined){
        url = url+`&status=${status}`
    }
    return axiosInstance.get(url)
}

export const getMedia2 = (page,pageSize,type,status,category) => {
    let url = `${path}?page=${page}&pageSize=${pageSize}&type=${type}`
    if(category !==undefined){
        url = url+`&keyword=${category}`
    }
    if(status !==undefined){
        url = url+`&status=${status}`
    }
    return axiosInstance.get(url)
}
export const createMedia = (data) => {
    return axiosInstance.post(`${path}`, data)
}
export const deleteMedia = (id) => {
    return axiosInstance.delete(`${path}/${id}`)
}