import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
const BorderComp = ({ defaultValue, changeValue }) => {
    const listBorder = [
        "dotted",
        "dashed",
        "solid",
        "double",
        "groove",
        "ridge",
        "inset",
        "outset",
        "none",
        "hidden",
    ]
    const [value, setValue] = useState(defaultValue)
    const changeDotted = (value) => {
        setValue(value)
    }
    useEffect(() => {
        changeValue(value)
    }, [value])

    return <>
        <Form.Group controlId="name">
            <Form.Label><span style={{ color: 'red' }}>*</span>Border</Form.Label>
            <Row>
                <Col>
                    <Form.Control as="select" onChange={($e) => changeDotted($e.target.value)}  className='form-control-dark'>
                        {listBorder.map(borderType => {
                            return <option value={borderType} selected={defaultValue === borderType}>{borderType}</option>
                        })}
                    </Form.Control>
                </Col>
            </Row>
        </Form.Group>
    </>
}

export default BorderComp;