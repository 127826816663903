import React from "react";
import { Form } from "react-bootstrap";
function InputUploadComp({ label, name, onChange }) {
    console.log("labellabel",label)
  return (
    <>
      <Form.Group controlId="name">
        {label && (
          <Form.Label>
            <span style={{ color: "red" }}></span>
            {label}
          </Form.Label>
        )}
        <Form.Control
          type="file"
          id="custom-file"
          label="Custom file input"
          name={name}
          className="file"
          data-show-upload="false"
          data-show-caption="true"
          custom
          onChange={($e) => {
            onChange($e);
          }}
        />
      </Form.Group>
    </>
  );
}

export default InputUploadComp;
