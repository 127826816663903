import React, { useEffect, useState } from 'react'
import { deleteComment, getLandingpageDetail } from '../../services/invitationServices'
import BreadCrumbComp from '../../components/BreadCrumbComp'
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import NavbarComp from '../../components/NavbarComp';
import ContactCSComp from '../../components/ContactCSComp';
import { useParams } from 'react-router-dom';
const RSVPPage = () => {
    let {slug} = useParams()
    const [listForm, setListForm] = useState([])
    const [breadcrumbData, setBreadCrumbData] = useState({
        root: 'Home',
        root_link: '/',
        menu: 'Undangan',
        menu_link: '/',
        current: ''
    });

    const fetchInvitations = async () => {
        let detail = await getLandingpageDetail(slug)
        if (detail?.data) {
            let data = []
            if(detail.data.listform){
                data = JSON.parse(detail.data.listform)
            }
            setListForm(data)
        }
    }

    useEffect(() => {
        fetchInvitations();
    }, [])

    const deleteFunc = (index) => {
        let list = [...listForm]
        list.splice(index, 1)
        let res = deleteComment(slug, {
            data: JSON.stringify(list)
        })
        if (res) {
            setListForm(list)
        }
    }
    return (
        <div>
            <NavbarComp></NavbarComp>
            <Container>
                <Row>
                    <Col md={12}>

                        <BreadCrumbComp data={breadcrumbData}></BreadCrumbComp>

                        <Table striped bordered hover>
                            <thead>
                                <tr>

                                    <th>Nama</th>
                                    <th>Status Kehadiran</th>
                                    <th>Pesan</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listForm && listForm.map((l, index) => {
                                    return <tr>

                                        <td>{l.nama}</td>
                                        <td>{l.kehadiran}</td>
                                        <td>{l.pesan}</td>
                                        <td>
                                            <Button onClick={() => deleteFunc(index)}>Hapus</Button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            <ContactCSComp></ContactCSComp>
        </div >
    )
}

export default RSVPPage
