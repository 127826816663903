import React, { useState, useEffect } from 'react'
import { Form, Col, Tabs, Tab } from 'react-bootstrap'
import FontIconComp from './FontIconComp'
import RangeSlider from '../form/Rangeslider'
import TextAlign from '../form/TextAlign'
import labelCSS from '../../helpers/labelCss'
import { DefaultEditor } from 'react-simple-wysiwyg';
import ColorComp from '../form/ColorComp'
import { useColor } from "react-color-palette";
import AnimationComp from '../form/AnimationComp';
import mediaQuery, { clearBorderRadius, isAdvancedRadius } from '../../helpers/mediaQueryHelper';
import { MEDIALARGE, MEDIAMEDIUM, MEDIAQUERY, MEDIASMALL, SIZEMEDIUM, SIZESMALL } from '../../variable';
import BorderRadiusComp from '../form/BorderRadiusComp'
const ListView = ({ data, ViewCB, widthDevice }) => {
    const [isBorderRadiusAdvanced, setIsBorderRadiusAdvanced] = useState(isAdvancedRadius(data))
    const delay = 1;
    let desc
    const [key, setKey] = useState('main')
    const [color, setColor] = useColor("rgb", "98, 142, 48");
    const [colData, setColData] = useState(data)
    const [isReady, setIsReady] = useState(false)
    const [masterData, setMasterData] = useState(data)
    const [widthDevice2, setWidthDevice2] = useState(widthDevice)


    const boderRadiusAdvancedMode = (val) => {
        setIsBorderRadiusAdvanced(val)
        let resetRadius = clearBorderRadius(masterData)
        setColData(resetRadius)
    }

    useEffect(() => {
        let desc = setTimeout(() => {
            if (colData) {
                ViewCB(colData)
                setMasterData(colData)
            }
        }, delay * 1000);

        // this will clear Timeout
        // when component unmount like in willComponentUnmount
        // and show will not change to true
        return () => {
            clearTimeout(desc);
        };
    },
        // useEffect will run only one time with empty []
        // if you pass a value to array,
        // like this - [data]
        // than clearTimeout will run every time
        // this value changes (useEffect re-run)
        [colData]);

    const changeIcon = (value) => {
        setColData(prev => {
            return {
                ...prev,
                icon: value
            }
        })
    }

    const cbAnimation = (value) => {
        setColData(prev => {
            return {
                ...prev,
                animation: value
            }
        })
    }

    const changeList = (value) => {

        setColData(prev => {
            return {
                ...prev,
                content: value
            }
        })
    }

    const changeColor = (value) => {

        setColor(value)
        setColData(prev => {
            return {
                ...prev,
                color: value.hex
            }
        })

    }

    const changeBackground = (value) => {

        setColor(value)
        setColData(prev => {
            return {
                ...prev,
                isTransparent: false,
                background: value.hex
            }
        })

    }



    const cbValue = (data) => {

        let key = labelCSS(data.key)

        let value = data.value
        let extension = key === "width" ? "%" : "px"
        if (data.key === "textAlign") {
            extension = ""

        }
        if (widthDevice === MEDIALARGE) {
            setColData(prev => {
                return {
                    ...prev,
                    [key]: value + extension
                }
            })
        }
        if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
            let widthDeviceStr = SIZEMEDIUM
            if (widthDevice === MEDIASMALL) {
                widthDeviceStr = SIZESMALL
            }
            let temp = { ...colData }
            if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
                let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`]
                if (extension !== "") {
                    value = value + extension
                }
                let newObject = {
                    [key]: value
                }
                let newJoin = { ...tempNewObject, ...newObject }
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin
                setColData(temp)

            } else {
                if (extension !== "") {
                    value = value + extension
                }
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
                    [key]: value
                }
                setColData(temp)
            }
        }

    }

    const noColor = (value) => {
        setColData(prev => {
            return {
                ...prev,
                isTransparent: value
            }
        })
    }


  


    const calculateSetting = () => {
        setIsReady(false)
        let tempState = { ...masterData }
        tempState.marginTop = mediaQuery(tempState, "marginTop", tempState.marginTop, widthDevice)
        tempState.marginLeft = mediaQuery(tempState, "marginLeft", tempState.marginLeft, widthDevice)
        tempState.marginRight = mediaQuery(tempState, "marginRight", tempState.marginRight, widthDevice)
        tempState.marginBottom = mediaQuery(tempState, "marginBottom", tempState.marginBottom, widthDevice)
        tempState.paddingTop = mediaQuery(tempState, "paddingTop", tempState.paddingTop, widthDevice)
        tempState.paddingRight = mediaQuery(tempState, "paddingRight", tempState.paddingRight, widthDevice)
        tempState.paddingBottom = mediaQuery(tempState, "paddingBottom", tempState.paddingBottom, widthDevice)
        tempState.paddingLeft = mediaQuery(tempState, "paddingLeft", tempState.paddingLeft, widthDevice)
        tempState.background = mediaQuery(tempState, "background", tempState.background, widthDevice)
        tempState.color = mediaQuery(tempState, "color", tempState.color, widthDevice)
        tempState.textAlign = mediaQuery(tempState, "textAlign", tempState.textAlign, widthDevice)
        if(isBorderRadiusAdvanced){
            tempState.borderRadius = 0
            tempState.borderTopLeftRadius = mediaQuery(tempState, "borderTopLeftRadius", tempState.borderTopLeftRadius, widthDevice)
            tempState.borderTopRightRadius = mediaQuery(tempState, "borderTopRightRadius", tempState.borderTopRightRadius, widthDevice)
            tempState.borderBottomLeftRadius = mediaQuery(tempState, "borderBottomLeftRadius", tempState.borderBottomLeftRadius, widthDevice)
            tempState.borderBottomRightRadius = mediaQuery(tempState, "borderBottomRightRadius", tempState.borderBottomRightRadius, widthDevice)
        } else {
            tempState.borderRadius = mediaQuery(tempState, "borderRadius", tempState.borderRadius, widthDevice)
            tempState.borderTopLeftRadius = 0
            tempState.borderTopRightRadius = 0
            tempState.borderBottomLeftRadius =0
            tempState.borderBottomRightRadius =0
        }
        setMasterData(tempState)
        setTimeout(() => {
            setIsReady(true)
        }, 500)
    }


    useEffect(() => {
        setIsReady(false)
        setTimeout(() => {
            calculateSetting(widthDevice2)
        }, 500)
    }, [widthDevice2])



    
    useEffect(() => {
        if (widthDevice !== widthDevice2) {
            setWidthDevice2(widthDevice)
        }
    }, [widthDevice])




    return (
        <div style={{ textAlign: 'left' }}>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="main" title="main">
                    {isReady && <>
                        <div className='mb-2'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Konten</Form.Label>
                                <Form.Control as="textarea" rows={3} value={colData.content} onChange={($e) => { changeList($e.target.value) }} className='form-control-dark' />
                            </Form.Group>
                        </div>
                        <RangeSlider value={masterData.fontSize} label="Font Size" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <ColorComp label="Color" colorCB={changeColor} primaryColor={masterData.color}></ColorComp>
                        <ColorComp label="Background" colorCB={changeBackground} primaryColor={masterData.background} showNoColor={true} noColor={noColor}></ColorComp>
                        <TextAlign value={masterData.textAlign} label="Text Align" cbValue={cbValue}></TextAlign>
                        <FontIconComp iconclassName={colData.icon} iconClassCB={changeIcon}></FontIconComp>
                    </>}
                </Tab>
                <Tab eventKey="setting" title="setting">
                    {isReady && <>
                        <BorderRadiusComp value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100" advancedValue={
                            {
                                borderTopLeftRadius: masterData.borderTopLeftRadius,
                                borderTopRightRadius: masterData.borderTopRightRadius,
                                borderBottomLeftRadius: masterData.borderBottomLeftRadius,
                                borderBottomRightRadius: masterData.borderBottomRightRadius
                            }
                        } isAdvancedMode={isBorderRadiusAdvanced} adVancedModeCB={boderRadiusAdvancedMode}></BorderRadiusComp> 
                        {/* <RangeSlider value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100"></RangeSlider> */}
                        <RangeSlider value={masterData.marginTop} label="Margin Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginRight} label="Margin Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginBottom} label="Margin Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginLeft} label="Margin Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingTop} label="Padding Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingRight} label="Padding Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingBottom} label="Padding Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingLeft} label="Padding Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    </>}
                </Tab>
                <Tab eventKey="animation" title="animasi" >
                    <AnimationComp value={colData.animation} cbValue={cbAnimation}></AnimationComp>
                </Tab>
            </Tabs>

            {/* <Form.Group controlId="name">
                <Form.Label><span style={{ color: 'red' }}>*</span>List</Form.Label>
                <Col>
                    <Form.Control as="textarea" rows={3} value={colData.content} onChange={($e) => changeList($e.target.value)} style={{ width: '100%' }} />
                </Col>
            </Form.Group> */}






        </div>
    )
}

export default ListView
