import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlices from "./features/userSlices";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";

const persisConfig = {
    key:'root',
    storage,
    whitelist:'user'
}

const rootReducer = combineReducers({
    user: userSlices,
  });

const persistedReducer = persistReducer(persisConfig,rootReducer)
const store = configureStore({
    reducer: persistedReducer,
})
let persistor = persistStore(store);
export {persistor}
export default store;