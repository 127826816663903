import React, { useState, useEffect } from 'react'
import {Tooltip ,OverlayTrigger} from 'react-bootstrap'
import ScrollMenuY from '../ScrollMenuY'
import LoaderComp from '../LoaderComp'

const SnippetView = ({ viewActive, list, drag, mobileMoveAction, addItemMobile, ViewCB }) => {
    const [snippet, setSnippet] = useState(list)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [showLoader, setShowLoader] = useState(true)
    const [disableScroll, setDisableScroll] = useState(false)
    const [loadNewData, setLoadNewData] = useState(false)
    const [colData, setColData] = useState("")

    const delay = 1;
    let timer1


    useEffect(() => {
        if (colData && colData.music !== "") {
            ViewCB(colData)
        }
    }, [colData])


    const scrollActionYCB = async (pageResult) => {
        clearTimeout(timer1);
        timer1 = setTimeout(() => {
            if (!disableScroll) {
                setShowLoader(true)
                if (pageResult === page + 1) {
                    setLoadNewData(pageResult)
                }
            }
        }, delay * 700);
    }


    useEffect(() => {
        if (loadNewData) {
            setPage(page + 1)
        }
    }, [loadNewData])

    useEffect(() => {
        if (page > 1) {
            fetchNewData()
        }
    }, [page])



   

    const fetchNewData = async () => {

     

    }



    return (
        <>
            <ScrollMenuY id="snippetView" scrollActionCB={scrollActionYCB} page={page}>
                {snippet && <ul style={{ breakInside: 'avoid', padding: 0, margin: 0, listStyle: 'none', padding: 0, margin: 0, textAlign: 'center', height: '560px', background: '#343536' }}>
                    {snippet && snippet.map((menu, index) => {
                        var key = index + 1
                        return <li
                            index={key}
                            draggable={true}
                            onDragStart={($e) => drag($e, "Code", menu.html)}
                            onTouchStart={($e) => {
                                drag($e, "Code", menu.html)
                            }}
                            onTouchMove={($e) => {
                                const x = $e.changedTouches[0].clientX
                                if(parseInt(x)>350){
                                    mobileMoveAction()
                                }
                            }}
                            onTouchEnd={($e) => {
                                $e.preventDefault();
                                const x = $e.changedTouches[0].clientX
                                const y = $e.changedTouches[0].clientY
                                let elem = document.elementFromPoint(x, y);
                                let idElement = elem.getAttribute("id");
                                addItemMobile(idElement)
                                
                            }}
                            style={{ color: '#fff', cursor: 'pointer', border: 'solid 5px #333', height: '100px', overflow: 'hidden', margin: '0px', padding: '0px', width: '125px', float: 'left', background: '#333', borderRadius: '7px' }}>
                            <div className="diableSelection"  >
                                <OverlayTrigger
                                    key="left"
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id={`tooltip-left`}>
                                            {menu.name}
                                        </Tooltip>
                                    }
                                >
                                    <div style={{ marginTop: '10px' }}>
                                        <img src={menu.image} className='img-fluid' />
                                    </div>
                                </OverlayTrigger>
                            </div>


                        </li>

                    })}
                </ul>}
                {showLoader && <LoaderComp></LoaderComp>}
            </ScrollMenuY>
        </>
    )
}

export default SnippetView
