import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
const InputPositionComp = ({ position, positionCB }) => {
    const [data, setData] = useState(position)

    const changeData = (key, value) => {
        setData(prev => {
            return {
                ...prev,
                [key]: value
            }
        })
    }

    useEffect(() => {
        positionCB(data)
    }, [data])
    return (
        <div style={{ width: '200px' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '55px' }}>
                    <Form.Group controlId="name">
                        <Form.Label>Top</Form.Label>
                        <Form.Control
                            type="text"
                            value={data?.top}
                            onChange={($e) => { changeData("top", $e.target.value) }} className='form-control-dark'>
                        </Form.Control>
                    </Form.Group>
                </div>
                <div style={{ width: '55px' }}>
                    <Form.Group controlId="name">
                        <Form.Label>Right</Form.Label>
                        <Form.Control
                            type="text"
                            value={data?.right}
                            onChange={($e) => { changeData("right", $e.target.value) }} className='form-control-dark'>
                        </Form.Control>
                    </Form.Group>
                </div>
                <div style={{ width: '55px' }}>
                    <Form.Group controlId="name">
                        <Form.Label>Bottom</Form.Label>
                        <Form.Control
                            type="text"
                            value={data?.bottom}
                            onChange={($e) => { changeData("bottom", $e.target.value) }} className='form-control-dark'>
                        </Form.Control>
                    </Form.Group>
                </div>
                <div style={{ width: '55px' }}>
                    <Form.Group controlId="name">
                        <Form.Label>Left</Form.Label>
                        <Form.Control
                            type="text"
                            value={data?.left}
                            onChange={($e) => { changeData("left", $e.target.value) }} className='form-control-dark'>
                        </Form.Control>
                    </Form.Group>
                </div>
            </div>
        </div>
    )
}

export default InputPositionComp