import moment from 'moment';

moment.locale('id');
moment().format('LL');
function momentService(date) {
    return moment(date).format("Do MMM YYYY")
}
function momentDateTime(date) {
    return moment(date).format("Do MMM YYYY HH:mm")
}
function momentDate(date) {
    return moment(date).format()
}
export { momentDateTime,momentDate }
export default momentService