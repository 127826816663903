import axiosInstance from "../helpers/axiosHelper"

const path ="/api/v1/customer/setting"
export const getSettingServices = () => {
    return axiosInstance.get(path)
}

export const updateSettingServices = (data) => {
    return axiosInstance.put(path,data)
}
