import { Alert, Col, Container, Row } from "react-bootstrap"
import BreadCrumbComp from "../components/BreadCrumbComp"
import NavbarComp from "../components/NavbarComp"
import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { getProfileServices,updateProfileServices } from "../services/profileServices";
import { useDispatch, useSelector } from "react-redux";
// import { updateUser } from "../../redux/actions/userAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import uploadHelper from "../helpers/uploadHelper";
import ContactCSComp from "../components/ContactCSComp";
import { updateProfileAction } from "../redux/features/userSlices";
// import Message from "../../components/Message";
const ProfilePage = () => {
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.userInfo)
    const [detailProfile, setDetailProfle] = useState()
    const [breadcrumbData, setBreadCrumbData] = useState({
        root: 'Home',
        root_link: '/',
        menu: 'Profile',
        menu_link: '/profile',
        current: ''
    });
    const [status, setStatus] = useState("")
    const [isUploading, setIsUploading] = useState(false)
    const [message, setMessage] = useState("")
    const [showPasswordForm, setShowPasswordForm] = useState(false)

    const showPasswordFormFunc = (value) => {
        setShowPasswordForm(!value)
    }

    const fetchProfile = async () => {
        const profile = await getProfileServices()
        setDetailProfle(profile.data)
    }

    const uploadSelectedFile = async (event) => {
        setIsUploading(true)
        let result = await uploadHelper(event)
        if (result) {
            setDetailProfle(prev => {
                return {
                    ...prev,
                    thumbnail: result[0].widthImage
                }
            })
        }

        setIsUploading(false)
    }

    const changeFormValue = (key, value) => {
        setDetailProfle(prev => {
            return {
                ...prev,
                [key]: value
            }
        })
    }
    useEffect(() => {
        fetchProfile()
    }, [])

    const save = async () => {
        if (detailProfile?.password && detailProfile.password != "") {
            if (detailProfile.password != detailProfile.confirmation_password) {
                setStatus("error")
                setMessage("Gagal diupdate konfirmasi password tidak sesuai")
                return
            }
        }
        let saved = await updateProfileServices(detailProfile)
        userInfo.thumbnail = detailProfile.thumbnail
        userInfo.whatsapp = detailProfile.whatsapp
        dispatch(updateProfileAction(userInfo))
        if (saved) {
            setStatus("success")
            setMessage("Berhasil diupdate")
        } else {
            setStatus("error")
            setMessage("Gagal diupdate")
        }
    }

    const removeImage = () => {
        setDetailProfle(prev => {
            return {
                ...prev,
                thumbnail: ""
            }
        })
    }
    return (
        <div>
            <NavbarComp></NavbarComp>
            <Container>
                <Row className="mt-5">
                    <Col md={12}>
                        <BreadCrumbComp data={breadcrumbData}></BreadCrumbComp>
                        <div style={{ width: '100%', background: '#fff', padding: '15px' }}>
                        {status && <Alert variant={status == "error" ? "danger" : "success"}>{message}</Alert>}
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    {/* <Form.Label>Aktif Hingga : 3 januari 2024</Form.Label> */}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" value={detailProfile?.email} disabled={true} style={{ color: '#333 !important' }} />

                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Photo</Form.Label>
                                    <Form.Control type="file" onChange={($e) => { uploadSelectedFile($e) }} />

                                    {detailProfile?.thumbnail && <div style={{ position: 'relative', width: '130px', height: '130px' }}>
                                        <div style={{ position: 'absolute', right: 0, top: 0, cursor: 'pointer' }} onClick={() => removeImage()}>
                                            <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                                        </div>
                                        <img src={detailProfile?.thumbnail} width="140px" />
                                    </div>}

                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Whatsapp</Form.Label>
                                    <Form.Control type="number" placeholder="Whatsapp" value={detailProfile?.whatsapp} onChange={($e) => changeFormValue("whatsapp", $e.target.value)} />
                                </Form.Group>
                                <div style={{ cursor: 'pointer', paddingBottom: '15px', paddingTop: '10px' }} onClick={() => showPasswordFormFunc(showPasswordForm)}>Ganti Password</div>
                                {showPasswordForm && <>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="text" placeholder="password" onChange={($e) => changeFormValue("password", $e.target.value)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Konfirmasi Password</Form.Label>
                                        <Form.Control type="text" placeholder="konfirmasi password" onChange={($e) => changeFormValue("confirmation_password", $e.target.value)} />
                                    </Form.Group>
                                </>}
                                <Button variant="primary" onClick={save}>
                                    Ubah
                                </Button>
                                <div style={{marginBottom:'100px'}}></div>
                            </Form>
                        </div>

                    </Col>

                </Row>
            </Container>
            <ContactCSComp></ContactCSComp>
        </div>
    )
}

export default ProfilePage