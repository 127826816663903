const userInfo ="userInfo"
export const getUserInfo = async () => {
    let res = await localStorage.getItem(userInfo)?JSON.parse(localStorage.getItem(userInfo)):null
    return res
} 

export const saveUserInfo = async (data) => {
    return localStorage.setItem(userInfo,JSON.stringify(data))
}

export const removeUserInfo = async () => {
    await localStorage.removeItem(userInfo)
}

export const saveActionId = async (actionId) => {
    return localStorage.setItem("actionId",actionId)
}
export const getActionId = async () => {
    return localStorage.getItem("actionId")
}

export const saveSlideId = async (slideId) => {
    return localStorage.setItem("slide",slideId)
}
export const getSlideId = async () => {
    return localStorage.getItem("slide")
}

export const saveColId = async (colId) => {
    return localStorage.setItem("colom",colId)
}
export const getColId = async () => {
    return localStorage.getItem("colom")
}
export const saveLog = async (log) => {
    return localStorage.setItem("log",log)
}
export const getLog = async () => {
    return localStorage.getItem("log")
}
export const clearEditorStorage = async () => {
    localStorage.removeItem("slide")
    localStorage.removeItem("colom")
}