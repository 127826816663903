import axiosInstance from "../helpers/axiosHelper"
const path ="/api/v1/customer/theme"
export const getThemes = (page,pageSize,category,id,status) => {

    if(id){
        return axiosInstance.get(`/api/v1/customer/theme?page=${page}&pageSize=${pageSize}&filterBy=${category}&keyword=${id}&status=${status}`)
    } else {
        if(!status){
            status = 'public'
        }
        return axiosInstance.get(`/api/v1/customer/theme?page=${page}&pageSize=${pageSize}&status=${status}`)
    }
}

export const getThemeDetail = (id) => {
    return axiosInstance.get(`${path}/${id}`)
}

export const makePublicServices = (id) => {
    return axiosInstance.get(`/api/v1/customer/theme/make-public/${id}`)
}


export const getAdminThemes = (pageSize, page, q) => {
    return axiosInstance.get(`${path}?pageSize=${pageSize}&page=${page}${q}`)
}

// export const getThemeDetail = (id_landingpage) => {
//     return axiosInstance.get(`${path}/${id_landingpage}`)
// }

export const updateTheme = (id, data) => {
    return axiosInstance.put(`${path}/${id}`, data)
}

export const createTheme = (data) => {
    return axiosInstance.post(`${path}`, data)
}
export const deleteTheme = (id) => {
    return axiosInstance.delete(`${path}/${id}`)
}

export const changeStatus = (id) => {
    return axiosInstance.put(`${path}/status/${id}`)
}
