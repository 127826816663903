import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { updatePluginServices } from '../../services/invitationServices';
import Colors from '../../Colors';

const PluginView = ({ openPluginCB, idLandingpage,plugins,loading }) => {
 
  const [list, setList] = useState([
    {
      name: "Kirim Instan",
      desc: "Kirim undangan instan",
      icon: "fa-envelope",
      code: "kirim_instan",
      color: "#f5f5f5",
      is_active: false
    },
    {
      name: "Daftar Tamu",
      desc: "Buat daftar tamu",
      icon: "fa-users",
      code: "daftar_tamu",
      color: "#f5f5f5",
      is_active: false
    }
  ])



  useEffect(() => {
    let tempList = [...list]
    let listActive = []
    if(plugins){
      for(let b=0;b<list.length;b++){
        for(let a=0;a<plugins.length;a++){
         if(tempList[b].code === plugins[a]){
          tempList[b].is_active=true
          listActive.push(plugins[a])
         } 
        }
      }
      setList(tempList)
    }
  },[plugins])

  const changeLocalStatus = (code) => {
    let tempList = [...list]
    tempList.filter(t => {
      if (t.code === code) {
        t.is_active = !t.is_active
      }
      return t
    })
    setList(tempList)
  }
  const changeStatus = async (code) => {
    if (idLandingpage) {
      changeLocalStatus(code)
      let res = await updatePluginServices(idLandingpage, {
        plugin: code
      })
      if (!res) {
        setTimeout(() => {
          changeLocalStatus(code)
        }, 100)
      } 
    }
  }


  return (
    <div style={{paddingLeft:'5px'}}>
      {list.map(l => {
        return <div style={{ width: '100%', borderBottom: `solid 1px ${Colors.dark}`, minHeight: '55px', color: '#fff', padding: '10px' }} >
          <div style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
            {/* <div>
              <Form.Check
                type="switch"
                id={l.code}
                label=""
                checked={l.is_active}
                name={l.code}
                onChange={() => changeStatus(l.code)}
              />
            </div> */}
            <div>
              <div style={{ flex: 1 }} onClick={() => openPluginCB(l.code)}>
                <div style={{ padding: '5px', paddingLeft: '12px' }}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '160px' }}>
                      <h6 style={{ color: '#fff', margin: '0px' }}>{l.name}</h6>
                      <small>{l.desc}</small>
                    </div>
                    <div style={{ width: '30px', textAlign: 'center', fontSize: '22px', color: l.color }}>
                      <i className={`fa ${l.icon}`}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      })}
    </div>

  )
}

export default PluginView