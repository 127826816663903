import React, { useState, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { upload } from "./../../services/uploadServices";
import Loader from "../Loader";
import { createMedia, getMedia, getMedia2 } from "../../services/mediaServices";
import TabView from "../TabView";
import ScrollMenuX from "../ScrollMenuX";
import ScrollMenuY from "../ScrollMenuY";
import Colors from "../../Colors";
const MusicView = ({ data, ViewCB, list, listCategory, device }) => {
  const [key, setKey] = useState("music");
  const [isUploading, setIsUploading] = useState(false);
  const [colData, setColData] = useState(data);
  const [listMusic, setListMusic] = useState(list);
  const [showFormUpload, setShowFormUpload] = useState(false);
  const [menus, setMenus] = useState(["Public", "Private"]);
  const [category, setCategory] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [status, setStatus] = useState("public");
  useEffect(() => {
    if (colData && colData.music !== "") {
      ViewCB(colData);
    }
  }, [colData]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      const { signal } = abortController;
      try {
        let res = await getMedia2(page, pageSize, key, status, category, {
          signal,
        });
        setListMusic(res.data.mediaList);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();

    return () => {
      abortController.abort(); // Cleanup function to abort the fetch if the component unmounts
    };
  }, []); // Empty dependency array means this effect runs only once

  const uploadSelectedFile = async (event) => {
    try {
      setIsUploading(true);
      const data = new FormData();
      data.append("file", event.target.files[0]);
      await upload(data)
        .then((res) => {
          chooseMusic(res.data.files[0]);
          setIsUploading(false);
          createMedia({
            name: res.data.files[0],
            link: res.data.files[0],
            keyword: "wedding",
            category: "6445f860c1b86f47079bf3e5",
          });
        })
        .catch((err) => {});
    } catch {
      alert("File tidak compatible");
      setIsUploading(false);
    }
  };

  const chooseMusic = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        music: value,
      };
    });
  };

  const filterCat = async (filterBy, keyword) => {
    setCategory(keyword);
    try {
      let res = await getMedia2(page, pageSize, "music", "public", keyword);
      setListMusic(res.data.mediaList);
    } catch (err) {
      setListMusic([]);
    }
  };

  return (
    <>
      {/* <TabView menus={menus} active={status} actionTab={changeThemeStatus}></TabView> */}
      <ScrollMenuX
        menus={listCategory}
        active={category}
        actionCB={filterCat}
      ></ScrollMenuX>
      {!showFormUpload && key === "private" && (
        <div
          style={{
            width: "100%",
            background: "#343536",
            color: "#fff",
            padding: "25px 10px",
            textAlign: "center",
            cursor: "pointer",
            marginBottom: "2px",
          }}
          onClick={() => setShowFormUpload(true)}
        >
          <i
            className="fas fa-upload  fa-2x"
            style={{ fontSize: "22px", marginRight: "10px" }}
          ></i>
          Upload Mp3
        </div>
      )}
      {showFormUpload && key === "private" && (
        <div className="pt-4 pb-4 pl-2 pr-2" style={{ background: "#343536" }}>
          <Form.Group controlId="name" style={{ textAlign: "left" }}>
            <div>{isUploading ? <Loader></Loader> : ""}</div>
            <Form.Label>
              <span style={{ color: "red" }}>*</span>Upload Musik
            </Form.Label>
            <Form.Control
              id="custom-file"
              label="Custom file input"
              name="input2[]"
              type="file"
              className="file"
              data-show-upload="false"
              data-show-caption="true"
              custom
              onChange={($e) => {
                uploadSelectedFile($e);
              }}
            />
          </Form.Group>
        </div>
      )}
      <div style={{ paddingLeft: "5px" }}>
        <ScrollMenuY>
          {listMusic &&
            listMusic.map((myMusic) => {
              return (
                <div
                  className="text-center"
                  style={{
                    width: "100%",
                    float: "left",
                    minHeight: "61px",
                    maxHeight: "60px",
                    overflow: "hidden !important",
                  }}
                >
                  <div
                    onClick={() => chooseMusic(myMusic.link)}
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      minHeight: "60px",
                      background: Colors.dark2,
                      borderTop: "dotted 1px #18191B",
                      overflow: "hidden",
                      display: "flex",
                    }}
                  >
                    <div style={{ width: "50px" }}>
                      <div
                        style={{
                          background: "#333",
                          borderRadius: "100px",
                          width: "40px",
                          height: "40px",
                          paddingTop: "3px",
                          border: "solid 1px #000",
                        }}
                      >
                        <i
                          className="fas fa-music pt-2 fa-2x"
                          style={{ fontSize: "14px" }}
                        ></i>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <p
                        className={`mt-2 ${
                          myMusic.name.length > 50 ? "labelContainer" : ""
                        }`}
                        style={{
                          textAlign: "left",
                          color: "#fff",
                          fontSize: "13px",
                          paddingTop: "2px",
                        }}
                      >
                        <span>{myMusic.name.substring(0, 50)}</span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </ScrollMenuY>
      </div>
    </>
  );
};

export default MusicView;
