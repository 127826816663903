const SMARTPHONE = "SMARTPHONE"
const LARGEDEVICE = "LARGE DEVICE"
export const MEDIAMEDIUM = "768px"
export const MEDIASMALL = "360px"
export const MEDIALARGE = "100%"
export const MEDIAQUERY = "mediaQueri"
export const SIZEMEDIUM =768
export const SIZESMALL =360
export const TRANSCOLOR ="#ba2323"
export const TRANSCOLORRGBA = "rgba(255,255,255,0)"
export {SMARTPHONE,LARGEDEVICE}