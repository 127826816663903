import React from 'react'
import InputTextComp from './Input/InputTextComp'
import InputSelectComp from './Input/InputSelectComp'

const InputComp = ({
    type,
    label,
    value,
    onChange,
    placeholder,
    isRequired,
    options,
    isMultiValue,
    autofocus,
    onClick,
    onBlur,
    name,
    className
}) => {
    const inputText = 'text'
    const inputNumber = 'number'
    const inputSelect = 'select'
    let inputView = false
    if (type === inputText || type === inputNumber) {
        inputView = true
    }
    const onChangeCB = (value) => {
        onChange(value)
    }

    const CheckForm = () => {
        switch (type) {
            case inputText:
                return <InputTextComp
                    type={type}
                    label={label}
                    value={value}
                    onChangeCB={onChangeCB}
                    placeholder={placeholder}
                    isRequired={isRequired}
                    autofocus={autofocus}
                    onClickCB={onClick}
                    onBlurCB={onBlur}
                    name={name}
                    className={className}
                ></InputTextComp>
            case inputNumber:
                return <InputTextComp
                    type={type}
                    label={label}
                    value={value}
                    onChangeCB={onChangeCB}
                    placeholder={placeholder}
                    isRequired={isRequired}
                    autofocus={autofocus}
                    onClickCB={onClick}
                    onBlurCB={onBlur}
                    name={name}
                    className={className}
                ></InputTextComp>
            case inputSelect:
                return <InputSelectComp
                    label={label}
                    placeholde={placeholder}
                    value={value}
                    onChangeCB={onChangeCB}
                    isRequired={isRequired}
                    options={options}
                    isMultiValue={isMultiValue}
                    className={className}
                ></InputSelectComp>

        }
    }
    return <CheckForm></CheckForm>
}

export default InputComp