import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
const TextAlign = ({ value, label, cbValue }) => {
  const [formValue, setFormValue] = useState(value);
  const [hasClick, setHasClick] = useState(false);
  const changeValue = (value) => {
    setHasClick(true);

    setFormValue(value);
  };
  useEffect(() => {
    if (hasClick) {
      cbValue({
        key: "textAlign",
        value: formValue,
      });
    }
  }, [formValue, hasClick]);

  return (
    <>
      <Form.Group controlId="name" className="mt-2 ">
        <Form.Label>
          <span style={{ color: "red", fontSize: "13px !important" }}>* </span>
          {label}
        </Form.Label>
        <div style={{ display: "flex", width: "100%" }}>
          <div
            onClick={() => changeValue("left")}
            style={{
              backgroundColor: formValue === "left" ? "#000" : "#333333",
              textAlign: "center",
              cursor: "pointer",
              paddingTop: "5px",
              paddingBottom: "5px",
              cursor: "pointer",
              flex: 1,
            }}
          >
            <i
              className="fas fa-align-left pt-2"
              style={{
                fontSize: 16,
                color: "#b3cbdd",
                marginRight: "10px",
                marginLeft: "5px",
              }}
            ></i>
          </div>
          <div
            onClick={() => changeValue("center")}
            style={{
              backgroundColor: formValue === "center" ? "#000" : "#333333",
              textAlign: "center",
              cursor: "pointer",
              paddingTop: "5px",
              paddingBottom: "5px",
              cursor: "pointer",
              flex: 1,
            }}
          >
            <i
              className="fas fa-align-center pt-2"
              style={{
                fontSize: 16,
                color: "#b3cbdd",
                marginRight: "10px",
                marginLeft: "5px",
              }}
            ></i>
          </div>
          <div
            onClick={() => changeValue("right")}
            style={{
              backgroundColor: formValue === "right" ? "#000" : "#333333",
              textAlign: "center",
              cursor: "pointer",
              paddingTop: "5px",
              paddingBottom: "5px",
              cursor: "pointer",
              flex: 1,
            }}
          >
            <i
              className="fas fa-align-right pt-2"
              style={{
                fontSize: 16,
                color: "#b3cbdd",
                marginRight: "10px",
                marginLeft: "5px",
              }}
            ></i>
          </div>
        </div>
      </Form.Group>
    </>
  );
};

export default TextAlign;
