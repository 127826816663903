import { Col, Container, Navbar, Row } from "react-bootstrap"
import BreadCrumbComp from "../components/BreadCrumbComp"
import NavbarComp from "../components/NavbarComp"
import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ContactCSComp from "../components/ContactCSComp";
import { getSettingServices, updateSettingServices } from "../services/settingServices";
import Message from "../components/Message";
import { useDispatch, useSelector } from "react-redux";
// import { updateUser } from "../redux/actions/userAction";
import { ColorPicker, useColor } from "react-color-palette";
import uploadHelper from "../helpers/uploadHelper";
import { updateSettingAction } from "../redux/features/userSlices";
import LoaderComp from "../components/LoaderComp";
const SettingPage = () => {
    const delay = 1;
    let timer1
    const userInfo = useSelector(state => state.userInfo)
    const [isUploading, setIsUploading] = useState(false)
    const dispatch = useDispatch()
    const [primaryColor, setPrimaryColor] = useState("red")
    const [enableColor, setEnableColor] = useState(false)
    const [defaultColor, setDefaultColor] = useColor(primaryColor);
    const [color, setColor] = useColor("hex", primaryColor ? primaryColor : '');
    const showColor = (val) => {
        setEnableColor(val)
    }
    const [breadcrumbData, setBreadCrumbData] = useState({
        root: 'Home',
        root_link: '/',
        menu: 'Setting',
        menu_link: '/setting',
        current: ''
    });

    const [status, setStatus] = useState("")
    const [message, setMessage] = useState("")


    const [setting, setSetting] = useState()

    const fetchSetting = async () => {
        const sett = await getSettingServices()
        console.log("lllooo", sett)
        setSetting(sett.data)
        setDefaultColor(sett.data.color)
    }

    useEffect(() => {
        fetchSetting()
    }, [])


    const updateSettingValue = (key, value) => {
        setSetting(prev => {
            return {
                ...prev,
                [key]: value
            }
        })
    }

    const save = async () => {
        setMessage("")
        setStatus("")
        let saved = await updateSettingServices(setting)
        if (saved) {
            userInfo.customerSetting.domain = setting.domain
            userInfo.customerSetting.homepage_link = setting.homepage_link
            dispatch(updateSettingAction(userInfo))
            setStatus("success")
            setMessage("Berhasil diupdate")
        } else {
            setStatus("error")
            setMessage("Gagal diupdate")
        }
    }

    const changeColor = (value) => {

        setColor(value)
        setDefaultColor(value.hex)
    }


    const colorCB = (color) => {
        let tempSetting = { ...setting }
        tempSetting.color = color.hex
        // setSetting(tempSetting)
    }

    useEffect(() => {
        let desc = setTimeout(() => {
            colorCB(color)
        }, delay * 1000);
        return () => {
            clearTimeout(desc);
        };
    }, [defaultColor])

    const uploadSelectedFile = async (event) => {
        try {
            setIsUploading(true)
            let result = await uploadHelper(event)
            let tempSetting = { ...setting }
            if(result){
                tempSetting.logo = result[0].widthImage
            }
            setSetting(tempSetting)
        } catch {
            alert("file tidak compatible")
            setIsUploading(false)
        }
    }

    return (
        <div>
            <NavbarComp></NavbarComp>
            <Container>
                <Row className="mt-5" >
                    <Col md={12}>
                        <BreadCrumbComp data={breadcrumbData}></BreadCrumbComp>
                        <div style={{ width: '100%', background: '#fff', padding: '15px' }}>
                            {status && <Message variant={status == "error" ? "danger" : "success"}>{message}</Message>}
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Logo</Form.Label>
                                    <Form.Control
                                        type="file"
                                        onChange={($e) => { uploadSelectedFile($e) }}
                                    />
                                    {isUploading && <LoaderComp></LoaderComp>}
                                    {!isUploading && setting?.logo && <img src={setting?.logo} width="200px" />}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Nama Usaha</Form.Label>
                                    <Form.Control disabled={true} type="email" placeholder="Nama Usaha" value={setting?.store_name} onChange={($e) => updateSettingValue("store_name", $e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Domain</Form.Label>
                                    <Form.Control type="text" placeholder="nama domain website" value={setting?.domain} onChange={($e) => updateSettingValue("domain", $e.target.value)} />
                                    <i>Pastikan bahwa anda telah memilki domain aktif dan setelah mengisi ini silahkan hubungi support kami untuk aktivasi domain lebih lanjut :)</i>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Link Halaman utama</Form.Label>
                                    <Form.Control type="text" placeholder="Link Halaman utama" value={setting?.homepage_link} onChange={($e) => updateSettingValue("homepage_link", $e.target.value)} />
                                </Form.Group>
                                <Button variant="primary" onClick={save}>
                                    Ubah
                                </Button>
                            </Form>
                        </div>

                    </Col>

                </Row>
            </Container>
            <ContactCSComp></ContactCSComp>
        </div>
    )
}

export default SettingPage