import "./App.css";
import { useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import routerProtected from "./routerProtected";
function App() {
  const { user } = useSelector((state) => state);
  return (
    <>
      {user?.app_id ? (
        <RouterProvider router={routerProtected} />
      ) : (
        <RouterProvider router={router} />
      )}
    </>
  );
}

export default App;
