
import { faA, faArrowRotateBack, faArrowRotateLeft, faArrowRotateRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
const RotateComp = ({ value, label, cbValue }) => {
    const [formValue, setFormValue] = useState(value)
    const [hasClick, setHasClick] = useState(false)
    const changeValue = (value) => {
        setHasClick(true)

        setFormValue(value)


    }
    useEffect(() => {
        if (hasClick) {
            cbValue({
                key: 'rotate',
                value: formValue
            })
        }
    }, [formValue, hasClick])

    return (
        <>
            <Form.Group controlId="name" className="mt-2 ">
                <Form.Label><span style={{ color: 'red' }}>* </span>{label}</Form.Label>
                <div >
                    <div style={{ display: 'flex', width: '100%',backgroundColor:'#333333' ,textAlign:'center'}}>
                        <div onClick={() => changeValue("0")} style={{ flex: 1,cursor:'pointer',background:formValue===0?'#000':'#333' ,padding:'10px 0px'}}>
                            <small>0deg</small>
                        </div>
                        <div onClick={() => changeValue("90")} style={{ flex: 1,cursor:'pointer',background:formValue===90?'#000':'#333' ,padding:'10px 0px'}}>
                            <small>90deg</small>
                        </div>
                        <div onClick={() => changeValue("180")} style={{ flex: 1,cursor:'pointer',background:formValue===180?'#000':'#333' ,padding:'10px 0px'}}>
                            <small>180deg</small>
                        </div>
                        <div onClick={() => changeValue("270")} style={{ flex: 1,cursor:'pointer',background:formValue===270?'#000':'#333' ,padding:'10px 0px'}}>
                            <small>270deg</small>
                        </div>
                    </div>

                </div>

            </Form.Group>
        </>
    )
}

export default RotateComp

