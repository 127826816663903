import { DragIconWrapper } from "../styles";

import React from "react";

export function DragHandle(props) {
  return (
    <DragIconWrapper {...props} >
        <i className='fas fa-grip-lines slide-row-flex-icon'></i>
    </DragIconWrapper>
  );
}
