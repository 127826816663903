import React, { useEffect } from 'react'

const IconButtonComp = ({ background, action, icon, cb, color, selectedContent, id }) => {

    useEffect(() => {

    }, [])

    const click = () => {
        cb(action, selectedContent, id)
    }

    return (
        <div className='action-btn-icon' onClick={click}>
            <i className={`fas ${icon} pt-1`} style={{ fontSize: 14, color: "#fff", cursor: 'pointer' }}  ></i>
        </div>
    )
}

export default IconButtonComp
