import React, { useEffect, useState } from 'react'
import { Form, Row, Tabs, Tab } from 'react-bootstrap'
import RangeSlider from '../form/Rangeslider';
import labelCSS from '../../helpers/labelCss'
import { useColor } from "react-color-palette";
import ColorComp from '../form/ColorComp';
import AnimationComp from '../form/AnimationComp';
import mediaQuery from '../../helpers/mediaQueryHelper';
import { MEDIALARGE, MEDIAMEDIUM, MEDIAQUERY, MEDIASMALL, SIZEMEDIUM, SIZESMALL } from '../../variable';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ButtonSpaceComp from '../ButtonSpaceComp';
import rgbToHex from '../../helpers/rgbToHexHelper';
const CountDownView = ({ data, ViewCB, widthDevice }) => {
    const [defaultBGColor, setDefaultBGColor] = useState('#000000')

    const [color, setColor] = useColor("rgb", "98, 142, 48");
    const [key, setKey] = useState('main')
    const [isReady, setIsReady] = useState(false)
    const [masterData, setMasterData] = useState(data)
    const [widthDevice2, setWidthDevice2] = useState(widthDevice)
    const [colData, setColData] = useState(data)
    const cbValue = (data) => {
        let key = labelCSS(data.key)
        let value = data.value
        let extension = key === "width" ? "%" : "px"
        if (key === "textAlign" || key === "fontFamily") {
            extension = ""
        }
        if (widthDevice === MEDIALARGE) {
            setColData(prev => {
                return {
                    ...prev,
                    [key]: value + extension
                }
            })
        }
        if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
            let widthDeviceStr = SIZEMEDIUM
            if (widthDevice === MEDIASMALL) {
                widthDeviceStr = SIZESMALL
            }
            let temp = { ...colData }
            if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
                let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`]
                let newObject = {
                    [key]: value
                }
                let newJoin = { ...tempNewObject, ...newObject }
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin
                setColData(temp)

            } else {
                temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
                    [key]: value
                }
                setColData(temp)
            }
        }
    }


    const cbAnimation = (value) => {
        setColData(prev => {
            return {
                ...prev,
                animation: value
            }
        })
    }

    const changeColor = (value) => {

        setColor(value)
        setColData(prev => {
            return {
                ...prev,
                color: value.hex
            }
        })

    }


    useEffect(() => {

        if (colData) {
            ViewCB(colData)
            setMasterData(colData)
        }
    }, [colData])

    const changeExpenseDate = (value) => {
        // var date = momentDate(value)
        // alert(date)
        setColData(prev => {
            return {
                ...prev,
                duedate: value
            }
        })
    }


    const calculateSetting = () => {
        setIsReady(false)
        let tempState = { ...masterData }
        tempState.marginTop = mediaQuery(tempState, "marginTop", tempState.marginTop, widthDevice)
        tempState.marginLeft = mediaQuery(tempState, "marginLeft", tempState.marginLeft, widthDevice)
        tempState.marginRight = mediaQuery(tempState, "marginRight", tempState.marginRight, widthDevice)
        tempState.marginBottom = mediaQuery(tempState, "marginBottom", tempState.marginBottom, widthDevice)
        tempState.paddingTop = mediaQuery(tempState, "paddingTop", tempState.paddingTop, widthDevice)
        tempState.paddingRight = mediaQuery(tempState, "paddingRight", tempState.paddingRight, widthDevice)
        tempState.paddingBottom = mediaQuery(tempState, "paddingBottom", tempState.paddingBottom, widthDevice)
        tempState.paddingLeft = mediaQuery(tempState, "paddingLeft", tempState.paddingLeft, widthDevice)
        tempState.borderRadius = mediaQuery(tempState, "borderRadius", tempState.borderRadius, widthDevice)
        tempState.backgroundColor = mediaQuery(tempState, "backgroundColor", tempState.backgroundColor, widthDevice)
        tempState.color = mediaQuery(tempState, "color", tempState.color, widthDevice)
        setMasterData(tempState)
        setTimeout(() => {
            setIsReady(true)
        }, 500)
    }


    useEffect(() => {
        setIsReady(false)
        setTimeout(() => {
            calculateSetting(widthDevice2)
        }, 500)
    }, [widthDevice2])


    useEffect(() => {
        if (widthDevice !== widthDevice2) {
            setWidthDevice2(widthDevice)
        }
    }, [widthDevice])





    const changeBackgroundColor = (value) => {

        if (value.hex !== defaultBGColor) {
            setColor(value)
            setColData(prev => {
                return {
                    ...prev,
                    isTransparent: false,
                    backgroundColor: value.hex
                }
            })
            setDefaultBGColor(value.hex)
        }
    }

    const noColor = (value) => {
        setColData(prev => {
            return {
                ...prev,
                isTransparent: value
            }
        })
    }


    return (
        <div style={{ textAlign: 'left' }}>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="main" title="main">
                    {isReady && <>
                        <Form.Group controlId="name">
                            <Form.Label><span style={{ color: 'red' }}>*</span>Konten</Form.Label>
                            <Row>
                            <Calendar onChange={(value) => changeExpenseDate(value)}  value={typeof (colData.duedate) === "object" ? colData.duedate : new Date(colData.duedate)} minDate={new Date()} />
                                {/* <DatePicker
                                    locale="id"
                                    onChange={(value) => changeExpenseDate(value)}
                                    className='form-control-dark'
                                    style={{ color: "#fff !important", width: '80%', marginLeft: '20px' }}
                                    value={typeof (colData.duedate) === "object" ? colData.duedate : new Date(colData.duedate)} /> */}
                            </Row>
                        </Form.Group>
                        <ColorComp label="Color" colorCB={changeColor} primaryColor={rgbToHex(masterData.color)}></ColorComp>
                        <ColorComp label="Background" colorCB={changeBackgroundColor} primaryColor={rgbToHex(masterData.backgroundColor)} showNoColor={true} noColor={noColor}></ColorComp>
                    </>}
                    <ButtonSpaceComp></ButtonSpaceComp>
                </Tab>
                <Tab eventKey="setting" title="setting">
                    {isReady && <>
                        <RangeSlider value={masterData.marginTop} label="Margin Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginRight} label="Margin Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginBottom} label="Margin Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.marginLeft} label="Margin Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingTop} label="Padding Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingRight} label="Padding Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingBottom} label="Padding Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <RangeSlider value={masterData.paddingLeft} label="Padding Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                        <ButtonSpaceComp></ButtonSpaceComp>
                    </>}
                </Tab>
                <Tab eventKey="animation" title="animasi" >
                    <AnimationComp value={colData.animation ? colData.animation : ''} cbValue={cbAnimation}></AnimationComp>
                </Tab>
            </Tabs>




            {/* <ColorPicker width={250} height={228} color={color} onChange={changeColor} hideHSV hideRGB  light /> */}


            <Row>
            </Row>
        </div>
    )
}

export default CountDownView
