import React, { useState, useEffect, useRef } from 'react'
import { getThemes } from '../../services/themeServices'
import TabView from '../TabView'
import ScrollMenuX from '../ScrollMenuX'
import ScrollMenuY from '../ScrollMenuY'
import LoaderComp from '../LoaderComp'
const ThemeView = ({ listThemes, applyTheme, listCategory }) => {

    const [themes, setThemes] = useState(listThemes)
    const [selectedMenu, setSelectedMenu] = useState("public")
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [category, setCategory] = useState("")
    const [categoryName, setCategoryName] = useState("")
    const [menus, setMenus] = useState(['Public', 'Private'])
    const [filter, setFilter] = useState("")
    const [showLoader, setShowLoader] = useState(true)
    const [disableScroll, setDisableScroll] = useState(false)
    const [loadNewData, setLoadNewData] = useState(false)
    const [imageCombined, setImageCombined] = useState(false)
    const delay = 1;
    let timer1

    let allowScroll = useRef(true)

    useEffect(() => {
        setThemes(listThemes)
    }, [listThemes])

    const filterCat = async (filterBy, keyword, newKey) => {
        let keynya = selectedMenu
        if (newKey) {
            keynya = newKey
        }
        setDisableScroll(false)
        setFilter(filterBy)
        setShowLoader(true)
        setCategory(keyword)
        let catName = findCat(keyword)
        setCategoryName(catName)
        setThemes([])
        try {
            let res = await getThemes(1, pageSize, filterBy, keyword, keynya)
            setThemes(res.data.themeList)
            setPage(1)
            setShowLoader(false)

        } catch (err) {
            setThemes([])
            setShowLoader(false)
        }
        allowScroll.current = true
    }

    const changeThemeStatus = (k) => {
        setThemes([])
        setSelectedMenu(k)
        if (k === menus[1].toLowerCase()) {
            filterCat(filter, "", k)
        }
        if (k === menus[0].toLowerCase()) {
            filterCat(filter, "", k)
        }
    }

    const findCat = (id) => {
        let selcat = listCategory.find(c => {
            return c._id === id
        })
        return selcat.name
    }

    const scrollActionYCB = async (pageResult) => {

        clearTimeout(timer1);
        timer1 = setTimeout(() => {
            if (allowScroll.current) {
                allowScroll.current = false
                setShowLoader(true)
                if (pageResult === page + 1) {
                    setLoadNewData(pageResult)
                }
            }
        }, delay * 700);



    }


    useEffect(() => {
        if (loadNewData) {
            setPage(page + 1)
        }
    }, [loadNewData])

    useEffect(() => {
        if (page > 1) {
            fetchNewData()
        }
    }, [page])


    const fetchNewData = async () => {

        try {
            let result = await getThemes(page, pageSize, filter, category, selectedMenu)
            if (result) {
                if (result.data.themeList.length > 0) {
                    let prevThemes = [...themes, ...result.data.themeList]
                    setThemes(prevThemes)
                    setImageCombined(true)
                    setTimeout(() => {
                        allowScroll.current = true
                    }, 1000)
                } else {
                    setDisableScroll(true)
                }
                setShowLoader(false)
            } else {
                setShowLoader(false)
            }
            // setLoadNewData(false)
        } catch (err) {
            setDisableScroll(true)
            setShowLoader(false)

        }

    }
    return (
        <>
            <TabView menus={menus} active={selectedMenu} actionTab={changeThemeStatus}></TabView>
            <ScrollMenuX menus={listCategory} active={category} actionCB={filterCat} page={page} ></ScrollMenuX>
            <div style={{ paddingLeft: "5px" }}>
                <ScrollMenuY id="themeView" scrollActionCB={scrollActionYCB} page={page}>
                    {themes.length === 0 && selectedMenu === menus[0].toLowerCase() && !showLoader && <p className='mt-3'>Maaf belum ada tema untuk kategori {categoryName} </p>}
                    {themes.length === 0 && selectedMenu === menus[1].toLowerCase() && !showLoader && <p className='mt-3'>Anda belum memiliki tema untuk {categoryName} </p>}
                    {themes && themes.map(theme => {
                        return <div className="text-center" style={{ height: '200px', width: '48%', float: 'left', marginBottom: '10px', marginLeft: '1%', marginRight: '1%', overflow: 'hidden', cursor: 'pointer' }} onClick={(() => applyTheme(theme))}>
                         
                            <img src={theme.image ? theme.image : 'https://d1jcw7e5n1q2pt.cloudfront.net/1701822331883-notfound.jpg'} style={{ width: '100%', overflow: 'hidden', float: 'left' }} />
                        </div>
                    })}
                    {showLoader && <LoaderComp></LoaderComp>}
                </ScrollMenuY>
            </div>
        </>
    )
}

export default ThemeView
